import { useEffect, useState } from 'react';
import { getReturnByDates } from '../../../../API/CollectorReturnApi.js';
import { endOfDay } from 'date-fns';
import { getAllStandardSalesDetailsByDates } from '../../../../API/SalesApi.js';
import { getAllStandardSalesDetailsByDatesCheck } from '../../../../API/CheckApi/CheckSalesApi.js';
import { getReturnByDatesCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi.js';
import * as Constant from '../../../../Constants';
import LineChart from '../../../../Components/LineChart';
import { reduceDataByKeyAndQty } from '../../../../UtilsData';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
export default function IndusDataConsumerDashboard() {
    const navigate = useNavigate();
    const [averageContainerByReturn, setAverageContainerByReturn] = useState(0);
    const [tauxReturnInTce, setTauxReturnInTce] = useState(0);
    const [tauxReturnInRvm, setTauxReturnInRvm] = useState(0);
    const [returnsByWeekDay, setReturnsByWeekDay] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const now = new Date();
                const startDate = new Date(now.getFullYear(), 0, 1);
                const endDate = now;

                const allCollectorReturnRaw = await getReturnByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allCollectorReturnCheck = getReturnByDatesCheck(allCollectorReturnRaw);
                if (allCollectorReturnRaw.length && allCollectorReturnCheck !== true) {
                    console.error(allCollectorReturnCheck);
                    Sentry.captureException(allCollectorReturnCheck);
                    navigate('/error');
                    return;
                }

                const countedAverageContainerByReturn = reduceDataByKeyAndQty(allCollectorReturnRaw, 'returnedAt');
                const averageContainerByReturnRaw = Object.keys(countedAverageContainerByReturn).map((item) => ({
                    date: item,
                    qty: countedAverageContainerByReturn[item],
                }));

                const averageContainerByReturnFinal = (allCollectorReturnRaw.length / averageContainerByReturnRaw.length).toFixed(1);
                setAverageContainerByReturn(averageContainerByReturnFinal);

                // TAUX EN RVM ET TCE

                const standardContainersReturned = allCollectorReturnRaw.filter(
                    (container) => container.container_owner === Constant.CONTAINER_OWNER_STATUS_STANDARD,
                );

                const standardContainersProducted = await getAllStandardSalesDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allSalesDetailsCheck = getAllStandardSalesDetailsByDatesCheck(standardContainersProducted);
                if (standardContainersProducted.length && allSalesDetailsCheck !== true) {
                    console.error(allSalesDetailsCheck);
                    Sentry.captureException(allSalesDetailsCheck);
                    navigate('/error');
                    return;
                }

                const totalStandardProducted = standardContainersProducted.reduce((acc, item) => acc + item.qty, 0);
                const ReturnInTce = standardContainersReturned.filter((container) => container.collector_type === Constant.COLLECTOR_TYPE_TCE);
                const tauxRetourTce = ((ReturnInTce.length / totalStandardProducted) * 100).toFixed(1);
                setTauxReturnInTce(tauxRetourTce);
                const ReturnInRvm = standardContainersReturned.filter((container) => container.collector_type === Constant.COLLECTOR_TYPE_RVM);
                const tauxRetourRvm = ((ReturnInRvm.length / totalStandardProducted) * 100).toFixed(1);
                setTauxReturnInRvm(tauxRetourRvm);

                // NB RETOUR BY DAY OF WEEK
                const countedByWeekDays = getQtyByDayOfWeek(allCollectorReturnRaw);
                const returnsByWeekDayRaw = Object.keys(countedByWeekDays).map((item) => ({
                    day: item,
                    qty: countedByWeekDays[item],
                }));

                setReturnsByWeekDay(returnsByWeekDayRaw);
            } catch (error) {
                console.debug(error);
            }
        };
        fetchData();
    }, [navigate]);

    const getQtyByDayOfWeek = (data) => {
        const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
        const initialAcc = daysOfWeek.reduce((acc, day) => {
            acc[day] = 0;
            return acc;
        }, {});
        return data.reduce((acc, curr) => {
            const date = new Date(curr.returnedAt);
            const dayOfWeek = daysOfWeek[date.getDay()];
            acc[dayOfWeek] += curr.qty;
            return acc;
        }, initialAcc);
    };

    const returnsByWeekDayQuantities = returnsByWeekDay.map((item) => item.qty);
    const returnsByWeekDayLabels = returnsByWeekDay.map((item) => item.day);
    return (
        <main>
            <div className="data-container">
                <div className="header-container">
                    <div className="header-title">
                        <h1>DONNÉES CONSOMMATEUR</h1>
                    </div>
                </div>

                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1%' }}>
                        <section
                            className="section-graph"
                            style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <div
                                className="title"
                                style={{ fontWeight: 'bold', height: '1rem' }}>
                                Produits
                            </div>
                            <div
                                style={{ height: '1rem' }}
                                className="title">
                                Nombre de contenants <br /> par acte de retour : <span style={{ fontSize: 25 }}>{averageContainerByReturn}</span>
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ display: 'flex', flex: 2, flexDirection: 'column', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ fontWeight: 'bold', height: '1rem' }}>
                                Collecteurs :
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}>
                                <div className="title">
                                    Taux de retour moyen <br /> dans les RVM : <span style={{ fontSize: 25 }}>{tauxReturnInRvm}%</span>
                                </div>

                                <div className="title">
                                    Taux de retour moyen dans <br /> les TCE : <span style={{ fontSize: 25 }}>{tauxReturnInTce}%</span>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 4, height: '22rem' }}>
                        <section
                            className="section-graph"
                            style={{ height: '22rem' }}>
                            <div
                                className="title"
                                style={{ fontWeight: 'bold', height: '1rem' }}>
                                Fréquence :{' '}
                            </div>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Fréquence hebdomadaire des actes de retour :{' '}
                            </div>
                            <div style={{ height: '90%' }}>
                                {returnsByWeekDay.length > 0 ? (
                                    <LineChart
                                        labels={returnsByWeekDayLabels}
                                        quantities={returnsByWeekDayQuantities}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}
