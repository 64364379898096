import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { CaretUp, SquaresFour, Recycle, UsersThree, Leaf } from '@phosphor-icons/react';

import * as Constants from '../Constants';

import { getSousMenuServiceSupplierData } from '../navigation/ServiceSupplier/ServiceSupplierMenu';
import { getSousMenuIndustrialData } from '../navigation/Industrial/IndustrialMenu';
import { getSousMenuSorterData } from '../navigation/Sorter/SorterMenu';
import { getSousMenuWasherData } from '../navigation/Washer/WasherMenu';
import { getSousMenuCollecteData } from '../navigation/Collecte/CollecteMenu';
import { getSousMenuRvmownerData } from '../navigation/Rvmowner/RvmownerMenu';
import { getSousMenuStoreownerData } from '../navigation/Storeowner/StoreownerMenu';
import { getSousMenuCdcData } from '../navigation/Cdc/CdcMenu';
import { getSousMenuDistributorData } from '../navigation/Distributor/DistributorMenu';

const MenuData = ({ accessTab, type }) => {
    const location = useLocation();

    const navigate = useNavigate();
    const userToken = Cookies.get('userToken');

    const moduleChoosed = localStorage.getItem('module');

    const isSupplierService = location.pathname.startsWith('/servicesupplier');
    const isIndustrial = location.pathname.startsWith('/industrial');
    const isRvmOwner = location.pathname.startsWith('/rvmowner');
    const isDistributor = location.pathname.startsWith('/distributor');
    const isError = location.pathname === '/error';

    const isSupplierServiceOrIndusAndOpe = (isSupplierService || isIndustrial || isRvmOwner) && moduleChoosed === Constants.MODULE_OPERATION;
    const isFSOrIndusOrDistribAndData = (isSupplierService || isIndustrial || isDistributor) && moduleChoosed === Constants.MODULE_DATA;

    const [selectedOnglet, setSelectedOnglet] = useState(isSupplierServiceOrIndusAndOpe ? 'homeOpe' : isFSOrIndusOrDistribAndData ? 'homeData' : null);
    const [selectedSousPartie, setSelectedSousPartie] = useState(null);
    const [isClicked, setIsClicked] = useState(true);
    const [selectedSousMenuData, setSelectedSousMenuData] = useState(null);

    const checkDisplayMenuData = () => {
        if (location.pathname.includes('/data')) {
            return 1;
        } else {
            return 0;
        }
    };

    const handleOngletClick = (onglet) => {
        onglet === 'homeOpe' && isRvmOwner && navigate('/rvmowner/operation/home');
        onglet === 'homeOpe' && isSupplierService && navigate('/servicesupplier/operation/home');
        onglet === 'homeOpe' && isIndustrial && navigate('/industrial/operation/home');
        onglet === 'homeData' && isIndustrial && navigate('/industrial/data/home');
        onglet === 'homeData' && isDistributor && navigate('/distributor/data/home');
        if (selectedOnglet === onglet) {
            setSelectedOnglet(null);
            setSelectedSousPartie(null);
        } else {
            setSelectedSousMenuData(null);
            setSelectedOnglet(onglet);
            setSelectedSousPartie(null);
            setIsClicked(true);
        }
    };

    const handleSousPartieClick = (sousPartie) => {
        setSelectedSousPartie(selectedSousPartie === sousPartie ? null : sousPartie);
        setSelectedSousMenuData(null);
    };

    const handleSousMenuDataClick = (sousMenuOption, bool) => {
        navigate(sousMenuOption.link);
        setSelectedSousMenuData(sousMenuOption.link);
        if (bool === false) {
            setSelectedSousPartie(null);
        }
    };

    const getIconForOngletData = (onglet) => {
        switch (onglet) {
            case 'homeData':
                return (
                    <SquaresFour
                        size={30}
                        color={selectedOnglet !== onglet ? Constants.$blanc : Constants.$vertBocoloco}
                    />
                );
            case 'reuse':
                return (
                    <Recycle
                        size={25}
                        color={selectedOnglet !== onglet ? Constants.$blanc : Constants.$vertBocoloco}
                    />
                );
            case 'consumer':
                return (
                    <UsersThree
                        size={25}
                        color={selectedOnglet !== onglet ? Constants.$blanc : Constants.$vertBocoloco}
                    />
                );
            case 'impact':
                return (
                    <Leaf
                        size={25}
                        color={selectedOnglet !== onglet ? Constants.$blanc : Constants.$vertBocoloco}
                    />
                );
            default:
                return '';
        }
    };

    const getSousMenuData = (type, onglet) => {
        switch (type) {
            case Constants.ENTITY_TYPE_FS:
                return getSousMenuServiceSupplierData(onglet);
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                return getSousMenuIndustrialData(onglet);
            case Constants.ENTITY_TYPE_SORTER:
                return getSousMenuSorterData(onglet);
            case Constants.ENTITY_TYPE_WASHER:
                return getSousMenuWasherData(onglet);
            case Constants.ENTITY_TYPE_OPERATOR:
                return getSousMenuCollecteData(onglet);
            case Constants.ENTITY_TYPE_RVMOWNER:
                return getSousMenuRvmownerData(onglet);
            case Constants.ENTITY_TYPE_STOREOWNER:
                return getSousMenuStoreownerData(onglet);
            case Constants.ENTITY_TYPE_CDC:
                return getSousMenuCdcData(onglet);
            case Constants.ENTITY_TYPE_DISTRIBUTOR:
                return getSousMenuDistributorData(onglet);

            default:
                return [];
        }
    };

    const displayMenuData = checkDisplayMenuData();
    if (!displayMenuData || isError) {
        return null;
    }

    return userToken ? (
        <section className="visible menu-lateral">
            <section className="nav">
                {accessTab.map((onglet, index) => (
                    <div
                        key={index}
                        style={{
                            background:
                                ((!isIndustrial && !isSupplierService && !isRvmOwner && !isDistributor) || selectedOnglet === onglet) && Constants.$blanc,
                            paddingBottom: '0.5rem',
                        }}>
                        <div
                            className={
                                (!isIndustrial && !isSupplierService && !isRvmOwner && !isDistributor) || (isClicked && selectedOnglet === onglet)
                                    ? 'title title-selected'
                                    : 'title'
                            }
                            onClick={() => handleOngletClick(onglet)}>
                            <div>
                                <p
                                    className={
                                        (!isIndustrial && !isSupplierService && !isRvmOwner && !isDistributor) || (isClicked && selectedOnglet === onglet)
                                            ? 'icone-title-selected'
                                            : 'icon-title'
                                    }>
                                    {getIconForOngletData(onglet)}
                                </p>
                            </div>
                            <div>
                                <p>{getSousMenuData(type, onglet).length ? getSousMenuData(type, onglet)[0].title : 'aucun titre'}</p>
                            </div>
                            <div>
                                <CaretUp
                                    style={{ color: selectedOnglet !== onglet ? Constants.$vertTresClair : Constants.$grisfonce }}
                                    size={15}
                                />
                            </div>
                        </div>
                        {((!isIndustrial && !isSupplierService && !isRvmOwner && !isDistributor) || selectedOnglet === onglet) && (
                            <ul>
                                {getSousMenuData(type, onglet).map((sousPartie, sousPartieIndex) => (
                                    <div key={sousPartieIndex}>
                                        {Array.isArray(sousPartie.sousMenu) ? (
                                            <div>
                                                <div
                                                    className="sous-partie"
                                                    style={{
                                                        color: selectedSousPartie === sousPartie.sousPartie ? Constants.$vertBocoloco : '',
                                                        fontWeight: selectedSousPartie === sousPartie.sousPartie ? 600 : 400,
                                                    }}>
                                                    <div></div>
                                                    <div>
                                                        <p onClick={() => handleSousPartieClick(sousPartie.sousPartie)}>{sousPartie.sousPartie}</p>
                                                    </div>
                                                    <div></div>
                                                </div>

                                                <div className="sous-partie-text">
                                                    {selectedSousPartie === sousPartie.sousPartie && (
                                                        <ul>
                                                            {sousPartie.sousMenu.map((option, optionIndex) => (
                                                                <li key={optionIndex}>
                                                                    <div></div>
                                                                    <div>
                                                                        <span className="bullet-point">&#8226;</span>
                                                                        <p
                                                                            style={{
                                                                                color: selectedSousMenuData === option.link && Constants.$vertBocoloco,
                                                                                fontWeight: selectedSousMenuData === option.link ? 600 : 400,
                                                                            }}
                                                                            onClick={() => handleSousMenuDataClick(option, true)}>
                                                                            {option.name}
                                                                        </p>
                                                                    </div>
                                                                    <div></div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <li
                                                className="simple-menu"
                                                style={{
                                                    display: 'flex',
                                                    color: selectedSousMenuData === sousPartie.link && Constants.$vertBocoloco,
                                                    fontWeight: selectedSousMenuData === sousPartie.link && '600',
                                                }}>
                                                <div></div>
                                                <div>
                                                    <p onClick={() => handleSousMenuDataClick(sousPartie, false)}>{sousPartie.name}</p>
                                                </div>
                                                <div></div>
                                            </li>
                                        )}
                                    </div>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </section>
        </section>
    ) : null;
};

export default MenuData;
