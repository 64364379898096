import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_INDUSTRIAL_CREATE_SALES = '/sales/new';
const BOCOLOCO_GET_SALESDETAILS_ALLBYDATES_STANDARD = '/sales/details/standard/bydates';
const BOCOLOCO_GET_SALESDETAILS_BYDATESANDENTITY = '/sales/details/bydatesandindus';
const BOCOLOCO_GET_SALESDETAILS_ALLBYDATES = '/sales/details/all/bydates';

export function salesCreate(declarationDetailsData, entity_id, user_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_INDUSTRIAL_CREATE_SALES;
    var url_args = '?entity_id=' + entity_id + '&user_id=' + user_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(declarationDetailsData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR CREATE INDUS SALES');
            console.log(error);
            throw error;
        });
}

export function getAllStandardSalesDetailsByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SALESDETAILS_ALLBYDATES_STANDARD;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getAllSalesDetailsByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SALESDETAILS_ALLBYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getAllSalesDetailsByDatesAndIndus(startDate, endDate, entityId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SALESDETAILS_BYDATESANDENTITY;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate + '&entityId=' + entityId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
