import { getGearAPIApiEndpoint } from './endpoints';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_STOREOWNERS = '/storeowner/get';
const BOCOLOCO_GET_STOREOWNERBYID = '/storeowner/getbyid';
const BOCOLOCO_GET_STOREOWNERSBYNAME = '/storeowner/getbyname';
const BOCOLOCO_ADD_STOREOWNER = '/storeowner/add';
const BOCOLOCO_UPDATE_STOREOWNER = '/storeowner/update';

export function getStoreowners() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREOWNERS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function addStoreowner(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_STOREOWNER;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD WASHER');
            console.log(error);
            throw error;
        });
}

export function updateStoreowner(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_STOREOWNER;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE STOREOWNER');
            console.log(error);
            throw error;
        });
}

export function getStoreownersByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREOWNERSBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function getStoreownerById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_STOREOWNERBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}
