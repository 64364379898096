import { useState } from 'react';
import { addDistributor } from '../../../../../API/DistributorApi';
import { useNavigate } from 'react-router-dom';
import { checkEntityFormErrors, checkIsInputEmpty } from '../../../../../Utils';
import AddressSearch from '../../../../../Components/AddressSearch';

export default function AddDistributor() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    const [formData, setFormData] = useState({
        company_name: '',
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        zipcode: '',
        siret: '',
        tva_intra: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        website: '',
        lat: '',
        long: '',
    });

    const [formErrors, setFormErrors] = useState({
        company_name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        siret: false,
        tva_intra: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        website: false,
    });

    const requiredFields = [
        'company_name',
        'address_1',
        'city',
        'zipcode',
        'siret',
        'tva_intra',
        'contact_name',
        'contact_phone',
        'contact_email',
    ];
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');
        const updatedFormData = { ...formData, name: formData.company_name };

        const inputEmpty = checkIsInputEmpty(requiredFields, formData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkEntityFormErrors(formData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const addStoreownerData = await addDistributor(updatedFormData);
                if (addStoreownerData) {
                    alert('Le distributeur été créé avec succès');
                    navigate(-1);
                } else {
                    alert("une erreur est survenue lors de l'ajout du distributeur");
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };

    return (
        <main>
            <section className="big-section">
                <h2>Ajouter un distributeur</h2>
                <div className="form-container">
                    <section>
                        <div>
                            <label>Nom de la société:</label>
                            <input
                                className={formErrors.company_name ? 'error' : ''}
                                type="text"
                                name="company_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Siret:</label>
                            <input
                                className={formErrors.siret ? 'error' : ''}
                                type="text"
                                name="siret"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>TVA intracommunautaire:</label>
                            <input
                                className={formErrors.tva_intra ? 'error' : ''}
                                type="text"
                                name="tva_intra"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                    <section>
                        <AddressSearch
                            formData={formData}
                            setFormData={setFormData}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            handleChange={handleChange}
                        />
                    </section>
                    <section>
                        <div>
                            <label>Nom du contact:</label>
                            <input
                                className={formErrors.contact_name ? 'error' : ''}
                                type="text"
                                name="contact_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Téléphone du contact:</label>
                            <input
                                className={formErrors.contact_phone ? 'error' : ''}
                                type="text"
                                name="contact_phone"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Email du contact:</label>
                            <input
                                className={formErrors.contact_email ? 'error' : ''}
                                type="text"
                                name="contact_email"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Site web:</label>
                            <input
                                className={formErrors.website ? 'error' : ''}
                                type="url"
                                name="website"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </section>
                </div>
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSubmit}>
                        Ajouter le distributeur
                    </button>
                </div>
            </section>
        </main>
    );
}
