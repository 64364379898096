import { useState, useEffect } from 'react';
import { _defineStatus } from '../../../../../Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkEntityFormErrors, checkIsInputEmpty } from '../../../../../Utils';
import AddressSearch from '../../../../../Components/AddressSearch';
import { getDistributorById, updateDistributor } from '../../../../../API/DistributorApi';

export default function UpdateDistributor() {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = location.state || {};
    const [distributorData, setDistributorData] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [errorEmpty, setErrorEmpty] = useState('');
    useEffect(() => {
        const fetchdistributorData = async () => {
            try {
                const distributorDataRaw = await getDistributorById(id);
                setDistributorData(distributorDataRaw[0]);
            } catch (error) {
                console.error('Erreur lors de la récupération des infos du distributeur', error);
            }
        };
        fetchdistributorData();
    }, [id]);

    const [formErrors, setFormErrors] = useState({
        company_name: false,
        address_1: false,
        address_2: false,
        city: false,
        zipcode: false,
        siret: false,
        tva_intra: false,
        contact_name: false,
        contact_phone: false,
        contact_email: false,
        website: false,
    });

    const requiredFields = [
        'company_name',
        'address_1',
        'city',
        'zipcode',
        'siret',
        'tva_intra',
        'contact_name',
        'contact_phone',
        'contact_email',
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDistributorData({
            ...distributorData,
            [name]: value,
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setErrorMessage([]);
        setErrorEmpty('');

        const updatedFormData = { ...distributorData, name: distributorData.company_name };

        const inputEmpty = checkIsInputEmpty(requiredFields, distributorData, setFormErrors, setErrorEmpty);
        if (!inputEmpty) {
            const checkFormat = checkEntityFormErrors(distributorData, formErrors, setFormErrors, setErrorMessage);
            if (checkFormat) {
                return;
            } else {
                const updatedistributorData = await updateDistributor(id, updatedFormData);
                if (updatedistributorData) {
                    alert('Le distributeur été modifié avec succès');
                    navigate(-1);
                } else {
                    alert('une erreur est survenue lors de la modification du distributeur');
                }
            }
        } else {
            console.log('Il reste des champs obligatoires vides');
        }
    };
    return (
        <main>
            <section className="big-section">
                <h2>Modifier un distributeur</h2>
                <div className="form-container">
                    <section>
                        <div>
                            <label>Nom de la société:</label>
                            <input
                                value={distributorData.company_name || ''}
                                className={formErrors.company_name ? 'error' : ''}
                                type="text"
                                name="company_name"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Siret:</label>
                            <input
                                value={distributorData.siret || ''}
                                className={formErrors.siret ? 'error' : ''}
                                type="text"
                                name="siret"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>TVA intracommunautaire:</label>
                            <input
                                value={distributorData.tva_intra || ''}
                                className={formErrors.tva_intra ? 'error' : ''}
                                type="text"
                                name="tva_intra"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </section>
                    <section>
                        <AddressSearch
                            formData={distributorData}
                            setFormData={setDistributorData}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            handleChange={handleInputChange}
                        />
                    </section>
                    <section>
                        <div>
                            <label>Nom du contact:</label>
                            <input
                                value={distributorData.contact_name || ''}
                                className={formErrors.contact_name ? 'error' : ''}
                                type="text"
                                name="contact_name"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Téléphone du contact:</label>
                            <input
                                value={distributorData.contact_phone || ''}
                                className={formErrors.contact_phone ? 'error' : ''}
                                type="number"
                                name="contact_phone"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Email du contact:</label>
                            <input
                                value={distributorData.contact_email || ''}
                                className={formErrors.contact_email ? 'error' : ''}
                                type="email"
                                name="contact_email"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Site web:</label>
                            <input
                                value={distributorData.website || ''}
                                className={formErrors.website ? 'error' : ''}
                                type="url"
                                name="website"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Statut:</label>
                            <select
                                style={{ backgroundColor: _defineStatus(distributorData.status).color, color: 'white' }}
                                name="status"
                                value={distributorData.status}
                                onChange={handleInputChange}>
                                <option value={0}>Actif</option>
                                <option value={100}>Inactif</option>
                            </select>
                        </div>
                    </section>
                </div>
                <p className="error-form">{errorEmpty && errorEmpty}</p>
                {errorMessage.length
                    ? errorMessage.map((item, index) => {
                          return (
                              <div
                                  className="error-form"
                                  key={index}>
                                  {item}
                              </div>
                          );
                      })
                    : null}
                <div className="button-confirm">
                    <button
                        className="button-actif"
                        onClick={handleSaveChanges}>
                        Enregistrer les modifications
                    </button>
                </div>
            </section>
        </main>
    );
}
