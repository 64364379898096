// SUPPLIER ORDER DETAILS
import { isDataValid } from '../../Utils';
export const getSupplierOrderDetailsByOrderIdCheck = (data) => {
    const name = 'getSupplierOrderDetailsByOrderId : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].supplierProduct.id) {
            return name + 'No supplier Product id found';
        } else if (!data[i].supplierProduct.supplier.id) {
            return name + 'No supplier id found';
        } else {
            return true;
        }
    }
};

export const getSupplierOrderDetailsByOrderIdDataCheck = (data) => {
    const name = 'getSupplierOrderDetailsByOrderIdData : ';
    for (let i = 0; i < data.length; i++) {
        if (!isDataValid(data[i].sku)) {
            return name + 'No sku found';
        } else if (!isDataValid(data[i].qty)) {
            return name + 'No quantity found';
        } else {
            return true;
        }
    }
};
