import React, { useEffect, useState } from 'react';
import { Jwt } from '../../../Jwt';
import { endOfDay } from 'date-fns';
import { Link } from 'react-router-dom';
import { Eye, DotsThree, FileCsv, FilePdf } from '@phosphor-icons/react';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import {
    downloadReportingStoreCashOutCsv,
    downloadReportingStoreCashOutPdf,
    getFinancialStoreCashOutByStoreAndDates,
} from '../../../API/FinancialCashOutReportingApi';

export default function Reportings() {
    const userContext = new Jwt();
    const now = new Date();

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);
    const [yearSelected, setYearSelected] = useState(now.getFullYear());
    const [showOptions, setShowOptions] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [reportingTab, setReportingTab] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const allFinancialStoreCashOutRaw = await getFinancialStoreCashOutByStoreAndDates(startDate.toISOString(), endOfDay(endDate.toISOString()));

                setReportingTab(allFinancialStoreCashOutRaw);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (startDate & endDate) fetchData();
    }, [startDate, endDate, userContext.entityId]);

    const getCsv = async (fileName) => {
        try {
            const response = await downloadReportingStoreCashOutCsv(fileName);
            if (response) {
                console.log('response', response);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const getPdf = async (fileName) => {
        try {
            const response = await downloadReportingStoreCashOutPdf(fileName);
            if (response) {
                console.log('response', response);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const getYears = () => {
        const years = [];
        for (let year = 2020; year <= now.getFullYear(); year++) {
            years.push(year);
        }
        return years;
    };
    const years = getYears();

    const handleYearSelected = (year) => {
        setYearSelected(year);
        setStartDate(startOfMonth(new Date(year, 1 - 1, 1)));
        setEndDate(endOfMonth(new Date(year, 12 - 1, 1)));
    };

    console.log('reportingTab', reportingTab);

    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>REPORTING MENSUELS</h1>
                </div>

                <div className="header-container">
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                style={{ width: '5rem' }}
                                onChange={(e) => handleYearSelected(e.target.value)}
                                value={yearSelected}>
                                {years.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '3%', flexDirection: 'column' }}>
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%', fontSize: 15 }}>
                            <p style={{ flex: 1 }}>Mois</p>
                            <p style={{ flex: 1, borderRight: 0 }}>Montant consigne (€)</p>
                            <p style={{ flex: 0.5 }}></p>
                        </div>
                    </div>

                    {reportingTab.length ? (
                        reportingTab.map((elem, index) => (
                            <section
                                key={index}
                                className="section-graph"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    height: '4rem',
                                    alignItems: 'center',
                                    padding: 0,
                                    position: 'relative',
                                }}>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{format(elem.endDate, 'MM/yyyy')}</p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{(elem.amount / 100).toFixed(2)} €</p>
                                <p
                                    style={{ flex: 0.5, paddingRight: '0.93rem', cursor: 'pointer', textAlign: 'right' }}
                                    onClick={() => {
                                        setSelectedIndex(index);
                                        setShowOptions(index === selectedIndex ? !showOptions : false);
                                    }}>
                                    <DotsThree size={25} />
                                </p>
                                {showOptions && index === selectedIndex && (
                                    <div className="option-container tooltip ">
                                        <p style={{ cursor: 'pointer' }}>
                                            <Link
                                                to={'/storeowner/financial/reportings/details'}
                                                state={{
                                                    startDate: elem.startDate,
                                                    endDate: elem.endDate,
                                                    date: elem.createdAt,
                                                    entity: userContext.entityId,
                                                }}>
                                                <Eye size={25} />
                                            </Link>
                                        </p>
                                        <p
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                getCsv(elem.csvFile);
                                            }}>
                                            <FileCsv size={25} />
                                        </p>
                                        <p
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                getPdf(elem.pdfFile);
                                            }}>
                                            <FilePdf size={25} />
                                        </p>
                                    </div>
                                )}
                            </section>
                        ))
                    ) : (
                        <div className="no-data-graph ">Aucune donnée.</div>
                    )}
                </div>
            </div>
        </main>
    );
}
