import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { CaretUp, UserCircle } from '@phosphor-icons/react';

import * as Constants from '../Constants';

import { getSousMenuServiceSupplierAccount } from '../navigation/ServiceSupplier/ServiceSupplierMenu';
import { getSousMenuIndustrialAccount } from '../navigation/Industrial/IndustrialMenu';
import { getSousMenuSorterAccount } from '../navigation/Sorter/SorterMenu';
import { getSousMenuWasherAccount } from '../navigation/Washer/WasherMenu';
import { getSousMenuCollecteAccount } from '../navigation/Collecte/CollecteMenu';
import { getSousMenuRvmownerAccount } from '../navigation/Rvmowner/RvmownerMenu';
import { getSousMenuStoreownerAccount } from '../navigation/Storeowner/StoreownerMenu';
import { getSousMenuCdcAccount } from '../navigation/Cdc/CdcMenu';
import { getSousMenuDistributorAccount } from '../navigation/Distributor/DistributorMenu';

const MenuAccount = ({ accessTab, type }) => {
    const location = useLocation();

    const navigate = useNavigate();
    const userToken = Cookies.get('userToken');

    const isSupplierService = location.pathname.startsWith('/servicesupplier');
    const isRvmOwner = location.pathname.startsWith('/rvmowner');
    const isError = location.pathname === '/error';

    const [selectedOnglet, setSelectedOnglet] = useState('my_account');
    const [selectedSousPartie, setSelectedSousPartie] = useState(null);
    const [isClicked, setIsClicked] = useState(true);
    const [selectedSousMenuAccount, setSelectedSousMenuAccount] = useState('/industrial/myprofil/personal_info');

    const checkDisplayMenuAccount = () => {
        if (location.pathname.includes('/myprofil')) {
            return 1;
        } else {
            return 0;
        }
    };

    const handleOngletClick = (onglet) => {
        if (selectedOnglet === onglet) {
            setSelectedOnglet(null);
            setSelectedSousPartie(null);
        } else {
            setSelectedSousMenuAccount(null);
            setSelectedOnglet(onglet);
            setSelectedSousPartie(null);
            setIsClicked(true);
        }
    };

    const handleSousPartieClick = (sousPartie) => {
        setSelectedSousPartie(selectedSousPartie === sousPartie ? null : sousPartie);
        setSelectedSousMenuAccount(null);
    };

    const handleSousMenuAccountClick = (sousMenuOption, bool) => {
        navigate(sousMenuOption.link);
        setSelectedSousMenuAccount(sousMenuOption.link);
        if (bool === false) {
            setSelectedSousPartie(null);
        }
    };

    const getIconForOngletAccount = (onglet) => {
        switch (onglet) {
            case 'my_account':
                return (
                    <UserCircle
                        size={25}
                        color={Constants.$vertBocoloco}
                    />
                );
            default:
                return '';
        }
    };

    const getSousMenuAccount = (type, onglet) => {
        switch (type) {
            case Constants.ENTITY_TYPE_FS:
                return getSousMenuServiceSupplierAccount(onglet);
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                return getSousMenuIndustrialAccount(onglet);
            case Constants.ENTITY_TYPE_SORTER:
                return getSousMenuSorterAccount(onglet);
            case Constants.ENTITY_TYPE_WASHER:
                return getSousMenuWasherAccount(onglet);
            case Constants.ENTITY_TYPE_OPERATOR:
                return getSousMenuCollecteAccount(onglet);
            case Constants.ENTITY_TYPE_RVMOWNER:
                return getSousMenuRvmownerAccount(onglet);
            case Constants.ENTITY_TYPE_STOREOWNER:
                return getSousMenuStoreownerAccount(onglet);
            case Constants.ENTITY_TYPE_CDC:
                return getSousMenuCdcAccount(onglet);
            case Constants.ENTITY_TYPE_DISTRIBUTOR:
                return getSousMenuDistributorAccount(onglet);

            default:
                return [];
        }
    };

    const displayMenuAccount = checkDisplayMenuAccount();
    if (!displayMenuAccount || isError) {
        return null;
    }

    return userToken ? (
        <section className="visible menu-lateral">
            <section className="nav">
                {accessTab.map((onglet, index) => (
                    <div
                        key={index}
                        style={{
                            background: ((!isSupplierService && !isRvmOwner) || selectedOnglet === onglet) && Constants.$blanc,
                            paddingBottom: '0.5rem',
                        }}>
                        <div
                            className={(!isSupplierService && !isRvmOwner) || (isClicked && selectedOnglet === onglet) ? 'title title-selected' : 'title'}
                            onClick={() => handleOngletClick(onglet)}>
                            <div>
                                <p className={'icone-title-selected'}>{getIconForOngletAccount(onglet)}</p>
                            </div>
                            <div>
                                <p>{getSousMenuAccount(type, onglet)[0].title}</p>
                            </div>
                            <div>
                                <CaretUp
                                    style={{ color: selectedOnglet !== onglet ? Constants.$vertTresClair : Constants.$grisfonce }}
                                    size={15}
                                />
                            </div>
                        </div>
                        {((!isSupplierService && !isRvmOwner) || selectedOnglet === onglet) && (
                            <ul>
                                {getSousMenuAccount(type, onglet).map((sousPartie, sousPartieIndex) => (
                                    <div key={sousPartieIndex}>
                                        {Array.isArray(sousPartie.sousMenu) ? (
                                            <div>
                                                <div
                                                    className="sous-partie"
                                                    style={{
                                                        color: selectedSousPartie === sousPartie.sousPartie ? Constants.$vertBocoloco : '',
                                                        fontWeight: selectedSousPartie === sousPartie.sousPartie ? 600 : 400,
                                                    }}>
                                                    <div></div>
                                                    <div>
                                                        <p onClick={() => handleSousPartieClick(sousPartie.sousPartie)}>{sousPartie.sousPartie}</p>
                                                    </div>
                                                    <div></div>
                                                </div>

                                                <div className="sous-partie-text">
                                                    {selectedSousPartie === sousPartie.sousPartie && (
                                                        <ul>
                                                            {sousPartie.sousMenu.map((option, optionIndex) => (
                                                                <li key={optionIndex}>
                                                                    <div></div>
                                                                    <div>
                                                                        <span className="bullet-point">&#8226;</span>
                                                                        <p
                                                                            style={{
                                                                                color: selectedSousMenuAccount === option.link && Constants.$vertBocoloco,
                                                                                fontWeight: selectedSousMenuAccount === option.link ? 600 : 400,
                                                                            }}
                                                                            onClick={() => handleSousMenuAccountClick(option, true)}>
                                                                            {option.name}
                                                                        </p>
                                                                    </div>
                                                                    <div></div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <li
                                                className="simple-menu"
                                                style={{
                                                    display: 'flex',
                                                    color: selectedSousMenuAccount === sousPartie.link && Constants.$vertBocoloco,
                                                    fontWeight: selectedSousMenuAccount === sousPartie.link && '600',
                                                }}>
                                                <div></div>
                                                <div>
                                                    <p onClick={() => handleSousMenuAccountClick(sousPartie, false)}>{sousPartie.name}</p>
                                                </div>
                                                <div></div>
                                            </li>
                                        )}
                                    </div>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </section>
        </section>
    ) : null;
};

export default MenuAccount;
