import { useState, useEffect } from 'react';
import { AccessServiceprovider } from '../../access/AccessServiceprovider';
import { AccessIndustrial } from '../../access/AccessIndustrial';
import { AccessOperator } from '../../access/AccessOperator';
import { AccessSorter } from '../../access/AccessSorter';
import { AccessWasher } from '../../access/AccessWasher';
import { AccessRvmowner } from '../../access/AccessRvmowner';
import { AccessStoreowner } from '../../access/AccessStoreowner';
import { AccessCdc } from '../../access/AccessCdc.js';
import { AccessDistributor } from '../../access/AccessDistributor';
import Cookies from 'js-cookie';
import { Jwt } from '../../Jwt';
import * as Constants from '../../Constants';
import { transformString, handleRedirectAfterCreate } from '../../Utils';
import { getInfoUser } from '../../API/AccountApi.js';

export default function CreateUser() {
    const tokenFromCookie = Cookies.get('userToken');
    const jwt = new Jwt(tokenFromCookie);
    const [userContext] = useState(jwt);

    const [clientType, setClientType] = useState(null);
    const [entityList, setEntityList] = useState([]);
    const [interfaceAccess, setInterfaceAccess] = useState(null);
    const [userNewAccess, setUserNewAccess] = useState(null);
    const [formData, setFormData] = useState({
        entity_id: '',
        first_name: '',
        last_name: '',
        email: '',
        job: '',
        role: '',
        access: '',
    });

    useEffect(() => {
        const fetchAllEntity = async () => {
            if (userContext.entityType === Constants.ENTITY_TYPE_FS) {
                const { gearApi } = await import('../../gearApi.js');
                const entities = clientType ? await gearApi.entity.getEntities({ type: clientType }) : [];
                setEntityList(entities);
            } else {
                const userInfo = await getInfoUser(userContext.userId);
                setEntityList([{ id: userInfo[0].entity_id, company_name: userInfo[0].entity.name }]);
            }
        };
        fetchAllEntity();
    }, [clientType, userContext]);

    useEffect(() => {
        if (parseInt(userContext.entityType) !== Constants.ENTITY_TYPE_FS) {
            setClientType(userContext.entityType);
            let access = chooseAccessInterface(userContext.entityType);
            setInterfaceAccess(access);
            setUserNewAccess(access);
        }
    }, [userContext.entityType]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        const { gearApi } = await import('../../gearApi.js');

        // Always activate a user
        let userData = { ...formData, ...{ status: 0 } };
        if (parseInt(userContext.entityType) !== Constants.ENTITY_TYPE_FS) {
            userData['entity_id'] = userContext.entityId;
        }

        await gearApi.user
            .createUser({ requestBody: userData })
            .then(function () {
                alert('Utilisateur créé !');
                let redirect = handleRedirectAfterCreate(userContext.entityType);
                window.location.href = redirect;
            })
            .catch(function (error) {
                if ('body' in error && 'message' in error.body && error.body.message) {
                    alert(error.body.message);
                } else {
                    alert('Une erreur est survenue');
                }
            });
    };

    const handleClientType = async (e) => {
        setClientType(e);
        let access = chooseAccessInterface(e);
        setInterfaceAccess(access);
        setUserNewAccess(access);
    };

    const chooseAccessInterface = (entity_type) => {
        let access;
        switch (parseInt(entity_type)) {
            case Constants.ENTITY_TYPE_FS:
                access = AccessServiceprovider;
                break;
            case Constants.ENTITY_TYPE_INDUSTRIAL:
                access = AccessIndustrial;
                break;
            case Constants.ENTITY_TYPE_SORTER:
                access = AccessSorter;
                break;
            case Constants.ENTITY_TYPE_WASHER:
                access = AccessWasher;
                break;
            case Constants.ENTITY_TYPE_OPERATOR:
                access = AccessOperator;
                break;
            case Constants.ENTITY_TYPE_RVMOWNER:
                access = AccessRvmowner;
                break;
            case Constants.ENTITY_TYPE_STOREOWNER:
                access = AccessStoreowner;
                break;
            case Constants.ENTITY_TYPE_CDC:
                access = AccessCdc;
                break;
            case Constants.ENTITY_TYPE_DISTRIBUTOR:
                access = AccessDistributor;
                break;
            default:
                console.log("setAccessInterface: entity type doesn't exist");
        }
        return access;
    };

    const handleAccessChange = async (interfaceName, module, menu, submenu, e) => {
        let val = 0;
        if (!e.target.checked) {
            val = 0;
        } else {
            val = 1;
        }
        let userNewAccess_tmp = { ...userNewAccess };
        userNewAccess_tmp[interfaceName][module][menu][submenu] = val;
        setUserNewAccess(userNewAccess_tmp);
        setFormData({ ...formData, access: JSON.stringify(userNewAccess_tmp) });
    };

    const displayAccess = () => {
        return (
            <div className="access-container">
                {interfaceAccess
                    ? Object.keys(interfaceAccess).map((interfaceName, key) => {
                          return (
                              <div
                                  style={{ display: 'flex', width: '100%', gap: '1%' }}
                                  key={key}>
                                  {Object.keys(interfaceAccess[interfaceName]).map((module, key) => {
                                      return (
                                          <div
                                              className="small-section"
                                              key={key}>
                                              <h1>{module}</h1>
                                              {Object.keys(interfaceAccess[interfaceName][module]).map((menu, key) => {
                                                  return (
                                                      <div
                                                          className="access-menu"
                                                          style={{ display: 'flex', flexDirection: 'column' }}
                                                          key={key}>
                                                          <p className="menu-title">{transformString(menu)}</p>
                                                          {Object.keys(interfaceAccess[interfaceName][module][menu]).map((submenu, key) => {
                                                              return (
                                                                  <div
                                                                      className="access-submenu"
                                                                      style={{ display: 'flex', justifyContent: 'space-around' }}
                                                                      key={key}>
                                                                      <p style={{ flex: 0.2, textAlign: 'right' }}>&#8226;</p>
                                                                      <p style={{ flex: 1, paddingLeft: '0.5rem' }}>{transformString(submenu)}</p>
                                                                      <input
                                                                          type="checkbox"
                                                                          name={submenu}
                                                                          checked={userNewAccess ? userNewAccess[interfaceName][module][menu][submenu] : 0}
                                                                          onChange={(e) => handleAccessChange(interfaceName, module, menu, submenu, e)}
                                                                      />
                                                                  </div>
                                                              );
                                                          })}
                                                      </div>
                                                  );
                                              })}
                                          </div>
                                      );
                                  })}
                              </div>
                          );
                      })
                    : null}
            </div>
        );
    };

    return (
        <main>
            <section className="big-section">
                <h2>Ajouter un utilisateur</h2>
                <div className="form-container">
                    <section style={{ width: '100%' }}>
                        <div>
                            <label>Type:</label>
                            <select
                                required
                                name="type"
                                onChange={(e) => {
                                    handleClientType(e.target.value);
                                }}
                                disabled={userContext.entityType === Constants.ENTITY_TYPE_FS ? false : true}>
                                <option value={''}>--</option>
                                <option
                                    value={0}
                                    selected={userContext.entityType === Constants.ENTITY_TYPE_FS ? true : false}>
                                    Fournisseur de service
                                </option>
                                <option
                                    value={1}
                                    selected={userContext.entityType === Constants.ENTITY_TYPE_INDUSTRIAL ? true : false}>
                                    Marque
                                </option>
                                <option
                                    value={2}
                                    selected={userContext.entityType === Constants.ENTITY_TYPE_SORTER ? true : false}>
                                    Centre de tri
                                </option>
                                <option
                                    value={3}
                                    selected={userContext.entityType === Constants.ENTITY_TYPE_WASHER ? true : false}>
                                    Centre de lavage
                                </option>
                                <option
                                    value={4}
                                    selected={userContext.entityType === Constants.ENTITY_TYPE_OPERATOR ? true : false}>
                                    Operateur de collecte
                                </option>
                                <option
                                    value={5}
                                    selected={userContext.entityType === Constants.ENTITY_TYPE_RVMOWNER ? true : false}>
                                    Gestionnaire de RVM
                                </option>
                                <option
                                    value={6}
                                    selected={userContext.entityType === Constants.ENTITY_TYPE_STOREOWNER ? true : false}>
                                    Gestionnaire de Magasin
                                </option>
                                <option
                                    value={7}
                                    selected={userContext.entityType === Constants.ENTITY_TYPE_CDC ? true : false}>
                                    Chambre de compensation
                                </option>
                                <option
                                    value={8}
                                    selected={userContext.entityType === Constants.ENTITY_TYPE_DISTRIBUTOR ? true : false}>
                                    Enseigne
                                </option>
                            </select>
                        </div>
                        <div>
                            <label>Société:</label>
                            <select
                                required
                                name="entity_id"
                                disabled={userContext.entityType === Constants.ENTITY_TYPE_FS ? false : true}
                                onChange={handleChange}>
                                {userContext.entityType === Constants.ENTITY_TYPE_FS && <option>Choisissez une option</option>}

                                {entityList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}
                                        defaultValue={userContext.entityId === option.id ? true : false}>
                                        {option.company_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Nom:</label>
                            <input
                                type="text"
                                name="last_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Prénom:</label>
                            <input
                                type="text"
                                name="first_name"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Email:</label>
                            <input
                                type="text"
                                name="email"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Poste:</label>
                            <input
                                type="text"
                                name="job"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Role</label>
                            <select
                                required
                                name="role"
                                onChange={handleChange}>
                                <option value={''}>--</option>
                                {userContext.entityType === Constants.ENTITY_TYPE_FS ? (
                                    <option value={'admin'}>Administrateur</option>
                                ) : (
                                    <>
                                        <option value={'contributor'}>Contributeur</option>
                                        <option value={'viewer'}>Lecteur</option>
                                    </>
                                )}
                            </select>
                        </div>
                    </section>
                </div>
            </section>
            {interfaceAccess ? (
                <div
                    className="sheet-top-infos"
                    style={{ marginTop: 0 }}>
                    <div className="title">
                        <h2>Accès</h2>
                    </div>
                    {displayAccess()}
                    <div className="button-confirm">
                        <button
                            className="button-actif"
                            onClick={handleSubmit}>
                            Ajouter l&apos;utilisateur
                        </button>
                    </div>
                </div>
            ) : null}
        </main>
    );
}
