import { useEffect, useState } from 'react';
import { getContainersByDates } from '../../../../API/ContainerApi';
import { getAllOrdersDetailsStandardByDates } from '../../../../API/OrderApi';
import {
    getStandardWasherDeclarationDetailsByDatesCheck,
    getWasherDeclarationDetailsByOrderDetailIdDataCheck,
} from '../../../../API/CheckApi/CheckWasherDeclarationApi';
import { getStandardSorterDeclarationDetailsByDatesCheck } from '../../../../API/CheckApi/CheckSorterDeclarationApi';
import { getAllStandardSalesDetailsByDatesCheck } from '../../../../API/CheckApi/CheckSalesApi';
import { getAllOrdersDetailsStandardByDatesCheck } from '../../../../API/CheckApi/CheckOrderApi';
import { getReturnByDatesCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import { getStandardWasherDeclarationDetailsByDates, getWasherDeclarationDetailsByOrderDetailIdData } from '../../../../API/WasherDeclarationApi';
import { getSupplierOrderDetailsByOrderIdData } from '../../../../API/SupplierOrderDetailsApi';
import { getSupplierOrderDetailsByOrderIdDataCheck } from '../../../../API/CheckApi/CheckSupplierOrderDetailsApi';
import { getAllStandardSalesDetailsByDates } from '../../../../API/SalesApi';
import { getStandardSorterDeclarationDetailsByDates } from '../../../../API/SorterDeclarationApi';
import { getReturnByDates } from '../../../../API/CollectorReturnApi';
import PieChart from '../../../../Components/PieChart';
import BarChartMulti from '../../../../Components/BarChartMulti';
import MultiLineChart from '../../../../Components/MultiLineChart';
import LineChart from '../../../../Components/LineChart';
import { _defineContainerType } from '../../../../Utils';
import 'react-datepicker/dist/react-datepicker.css';
import { endOfDay } from 'date-fns';
import DateFilter from '../../../../Components/DateFilter';
import * as Constant from '../../../../Constants';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import {
    reduceDataByKeyAndQty,
    reduceDataByMonth,
    countOccurenceByKey,
    reduceByMonthAndKey,
    addMonthToTab,
    transformDataEmpliee,
    accumulateDataByMonth,
} from '../../../../UtilsData';
export default function DsitribDataReuseContainer() {
    const navigate = useNavigate();
    const now = new Date();
    const [standardContainers, setStandardContainers] = useState([]);
    const [standardContainersByMonth, setStandardContainersByMonth] = useState([]);
    const [standardContainersByType, setStandardContainersByType] = useState([]);
    const [totalContainerInCirculation, setTotalContainerInCirculation] = useState(0);
    const [allContainerInCirculation, setAllContainerInCirculation] = useState([]);
    const [containerAttributed, setContainerAttributed] = useState([]);
    const [totalReturned, setTotalReturned] = useState(0);
    const [containerReturnedByMonth, setContainerReturnedByMonth] = useState([]);
    const [containerReturnedBySku, setContainerReturnedBySku] = useState([]);
    const [chartDataImmobilized, setChartDataImmobilized] = useState();
    const [totalImmobilized, setTotalImmobilized] = useState(0);

    // FILTRES

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);

    useEffect(() => {
        const fetchData = async () => {
            setStandardContainers([]);
            setStandardContainersByMonth([]);
            setStandardContainersByType([]);
            setTotalContainerInCirculation(0);
            setAllContainerInCirculation([]);
            setContainerAttributed([]);
            setTotalReturned(0);
            setContainerReturnedByMonth([]);
            setContainerReturnedBySku([]);
            setTotalImmobilized(0);
            setChartDataImmobilized([]);

            try {
                // CONTENANTS STANDARDS
                const containerRaw = await getContainersByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const containerStandard = containerRaw.filter((container) => container.owner === Constant.CONTAINER_OWNER_STATUS_STANDARD);

                setStandardContainers(containerStandard);
                if (containerRaw.length > 0) {
                    // CONTENANTS STANDARD PAR MOIS
                    const countedByMonth = accumulateDataByMonth(containerStandard, startDate, endDate, 'createdAt', null);
                    const containerByMonthRaw = Object.keys(countedByMonth).map((month) => ({
                        month,
                        qty: countedByMonth[month],
                    }));
                    setStandardContainersByMonth(containerByMonthRaw);

                    // CONTENANTS STANDARD PAR TYPE
                    const countedByType = countOccurenceByKey(containerStandard, 'type');
                    const containerByTypeRaw = Object.keys(countedByType).map((type) => ({
                        type: _defineContainerType(type),
                        qty: ((countedByType[type] / containerStandard.length) * 100).toFixed(1),
                    }));
                    setStandardContainersByType(containerByTypeRaw);
                }
                // UNITES EN CIRCULATIONS
                const standardCirculationContainer = await getAllStandardSalesDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allSalesDetailsCheck = getAllStandardSalesDetailsByDatesCheck(standardCirculationContainer);
                if (standardCirculationContainer.length && allSalesDetailsCheck !== true) {
                    console.error(allSalesDetailsCheck);
                    Sentry.captureException(allSalesDetailsCheck);
                    navigate('/error');
                    return;
                }

                if (standardCirculationContainer.length > 0) {
                    // TOTAL UNITES EN CIRCULATION
                    const totalSales = standardCirculationContainer.reduce((acc, item) => acc + item.qty, 0);

                    setTotalContainerInCirculation(totalSales);

                    // UNITES EN CIRCULATION PAR MOIS
                    const countedSalesByMonth = accumulateDataByMonth(standardCirculationContainer, startDate, endDate, 'createdAt', 'qty');
                    const salesByMonthRaw = Object.keys(countedSalesByMonth).map((month) => ({
                        month,
                        qty: countedSalesByMonth[month],
                    }));

                    setAllContainerInCirculation(salesByMonthRaw);
                }

                // UNITES COMMANDEES ET REPARTITION NEUF / REEMPLOYE
                const allOrderDetailsStandard = await getAllOrdersDetailsStandardByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));

                const allOrderDetailsRawCheck = getAllOrdersDetailsStandardByDatesCheck(allOrderDetailsStandard);
                if (allOrderDetailsStandard.length && allOrderDetailsRawCheck !== true) {
                    console.error(allOrderDetailsRawCheck);
                    Sentry.captureException(allOrderDetailsRawCheck);
                    navigate('/error');
                    return;
                }

                if (allOrderDetailsStandard.length > 0) {
                    for (let i = 0; i < allOrderDetailsStandard.length; i++) {
                        const nb_total = allOrderDetailsStandard[i].qty * allOrderDetailsStandard[i].container_palet_nbcontainer;
                        allOrderDetailsStandard[i].nb_total = nb_total;
                    }

                    let tabAttributiontest = [];
                    for (let i = 0; i < allOrderDetailsStandard.length; i++) {
                        const washerAttributedData = await getWasherDeclarationDetailsByOrderDetailIdData(allOrderDetailsStandard[i].id);
                        const washerAttributedDataCheck = getWasherDeclarationDetailsByOrderDetailIdDataCheck(washerAttributedData);
                        if (washerAttributedData.length && washerAttributedDataCheck !== true) {
                            console.error(washerAttributedDataCheck);
                            Sentry.captureException(washerAttributedDataCheck);
                            navigate('/error');
                            return;
                        }

                        const resultwasher = combineTab(!washerAttributedData.message ? washerAttributedData : [], allOrderDetailsStandard[i], 'Réemployés');
                        const supplierAttributedData = await getSupplierOrderDetailsByOrderIdData(allOrderDetailsStandard[i].id);
                        const supplierAttributedDataCheck = getSupplierOrderDetailsByOrderIdDataCheck(supplierAttributedData);
                        if (supplierAttributedData.length && supplierAttributedDataCheck !== true) {
                            console.error(supplierAttributedDataCheck);
                            Sentry.captureException(supplierAttributedDataCheck);
                            navigate('/error');
                            return;
                        }
                        const resultSupplier = combineTab(!supplierAttributedData.message ? supplierAttributedData : [], allOrderDetailsStandard[i], 'Neufs');
                        tabAttributiontest = [...tabAttributiontest, ...resultwasher, ...resultSupplier];
                    }
                    const combinedTabWithMonth = addMonthToTab(tabAttributiontest, startDate, endDate, 'type', 'date', 'qty');
                    const transformData = transformDataEmpliee(combinedTabWithMonth, 'date', 'type', 'qty', null, false, null);
                    setContainerAttributed(transformData);
                }

                // UNITES TRIEES
                const allSorterDeclarationDetails = await getStandardSorterDeclarationDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allSorterDeclarationDetailsCheck = getStandardSorterDeclarationDetailsByDatesCheck(allSorterDeclarationDetails);
                if (allSorterDeclarationDetails.length && allSorterDeclarationDetailsCheck !== true) {
                    console.error(allSorterDeclarationDetailsCheck);
                    Sentry.captureException(allSorterDeclarationDetailsCheck);
                    navigate('/error');
                    return;
                }

                // TOTAL UNITEES TRIEES
                if (allSorterDeclarationDetails.length > 0) {
                    for (let i = 0; i < allSorterDeclarationDetails.length; i++) {
                        const nb_total = allSorterDeclarationDetails[i].qty * allSorterDeclarationDetails[i].container_palet_nbcontainer;
                        allSorterDeclarationDetails[i].nb_total = nb_total;
                    }
                }

                // UNITES LAVEES
                const allWasherDeclarationDetails = await getStandardWasherDeclarationDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allWasherDeclarationDetailsCheck = getStandardWasherDeclarationDetailsByDatesCheck(allWasherDeclarationDetails);
                if (allWasherDeclarationDetails.length && allWasherDeclarationDetailsCheck !== true) {
                    console.error(allWasherDeclarationDetailsCheck);
                    Sentry.captureException(allWasherDeclarationDetailsCheck);
                    navigate('/error');
                    return;
                }
                // TOTAL UNITEES LAVEES
                if (allWasherDeclarationDetails.length > 0) {
                    for (let i = 0; i < allWasherDeclarationDetails.length; i++) {
                        const nb_total = allWasherDeclarationDetails[i].qty * allWasherDeclarationDetails[i].container_palet_nbcontainer;
                        allWasherDeclarationDetails[i].nb_total = nb_total;
                    }
                }

                // UNITES COLLECTEES
                const allCollectorReturnRaw = await getReturnByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));

                const allCollectorReturnCheck = getReturnByDatesCheck(allCollectorReturnRaw);
                if (allCollectorReturnRaw.length && allCollectorReturnCheck !== true) {
                    console.error(allCollectorReturnCheck);
                    Sentry.captureException(allCollectorReturnCheck);
                    navigate('/error');
                    return;
                }
                // TOTAL UNITEES COLLECTEES
                if (allCollectorReturnRaw.length > 0) {
                    const totalQty = allCollectorReturnRaw.reduce((acc, item) => acc + item.qty, 0);
                    setTotalReturned(totalQty);

                    // UNITES COLLECTEES PAR MOIS
                    const containerReturnedByMonthCounted = reduceDataByMonth(allCollectorReturnRaw, startDate, endDate, 'returnedAt', 'qty');
                    const containerReturnedByMonthRaw = Object.keys(containerReturnedByMonthCounted).map((month) => ({
                        month,
                        qty: containerReturnedByMonthCounted[month],
                    }));
                    setContainerReturnedByMonth(containerReturnedByMonthRaw);
                }
                // UNITES COLLECTEES PAR SKU
                const standardSkuReturned = allCollectorReturnRaw.filter((item) => item.container_owner === Constant.CONTAINER_OWNER_STATUS_STANDARD);
                const countedBySku = reduceDataByKeyAndQty(standardSkuReturned, 'container_sku');
                const containerReturnBySkuRaw = Object.keys(countedBySku).map((sku) => ({
                    sku,
                    qty: ((countedBySku[sku] / standardSkuReturned.length) * 100).toFixed(1),
                }));
                setContainerReturnedBySku(containerReturnBySkuRaw);

                // UNITES IMMOBILISEES
                const totalImmobilizedRaw = getTotalImmobolized(
                    standardCirculationContainer,
                    allCollectorReturnRaw,
                    allSorterDeclarationDetails,
                    allWasherDeclarationDetails,
                );

                setTotalImmobilized(totalImmobilizedRaw);
                const formattedOrders = reduceByMonthAndKey(standardCirculationContainer, 'createdAt', 'container_sku', 'qty', 'sku');
                const formattedSortedDecla = reduceByMonthAndKey(allSorterDeclarationDetails, 'createdAt', 'sku', 'nb_total', 'sku');
                const formattedWasherDecla = reduceByMonthAndKey(allWasherDeclarationDetails, 'createdAt', 'sku', 'nb_total', 'sku');
                const formattedReturn = reduceByMonthAndKey(standardSkuReturned, 'returnedAt', 'container_sku', 'qty', 'sku');

                const formattedOrdersDiffTab = addMonthToTab(formattedOrders, startDate, endDate, 'sku', 'month', 'quantity');
                formattedOrdersDiffTab.forEach((item) => {
                    item.qtyMarket = item.quantity;
                });

                const formattedSortedDiffTab = addMonthToTab(formattedSortedDecla, startDate, endDate, 'sku', 'month', 'quantity');
                formattedSortedDiffTab.forEach((item) => {
                    item.qtySorter = item.quantity;
                });

                const formattedWasherDiffTab = addMonthToTab(formattedWasherDecla, startDate, endDate, 'sku', 'month', 'quantity');
                formattedWasherDiffTab.forEach((item) => {
                    item.qtyWash = item.quantity;
                });

                const formattedReturnDiffTab = addMonthToTab(formattedReturn, startDate, endDate, 'sku', 'month', 'quantity');
                formattedReturnDiffTab.forEach((item) => {
                    item.qtyReturned = item.quantity;
                });

                const chartDataImmobilizedRaw = soustraction(formattedOrdersDiffTab, formattedSortedDiffTab, formattedWasherDiffTab, formattedReturnDiffTab);

                setChartDataImmobilized(chartDataImmobilizedRaw);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (startDate & endDate) fetchData();
    }, [startDate, endDate, navigate]);

    const getTotalImmobolized = (standardCirculationContainer, allCollectorReturnRaw, allSorterDeclarationDetails, allWasherDeclarationDetails) => {
        const totalContainerInCirculation = standardCirculationContainer.reduce((acc, obj) => acc + obj.qty, 0);
        const totalSorted = allSorterDeclarationDetails.reduce((acc, obj) => acc + obj.nb_total, 0);
        const totalWashed = allWasherDeclarationDetails.reduce((acc, obj) => acc + obj.nb_total, 0);
        const totalReturned = allCollectorReturnRaw.reduce((acc, obj) => acc + obj.qty, 0);
        return totalContainerInCirculation - totalSorted - totalWashed - totalReturned;
    };

    const soustraction = (tab1, tab2, tab3, tab4) => {
        const resultat = [...tab1, ...tab2, ...tab3, ...tab4].reduce((acc, elem) => {
            const index = acc.findIndex((e) => e.sku === elem.sku && e.month === elem.month);
            if (index === -1) {
                acc.push({
                    sku: elem.sku,
                    month: elem.month,
                    qtyMarket: elem.qtyMarket || 0,
                    qtySorter: elem.qtySorter || 0,
                    qtyWash: elem.qtyWash || 0,
                    qtyReturned: elem.qtyReturned || 0,
                    qtyImmo: elem.qtyMarket - elem.qtySorter - elem.qtyWash - elem.qtyReturned,
                });
            } else {
                acc[index].qtyMarket += elem.qtyMarket || 0;
                acc[index].qtySorter += elem.qtySorter || 0;
                acc[index].qtyWash += elem.qtyWash || 0;
                acc[index].qtyReturned += elem.qtyReturned || 0;
                acc[index].qtyImmo = acc[index].qtyMarket - acc[index].qtySorter - acc[index].qtyWash - acc[index].qtyReturned;
            }
            return acc;
        }, []);
        return resultat;
    };

    const combineTab = (tab1, item, type) => {
        const countOccurrences = (tab, key) => {
            if (!tab) return {};
            return tab.reduce((acc, obj) => {
                acc[obj[key]] = (acc[obj[key]] || 0) + 1;
                return acc;
            }, {});
        };

        const count = countOccurrences(tab1);
        const monthName = new Date(item.dated).toLocaleString('default', { month: 'long' });

        const result = Object.keys(count).map((key) => ({
            date: monthName,
            qty: count[key] * item.container_palet_nbcontainer || 0,
            type: type,
        }));

        return result;
    };

    const standardContainersByMonthLabels = standardContainersByMonth.map((item) => item.month);
    const standardContainersByMonthQuantities = standardContainersByMonth.map((item) => item.qty);

    const standardContainersByTypeLabels = standardContainersByType.map((item) => item.type);
    const standardContainersByTypeQuantities = standardContainersByType.map((item) => item.qty);

    const orderByMonthsLabels = allContainerInCirculation.map((item) => item.month);
    const orderByMonthQuantities = allContainerInCirculation.map((item) => item.qty);

    const containerReturnedByMonthLabels = containerReturnedByMonth.map((item) => item.month);
    const containerReturnedByMonthQuantities = containerReturnedByMonth.map((item) => item.qty);

    const containersReturnedBySkuLabels = containerReturnedBySku.map((item) => item.sku);
    const containersReturnedBySkuQuantities = containerReturnedBySku.map((item) => item.qty);
    let chartDataImmobilizedQuantities = [];
    if (chartDataImmobilized) {
        const chartDataImmobilizedTest = [...new Set(chartDataImmobilized.map((fd) => fd.sku))];
        chartDataImmobilizedQuantities = chartDataImmobilizedTest.map((sku, index) => {
            const data = chartDataImmobilized.filter((fd) => fd.sku === sku).map((fd) => fd.qtyImmo);
            return {
                label: sku,
                data,
            };
        });
    }

    return (
        <main>
            <div className="data-container">
                <div className="header-container">
                    <div className="header-title">
                        <h1>CONTENANTS</h1>
                        <div className="date-container">
                            <DateFilter
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ height: '22rem', marginBottom: '2rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '22rem' }}>
                        <div
                            className="title"
                            style={{ fontWeight: 'bold', height: '1rem' }}>
                            Contenants standards
                        </div>

                        <div
                            style={{ height: '1rem' }}
                            className="title">
                            Nombre de contenants standard existants : {standardContainers.length}
                        </div>

                        <div style={{ display: 'flex', gap: '1%', height: '16rem' }}>
                            <div style={{ width: '50%' }}>
                                <div className="title">Evolution :</div>
                                <div style={{ height: '90%' }}>
                                    {standardContainersByMonth.length > 0 ? (
                                        <LineChart
                                            labels={standardContainersByMonthLabels}
                                            quantities={standardContainersByMonthQuantities}
                                        />
                                    ) : (
                                        <div className="no-data-graph ">Aucune donnée.</div>
                                    )}
                                </div>
                            </div>
                            <div style={{ width: '50%' }}>
                                <div className="title">Répartition par typologie (%) :</div>
                                <div style={{ height: '80%' }}>
                                    {standardContainersByType.length > 0 ? (
                                        <PieChart
                                            title={standardContainersByTypeLabels}
                                            quantities={standardContainersByTypeQuantities}
                                            position={'left'}
                                        />
                                    ) : (
                                        <div className="no-data-graph ">Aucune donnée.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div style={{ height: '22rem', marginBottom: '2rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '22rem' }}>
                        <div
                            className="title"
                            style={{ fontWeight: 'bold', height: '1rem' }}>
                            Contenants en circulation
                        </div>
                        <div
                            style={{ height: '1rem' }}
                            className="title">
                            Nombre total de contenants standards mis sur le marché : {totalContainerInCirculation}
                        </div>
                        <div style={{ display: 'flex', gap: '1%', height: '16rem' }}>
                            <div style={{ width: '50%' }}>
                                <div className="title">Evolution :</div>
                                <div style={{ height: '90%' }}>
                                    {allContainerInCirculation.length > 0 ? (
                                        <LineChart
                                            labels={orderByMonthsLabels}
                                            quantities={orderByMonthQuantities}
                                        />
                                    ) : (
                                        <div className="no-data-graph ">Aucune donnée.</div>
                                    )}
                                </div>
                            </div>
                            <div style={{ width: '50%' }}>
                                <div className="title">Nombre de contenants standards neufs et réemployés :</div>
                                <div style={{ height: '90%' }}>
                                    {containerAttributed.datasets ? (
                                        <BarChartMulti formattedData={containerAttributed} />
                                    ) : (
                                        <div className="no-data-graph">Aucune donnée.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div style={{ height: '22rem', marginBottom: '2rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '22rem' }}>
                        <div
                            className="title"
                            style={{ fontWeight: 'bold', height: '1rem' }}>
                            Contenants collectés
                        </div>
                        <div
                            style={{ height: '1rem' }}
                            className="title">
                            Nombre total de contenants collectés : {totalReturned}
                        </div>
                        <div style={{ display: 'flex', gap: '1%', height: '16rem' }}>
                            <div style={{ width: '50%' }}>
                                <div className="title">Evolution :</div>
                                <div style={{ height: '90%' }}>
                                    {containerReturnedByMonth.length > 0 ? (
                                        <LineChart
                                            labels={containerReturnedByMonthLabels}
                                            quantities={containerReturnedByMonthQuantities}
                                        />
                                    ) : (
                                        <div className="no-data-graph ">Aucune donnée.</div>
                                    )}
                                </div>
                            </div>
                            <div style={{ width: '50%' }}>
                                <div className="title">Répartition par SKU (%) :</div>
                                <div style={{ height: '80%' }}>
                                    {containerReturnedBySku.length > 0 ? (
                                        <PieChart
                                            title={containersReturnedBySkuLabels}
                                            quantities={containersReturnedBySkuQuantities}
                                            position={'left'}
                                        />
                                    ) : (
                                        <div className="no-data-graph ">Aucune donnée.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div style={{ height: '22rem', marginBottom: '2rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '22rem' }}>
                        <div
                            className="title"
                            style={{ fontWeight: 'bold', height: '1rem' }}>
                            Contenants immobilisés par le consommateur et/ou par distributeur
                        </div>
                        <div
                            style={{ height: '1rem' }}
                            className="title">
                            Nombre total de contenants standards immobilisés : {totalImmobilized > 0 ? totalImmobilized : 0}
                        </div>
                        <div style={{ display: 'flex', gap: '1%', height: '16rem' }}>
                            <div style={{ width: '100%' }}>
                                <div className="title">Evolution :</div>
                                <div style={{ height: '90%' }}>
                                    {chartDataImmobilized ? (
                                        <MultiLineChart
                                            initialData={chartDataImmobilized}
                                            datasets={chartDataImmobilizedQuantities}
                                        />
                                    ) : (
                                        <div className="no-data-graph ">Aucune donnée.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
