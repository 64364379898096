import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Logo from '../Assets/images/Logo-GEAR-by-Bocoloco.png';
import { useEffect } from 'react';

const SplashScreen = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (navigator.onLine) {
            const timer = setTimeout(() => {
                navigate('/login');
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [navigate]);

    return (
        <main className="splasing-container">
            <div className="empty"></div>
            <div className="logo-container">
                <img
                    src={Logo}
                    alt="Logo"
                />
            </div>
            <div className="loader-container">
                <div className="loader"></div>
            </div>
            <Footer />
        </main>
    );
};

export default SplashScreen;
