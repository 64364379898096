import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_SORTERS = '/sorter/get';
const BOCOLOCO_GET_SORTERBYID = '/sorter/getbyid';
const BOCOLOCO_GET_SORTERSBYNAME = '/sorter/getbyname';
const BOCOLOCO_ADD_SORTER = '/sorter/add';
const BOCOLOCO_UPDATE_SORTER = '/sorter/update';
const BOCOLOCO_GET_SORTEREVENTSBYOPERATOR = '/sorter/event/getbyoperator';
const BOCOLOCO_CREATE_PICKUPACCEPT = '/sorter/event/accept';
const BOCOLOCO_CREATE_PICKUPREQUEST = '/sorter/event/request';
const BOCOLOCO_CREATE_PICKUPDONE = '/sorter/event/done';
const BOCOLOCO_GET_SORTEREVENTSBYWASHER = '/sorter/event/getbywasher';
const BOCOLOCO_CREATE_PICKUPRECEIVED = '/sorter/event/received';
const BOCOLOCO_GET_SORTERSBYDATES = '/sorter/getbydates';
export function getSorters() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SORTERS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getSortersByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SORTERSBYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function addSorter(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_SORTER;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD WASHER');
            console.log(error);
            throw error;
        });
}

export function updateSorter(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_SORTER;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE SORTER');
            console.log(error);
            throw error;
        });
}

export function getSortersByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SORTERSBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getSorterById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SORTERBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getSorterEventsByOperator(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SORTEREVENTSBYOPERATOR;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function sorterPickUprequest(collecteData, pickUpAcceptTime) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPREQUEST;
    var url_args = '?time=' + pickUpAcceptTime;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(collecteData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR REQUEST PICK UP');
            console.log(error);
            throw error;
        });
}

export function sorterPickUpAccept(collecteData, pickUpAcceptTime) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPACCEPT;
    var url_args = '?time=' + pickUpAcceptTime;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(collecteData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ACCEPT PICK UP');
            console.log(error);
            throw error;
        });
}

export function sorterPickUpDone(collecteData, pickUpDoneTime) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPDONE;
    var url_args = '?time=' + pickUpDoneTime;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(collecteData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR DONE PICK UP');
            console.log(error);
            throw error;
        });
}

export function getSorterEventsByWasher(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_SORTEREVENTSBYWASHER;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function sorterPickUpReceived(collecteData, pickUpDoneTime) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_PICKUPRECEIVED;
    var url_args = '?time=' + pickUpDoneTime;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(collecteData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR DONE PICK UP');
            console.log(error);
            throw error;
        });
}
