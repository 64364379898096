import { useState, useEffect, useRef } from 'react';
import { getCollectors, getCollectorsLastCollecte, getCollectorsNextCollecte, getCollectorsNextDelivery } from '../../../../../API/CollectorApi';
import { _defineCollectorType, _defineCollecteStatus, _defineBoxesStoreState, getDefaultOperator } from '../../../../../Utils';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import * as Constants from '../../../../../Constants';
import { getStoreBoxesByStoreId } from '../../../../../API/StoreBoxesApi';
import { storePickUpRequest, getStoreEventDetailsBySuiviId, storePickUpPendingAccept } from '../../../../../API/StoreApi';

export default function CollectorPickup() {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [storeBoxes, setStoreBoxes] = useState([]);
    const [boxesCollecte, setBoxesCollecte] = useState([]);
    const [collecteInfos, setCollecteInfos] = useState({
        time: null,
        storeId: null,
        sorterId: null,
        operatorId: null,
        suiviCollecteId: null,
        suiviDeliveryId: null,
        from: null,
    });
    const [storeInfos, setStoreInfos] = useState([]);
    const [todo, setTodo] = useState(null);
    const dateActuelle = new Date();

    const dialogRef = useRef(null);

    useEffect(() => {
        const fetchAllCollectors = async () => {
            try {
                const result = await getCollectors();
                const groupByStoreIdAndType = result.reduce((acc, item) => {
                    const key = `${item.store.id}-${item.type}`;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(item);
                    return acc;
                }, {});

                const resultFormatted = Object.keys(groupByStoreIdAndType).map((key) => {
                    return {
                        name: groupByStoreIdAndType[key][0].store_name,
                        collectorId: groupByStoreIdAndType[key][0].type !== Constants.COLLECTOR_TYPE_TCE ? groupByStoreIdAndType[key][0].id : null,
                        storeId: groupByStoreIdAndType[key][0].store.id,
                        sorterId: groupByStoreIdAndType[key][0].sorter_id,
                        type: groupByStoreIdAndType[key][0].type,
                        filling_rate: groupByStoreIdAndType[key][0].filling_rate,
                        sorter_name: groupByStoreIdAndType[key][0].sorter_name,
                    };
                });

                for (let i = 0; i < resultFormatted.length; i++) {
                    const lastCollecte = await getCollectorsLastCollecte(resultFormatted[i].storeId);
                    if (lastCollecte[0].store.storeEvents.length) {
                        resultFormatted[i].lastCollecte = lastCollecte[0].store.storeEvents[0];
                    } else {
                        resultFormatted[i].lastCollecte = null;
                    }
                    const nextCollecte = await getCollectorsNextCollecte(resultFormatted[i].storeId);

                    const nextDelivery = await getCollectorsNextDelivery(resultFormatted[i].storeId);

                    if (nextCollecte[0].store.storeEvents.length && lastCollecte[0].store.storeEvents.length) {
                        if (nextCollecte[0].store.storeEvents[0].suiviId !== lastCollecte[0].store.storeEvents[0].suiviId) {
                            resultFormatted[i].nextCollecte = nextCollecte[0].store.storeEvents[0];
                            resultFormatted[i].nextDelivery = nextDelivery[0].store.storeEvents[0];
                        } else {
                            resultFormatted[i].nextCollecte = null;
                        }
                    } else if (nextCollecte[0].store.storeEvents.length && !lastCollecte[0].store.storeEvents.length) {
                        if (nextCollecte[0].store.storeEvents[0].status === Constants.EVENT_RECEIVED) {
                            resultFormatted[i].nextCollecte = null;
                        } else {
                            resultFormatted[i].nextCollecte = nextCollecte[0].store.storeEvents[0];
                            resultFormatted[i].nextDelivery = nextDelivery[0].store.storeEvents[0];
                        }
                    }
                }

                let tab = [];

                for (let i = 0; i < resultFormatted.length; i++) {
                    if (resultFormatted[i].filling_rate >= 80 && !resultFormatted[i].nextCollecte) {
                        tab.push(resultFormatted[i]);
                    } else if (resultFormatted[i].nextCollecte) {
                        tab.push(resultFormatted[i]);
                    } else if (resultFormatted[i].lastCollecte) {
                        const dateNow = new Date();
                        const dateLastCollecte = new Date(resultFormatted[i].lastCollecte);
                        const differenceInDaysValue = Math.floor((dateNow - dateLastCollecte) / (1000 * 60 * 60 * 24));
                        if (differenceInDaysValue > 21) {
                            tab.push(resultFormatted[i]);
                        }
                    }
                }

                setData(tab);
            } catch (error) {
                console.error('Erreur lors de la récupération des collectors', error);
            }
        };

        fetchAllCollectors();
    }, [navigate]);

    const handleModalOpen = async (item) => {
        const operator = getDefaultOperator();

        setStoreInfos(item);

        if (item.nextCollecte && item.nextCollecte.status === Constants.EVENT_PENDING) {
            const getDetails = await getStoreEventDetailsBySuiviId(item.nextCollecte.suiviId);

            setCollecteInfos({
                ...collecteInfos,
                time: format(item.nextCollecte.time, 'yyyy-MM-dd'),
                storeId: item.storeId,
                sorterId: item.sorterId,
                operatorId: operator,
                suiviCollecteId: item.nextCollecte.suiviId,
                suiviDeliveryId: item.nextDelivery.suiviId,
            });

            const groupedData = getDetails.reduce((acc, item) => {
                const name = `${item.container.name} ${_defineBoxesStoreState(item.empty)}`;

                if (!acc[name]) {
                    acc[name] = [];
                }

                acc[name].push(item);

                return acc;
            }, {});

            const result = Object.keys(groupedData).map((name) => ({
                name,
                items: groupedData[name],
            }));

            setStoreBoxes(result);
            setTodo('to validate');
        } else if (item.nextCollecte && item.nextCollecte.status !== Constants.EVENT_PENDING) {
            const getDetails = await getStoreEventDetailsBySuiviId(item.nextCollecte.suiviId);
            const groupedData = getDetails.reduce((acc, item) => {
                const name = `${item.container.name} ${_defineBoxesStoreState(item.empty)}`;

                if (!acc[name]) {
                    acc[name] = [];
                }

                acc[name].push(item);

                return acc;
            }, {});

            const result = Object.keys(groupedData).map((name) => ({
                name,
                items: groupedData[name],
            }));
            setStoreBoxes(result);
            setTodo('to see');
        } else if (!item.nextCollecte) {
            const storeBoxesData = await getStoreBoxesByStoreId(item.storeId);
            const boxesFilled = storeBoxesData.filter((item) => item.empty === false);
            const groupedData = boxesFilled.reduce((acc, item) => {
                const name = item.container.name;

                if (!acc[name]) {
                    acc[name] = [];
                }

                acc[name].push(item);

                return acc;
            }, {});

            const result = Object.keys(groupedData).map((name) => ({
                name,
                items: groupedData[name],
            }));
            setCollecteInfos({
                ...collecteInfos,
                storeId: item.storeId,
                sorterId: item.sorterId,
                operatorId: operator,
                from: Constants.STOREEVENTDETAILS_FROM_MANUAL_FS,
            });
            setStoreBoxes(result);
            setTodo('to request');
        }

        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    useEffect(() => {
        const initialFormData = storeBoxes.map((item) => ({
            containerName: item.name,
            containerId: item.items[0].containerId,
            qty: todo === 'to request' ? 0 : item.items[0].qty,
        }));
        setBoxesCollecte(initialFormData);
    }, [
        storeBoxes,
        todo,
    ]);

    const handleInputChange = (index, value) => {
        const newFormData = [...boxesCollecte];
        newFormData[index] = { ...newFormData[index], qty: value };

        setBoxesCollecte(newFormData);
    };

    const handleModalClose = () => {
        setTodo(null);
        setBoxesCollecte([]);
        setCollecteInfos({ time: null, storeId: null, sorterId: null, operatorId: null });
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const sendPickUpRequest = async () => {
        const data = {
            boxesCollecte,
            collecteInfos,
        };
        const request = await storePickUpRequest(data);
        if (request.message === 'ok') {
            alert('La collecte a bien été effectuée');
            handleModalClose();
            window.location.reload();
        } else {
            alert('Une erreur est survenue');
        }
    };

    const acceptPendingPickUp = async () => {
        const data = {
            boxesCollecte,
            collecteInfos,
        };
        const response = await storePickUpPendingAccept(data);
        if (response.message === 'ok') {
            alert('La collecte a bien été effectuée');
            handleModalClose();
            window.location.reload();
        } else {
            alert('Une erreur est survenue');
        }
    };

    return (
        <main>
            <section className="big-section">
                <h2>Collectes en cours - à programmer</h2>
                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600', width: '100%' }}>
                        <p>Nom</p>
                        <p>Type</p>
                        <p>Taux</p>
                        <p>Dernière collecte</p>
                        <p>Centre de tri</p>
                        <p>Prochaine collecte estimée</p>
                        <p>Statut</p>
                    </div>

                    {data.length
                        ? data.map((item, index) => {
                              return (
                                  <div
                                      style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', cursor: 'pointer' }}
                                      key={index}
                                      onClick={() => {
                                          handleModalOpen(item);
                                      }}>
                                      <div
                                          style={{ width: '100%' }}
                                          className="pre-filled-fields-2-several-item">
                                          <p>{item.name}</p>
                                          <p>{_defineCollectorType(item.type)}</p>
                                          <p>{item.filling_rate}</p>
                                          <p>{item.lastCollecte ? format(item.lastCollecte.time, 'yyyy-MM-dd') : ''}</p>
                                          <p>{item.sorter_name}</p>
                                          <p>{item.nextCollecte ? format(item.nextCollecte.time, 'yyyy-MM-dd') : ''}</p>
                                          <p
                                              style={{
                                                  backgroundColor: item.nextCollecte && _defineCollecteStatus(item.nextCollecte.status).color,
                                                  color: Constants.$blanc,
                                                  borderTopRightRadius: '0.62rem',
                                                  borderBottomRightRadius: '0.62rem',
                                              }}>
                                              {item.nextCollecte ? _defineCollecteStatus(item.nextCollecte.status).name : ''}
                                          </p>
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p> {todo === 'to validate' || todo === 'to request' ? 'Demande de' : 'Information'} collecte</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                {todo === 'to request' ? (
                    <div>
                        {boxesCollecte.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="collector-infos">
                                    <div className="depart-arrival-container">
                                        <div
                                            className="departure"
                                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <h4>Contenant</h4>
                                            <p>{item.containerName}</p>
                                        </div>
                                        <div>
                                            <h4>Quantité</h4>
                                            <select
                                                value={item.qty}
                                                onChange={(e) => {
                                                    handleInputChange(index, e.target.value);
                                                }}>
                                                <option value="">--</option>
                                                {Array.from({ length: storeBoxes[index].items.length }, (_, i) => i + 1).map((num) => (
                                                    <option
                                                        key={num}
                                                        value={num}>
                                                        {num}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div
                            className="depart-arrival-container"
                            style={{ display: 'flex', marginTop: '1rem' }}>
                            <div
                                className="departure"
                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '50%' }}>
                                <h4>Date collecte :</h4>
                            </div>
                            <div style={{ width: '50%' }}>
                                <input
                                    style={{ width: '67%' }}
                                    min={format(dateActuelle, 'yyyy-MM-dd')}
                                    type="date"
                                    onChange={(e) => {
                                        setCollecteInfos({ ...collecteInfos, time: e.target.value });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="collector-validate">
                            <button
                                className="button-actif"
                                onClick={sendPickUpRequest}>
                                VALIDER
                            </button>
                        </div>
                    </div>
                ) : todo === 'to validate' ? (
                    <div
                        className="depart-arrival-container"
                        style={{ display: 'flex', marginTop: '1rem', flexDirection: 'column', gap: '20px' }}>
                        <p>
                            Magasin : <span style={{ fontWeight: 400 }}>{storeInfos.name}</span>{' '}
                        </p>
                        <div
                            className="collector-infos"
                            style={{ width: '50%' }}>
                            <p style={{ marginBottom: '1rem' }}>A récuperer :</p>
                            {storeBoxes.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="collector-infos"
                                        style={{
                                            fontWeight: 400,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}>
                                        <p>{item.name}</p>
                                        <p>x{item.items[0].qty}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <p>
                            Le : <span style={{ fontWeight: 400 }}> {format(storeInfos.nextCollecte.time, 'yyyy-MM-dd')}</span>
                        </p>

                        <div
                            className="collector-validate"
                            style={{ gap: '3%' }}>
                            <button
                                className="button-actif"
                                onClick={acceptPendingPickUp}>
                                VALIDER
                            </button>
                            <button
                                style={{ backgroundColor: Constants.$rouge }}
                                className="button-actif">
                                REFUSER
                            </button>
                        </div>
                    </div>
                ) : todo === 'to see' ? (
                    <div
                        className="depart-arrival-container"
                        style={{ display: 'flex', marginTop: '1rem', flexDirection: 'column', gap: '20px' }}>
                        <p>
                            Magasin : <span style={{ fontWeight: 400 }}>{storeInfos.name}</span>{' '}
                        </p>
                        <div
                            className="collector-infos"
                            style={{ width: '50%' }}>
                            <p style={{ marginBottom: '1rem' }}>A récuperer :</p>
                            {storeBoxes.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="collector-infos"
                                        style={{
                                            fontWeight: 400,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}>
                                        <p>{item.name}</p>
                                        <p>x{item.items[0].qty}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <p>
                            Le : <span style={{ fontWeight: 400 }}> {format(storeInfos.nextCollecte.time, 'yyyy-MM-dd')}</span>
                        </p>
                    </div>
                ) : null}
            </dialog>
        </main>
    );
}
