// SALES API
import { isDataValid } from '../../Utils';

export const getAllSalesDetailsByDatesCheck = (data) => {
    const name = 'getAllStandardSalesDetailsByDates : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].createdAt) {
            return name + 'No sales details found';
        } else {
            return true;
        }
    }
};

export const getAllStandardSalesDetailsByDatesCheck = (data) => {
    const name = 'getAllStandardSalesDetailsByDates : ';
    for (let i = 0; i < data.length; i++) {
        if (!isDataValid(data[i].createdAt)) {
            return name + 'No date found';
        } else if (!isDataValid(data[i].qty)) {
            return name + 'No quantity found';
        } else if (!isDataValid(data[i].sale_type)) {
            return name + 'No sales type found';
        } else if (!isDataValid(data[i].container_sku)) {
            return name + 'No container sku found';
        } else {
            return true;
        }
    }
};

export const getAllSalesDetailsByDatesAndIndusCheck = (data) => {
    const name = 'getAllSalesDetailsByDatesAndIndus : ';
    for (let i = 0; i < data.length; i++) {
        if (!isDataValid(data[i].createdAt)) {
            return name + 'No date found';
        } else if (!isDataValid(data[i].qty)) {
            return name + 'No quantity  found';
        } else if (!isDataValid(data[i].sale_type)) {
            return name + 'No type sale found';
        } else if (!isDataValid(data[i].container_sku)) {
            return name + 'No container sku';
        } else if (!isDataValid(data[i].container_owner)) {
            return name + 'No container owner found';
        } else if (!isDataValid(data[i].product_ean)) {
            return name + 'No product ean found';
        } else if (!isDataValid(data[i].product_name)) {
            return name + 'No product name found';
        } else if (!isDataValid(data[i].product_cat)) {
            return name + 'No container category found';
        } else if (!isDataValid(data[i].container_price)) {
            return name + 'No container price found';
        } else {
            return true;
        }
    }
};
