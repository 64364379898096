import { useState, useEffect } from 'react';
import { getIndustrialById, getIndustrialAddressById, getIndustrialProductByIndusId } from '../../../../../API/IndustrialApi';
import { _defineStatus } from '../../../../../Utils';
import { Link, useLocation } from 'react-router-dom';
import { Pencil } from '@phosphor-icons/react';
import * as Constant from '../../../../../Constants';

export default function IndustrialSheet() {
    const [filterAdressSelected, setFilterAdressSelected] = useState('asc');
    const [filterProductSelected, setFilterProductSelected] = useState('asc');
    const [industrialData, setIndustrialData] = useState([]);
    const [indusAddressData, setIndusAddressData] = useState([]);
    const [indusProductData, setIndusProductData] = useState([]);
    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const fetchIndustrialData = async () => {
            try {
                const indusData = await getIndustrialById(id);
                if (indusData) {
                    setIndustrialData(indusData[0]);
                } else {
                    throw new Error('Erreur lors de la récupération des données');
                }

                const indusAddress = await getIndustrialAddressById(id);
                if (indusAddress) {
                    setIndusAddressData(indusAddress);
                } else {
                    throw new Error('Erreur lors de la récupération des données');
                }

                const indusProduct = await getIndustrialProductByIndusId(id);
                if (indusProduct) {
                    setIndusProductData(indusProduct);
                } else {
                    throw new Error('Erreur lors de la récupération des données');
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des infos de l'industriel", error);
            }
        };
        fetchIndustrialData();
    }, [id]);

    const sortDataAsc = (data, setData, type) => {
        const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
        setData(sortedData);
        if (type === 'product') {
            setFilterProductSelected('asc');
        } else if (type === 'address') {
            setFilterAdressSelected('asc');
        }
    };

    const sortDataDesc = (data, setData, type) => {
        const sortedData = [...data].sort((a, b) => b.name.localeCompare(a.name));
        setData(sortedData);
        if (type === 'product') {
            setFilterProductSelected('desc');
        } else if (type === 'address') {
            setFilterAdressSelected('desc');
        }
    };
    return (
        <main>
            {industrialData.id && (
                <div className="sheet">
                    <div className="sheet-top-infos">
                        <div className="title">
                            <Link
                                className="update-indus"
                                to={'/servicesupplier/operation/industrial/update'}
                                state={{ id: industrialData.id }}>
                                <div className="icon">
                                    <Pencil size={20} />
                                </div>
                            </Link>
                            <h2>{industrialData.company_name}</h2>
                            <div
                                className="status"
                                style={{ backgroundColor: _defineStatus(industrialData.status).color }}>
                                {_defineStatus(industrialData.status).name}
                            </div>
                        </div>

                        <section className="item-info-container">
                            <div className="border">
                                <div>
                                    <p>
                                        Société : <span>{industrialData.company_name}</span>
                                    </p>
                                    <p>
                                        Siret : <span>{industrialData.siret}</span>{' '}
                                    </p>
                                    <p>
                                        Tva intra : <span>{industrialData.tva_intra}</span>{' '}
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Adresse 1 : <span>{industrialData.address_1}</span>
                                    </p>
                                    <p>
                                        Adresse 2 : <span>{industrialData.address_2}</span>
                                    </p>
                                    <p>
                                        Ville : <span>{industrialData.city}</span>
                                    </p>
                                    <p>
                                        Code postal : <span>{industrialData.zipcode}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="border">
                                <div>
                                    <p>
                                        Contact : <span>{industrialData.contact_name}</span>
                                    </p>
                                    <p>
                                        Email : <span>{industrialData.contact_email}</span>
                                    </p>
                                    <p>
                                        Tél : <span>{industrialData.contact_phone}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les adresses</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterAdressSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(indusAddressData, setIndusAddressData, 'address');
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterAdressSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(indusAddressData, setIndusAddressData, 'address');
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/industrial/address/add'}
                                    state={{ entity_id: industrialData.id }}
                                    className="button-actif">
                                    AJOUTER UNE ADRESSE
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Nom</p>
                                <p>Adresse 1</p>
                                <p>Adresse 2</p>
                                <p>Ville</p>
                                <p>Code postal</p>
                                <p>Nom contact</p>
                                {/* <p>Tel contact</p> */}
                                {/* <p style={{ flex: 1.5 }}>Email contact</p> */}
                                <p>Statut</p>
                            </div>

                            {indusAddressData.length ? (
                                indusAddressData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/industrial/address/update'}
                                            state={{ id: item.id }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.name}</p>
                                            <p>{item.address_1}</p>
                                            <p>{item.address_2 || 'N.D'}</p>
                                            <p>{item.city}</p>
                                            <p>{item.zipcode}</p>
                                            <p>{item.contact_name}</p>
                                            {/* <p>{item.contact_phone}</p> */}
                                            {/* <p style={{ flex: 1.5 }}>{item.contact_email}</p> */}
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatus(item.status).color,
                                                    color: Constant.$blanc,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                }}>
                                                {_defineStatus(item.status).name}
                                            </p>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div style={{ textAlign: 'center' }}>
                                    <p>Aucune adresse enregistrée.</p>
                                </div>
                            )}
                        </div>
                    </section>

                    <section className="big-section">
                        <div className="top">
                            <h2 style={{ margin: '0' }}>Les produits</h2>
                            <div className="top-button-sort">
                                <div className="filter">
                                    <p className="sort">Trier par</p>
                                    <div>
                                        <p
                                            className={filterProductSelected === 'asc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataAsc(indusProductData, setIndusProductData, 'product');
                                            }}>
                                            A &gt; Z
                                        </p>
                                        <p
                                            className={filterProductSelected === 'desc' ? 'selected' : 'classic'}
                                            onClick={() => {
                                                sortDataDesc(indusProductData, setIndusProductData, 'product');
                                            }}>
                                            Z &gt; A
                                        </p>
                                    </div>
                                </div>
                                <Link
                                    to={'/servicesupplier/operation/industrial/product/add'}
                                    state={{ entity_id: industrialData.id }}
                                    className="button-actif">
                                    AJOUTER UN PRODUIT
                                </Link>
                            </div>
                        </div>

                        <div className="tab-item">
                            <div
                                className="pre-filled-fields-1-several-item"
                                style={{ fontWeight: '600' }}>
                                <p>Nom</p>
                                <p>EAN</p>
                                <p>SKU</p>
                                <p>Statut</p>
                            </div>

                            {indusProductData.length ? (
                                indusProductData.map((item, index) => {
                                    return (
                                        <Link
                                            to={'/servicesupplier/operation/industrial/product/update'}
                                            state={{ id: item.id }}
                                            key={index}
                                            className="pre-filled-fields-2-several-item">
                                            <p>{item.name}</p>
                                            <p>{item.ean}</p>
                                            <p>{item.sku}</p>
                                            <p
                                                style={{
                                                    backgroundColor: _defineStatus(item.status).color,
                                                    color: Constant.$blanc,
                                                    borderTopRightRadius: '0.6rem',
                                                    borderBottomRightRadius: '0.6rem',
                                                }}>
                                                {_defineStatus(item.status).name}
                                            </p>
                                        </Link>
                                    );
                                })
                            ) : (
                                <div style={{ textAlign: 'center' }}>
                                    <p>Aucun produit enregistré.</p>
                                </div>
                            )}
                        </div>
                    </section>
                </div>
            )}
        </main>
    );
}
