import { getGearAPIApiEndpoint } from './endpoints';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_DISTRIBUTORS = '/distributor/get';
const BOCOLOCO_GET_DISTRIBUTORBYID = '/distributor/getbyid';
const BOCOLOCO_GET_DISTRIBUTORSBYNAME = '/distributor/getbyname';
const BOCOLOCO_ADD_DISTRIBUTOR = '/distributor/add';
const BOCOLOCO_UPDATE_DISTRIBUTOR = '/distributor/update';

export function getDistributors() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DISTRIBUTORS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function addDistributor(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_DISTRIBUTOR;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD WASHER');
            console.log(error);
            throw error;
        });
}

export function updateDistributor(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_DISTRIBUTOR;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE DISTRIBUTOR');
            console.log(error);
            throw error;
        });
}

export function getDistributorsByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DISTRIBUTORSBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}

export function getDistributorById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DISTRIBUTORBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        });
}
