import { useEffect, useState } from 'react';
import PieChart from '../../../../Components/PieChart.jsx';
import { CSVLink } from 'react-csv';
import { getRegionsName } from '../../../../Utils';
import DateFilter from '../../../../Components/DateFilter';
import * as Constant from '../../../../Constants';
import { countOccurenceByKey } from '../../../../UtilsData.js';

export default function IndusDataReuseWasher() {
    const now = new Date();
    const [washerActive, setwasherActive] = useState(0);
    const [washerByRegion, setwasherByRegion] = useState([]);
    const [formattedData, setFormattedData] = useState([]);

    // FILTRES
    const [startDate, setStartDate] = useState(new Date(now.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);

    useEffect(() => {
        const fetchData = async () => {
            const { gearApi } = await import('../../../../gearApi.js');
            const { endOfDay } = await import('date-fns');

            setwasherByRegion([]);
            setwasherActive(0);
            setFormattedData([]);

            // In case we have nothing specified simply don't put a limit
            const betweenDate = {};
            if (startDate) {
                betweenDate.existsSince = startDate;
            } else {
                betweenDate.existsSince = null;
            }
            if (endDate) {
                // Make sure we use the full day
                betweenDate.existsUntil = endOfDay(endDate);
            } else {
                betweenDate.existsUntil = null;
            }

            const washerRaw = await gearApi.washer.getWashers(betweenDate);
            const washerActiveRaw = washerRaw.filter((washer) => washer.status === 0);

            const countedRegions = countOccurenceByKey(washerActiveRaw, 'region');
            const regionsArray = Object.keys(countedRegions).map((region) => ({
                region: getRegionsName(region),
                qty: ((countedRegions[region] / washerActiveRaw.length) * 100).toFixed(1),
            }));

            const formattedDataRaw = washerActiveRaw.map((item) => {
                const washer = {
                    nom: item.company_name,
                    region: getRegionsName(item.region),
                    zipcode: item.zipcode,
                    city: item.city,
                };
                return washer;
            });

            setwasherByRegion(regionsArray);
            setwasherActive(washerActiveRaw.length);
            setFormattedData(formattedDataRaw);
        };
        fetchData();
    }, [startDate, endDate]);

    const washerByRegionQuantities = washerByRegion.map((item) => item.qty);
    const washerByRegionLabels = washerByRegion.map((item) => item.region);

    const customHeader = [
        {
            label: 'Nom',
            key: 'nom',
        },
        {
            label: 'Code postal',
            key: 'zipcode',
        },
        {
            label: 'Ville',
            key: 'city',
        },
        {
            label: 'Région',
            key: 'region',
        },
    ];

    return (
        <main>
            <div className="data-container">
                <div className="header-container">
                    <div className="header-title">
                        <h1>CENTRE DE LAVAGE</h1>
                        <div className="date-container">
                            <DateFilter
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                            <div className="extract-content">
                                <div className="extract">
                                    <CSVLink
                                        style={{ textDecoration: 'none', color: Constant.$blanc }}
                                        className="extract-button"
                                        headers={customHeader}
                                        data={formattedData ? formattedData : []}
                                        filename={`Les_centres_de_lavage.csv`}
                                        target="_blank"
                                        separator={';'}>
                                        Extraire la donnée
                                    </CSVLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-number"
                        style={{ width: '33%' }}>
                        <div className="title">Nombre de centres actifs</div>
                        <div className="content">
                            <div className="number">{washerActive}</div>
                            <div className="text"></div>
                        </div>
                    </section>
                    <section style={{ width: '50%' }}></section>
                </div>

                <div style={{ display: 'flex', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ width: '33%' }}>
                        <div className="title">Répartition des centres de lavage par région (%) :</div>
                        <div style={{ height: '13rem' }}>
                            {washerByRegion.length > 0 ? (
                                <PieChart
                                    title={washerByRegionLabels}
                                    quantities={washerByRegionQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
