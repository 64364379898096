import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_GET_INDUSTRIALS = '/industrial/get';
const BOCOLOCO_GET_INDUSTRIALBYID = '/industrial/getbyid';
const BOCOLOCO_GET_INDUSTRIALSBYNAME = '/industrial/getbyname';
const BOCOLOCO_GET_INDUSTRIALGETADDRESS = '/industrial/address/get';
const BOCOLOCO_GET_INDUSTRIALGETADDRESSBYID = '/industrial/address/getbyid';
const BOCOLOCO_ADD_INDUSTRIALADDRESS = '/industrial/address/add';
const BOCOLOCO_UPDATE_INDUSTRIALADDRESS = '/industrial/address/update';
const BOCOLOCO_GET_INDUSTRIALGETBYZIPCODEANDENTITYID = '/industrial/address/getbyzipcodeandentityid';
const BOCOLOCO_GET_INDUSTRIALGETPRODUCT_BYENTITYID = '/industrial/product/getbyentityid';
const BOCOLOCO_GET_ALLINDUSTRIALGETPRODUCT = '/industrial/product/getall';
const BOCOLOCO_GET_INDUSTRIALPRODUCTSBYNAME = '/industrial/product/getbyname';
const BOCOLOCO_GET_INDUSTRIALPRODUCTBYDATES = '/industrial/product/bydates';
const BOCOLOCO_GET_INDUSTRIALPRODUCTBYDATESANDENTITY = '/industrial/product/bydatesandindus';
const BOCOLOCO_ADD_INDUSTRIALPRODUCT = '/industrial/product/add';
const BOCOLOCO_UPDATE_INDUSTRIALPRODUCT = '/industrial/product/update';
const BOCOLOCO_GET_INDUSTRIALGETPRODUCTBYID = '/industrial/product/getbyid';
const BOCOLOCO_ADD_INDUSTRIAL = '/industrial/add';
const BOCOLOCO_UPDATE_INDUSTRIAL = '/industrial/update';

export function getIndustrials() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function addIndustrial(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_INDUSTRIAL;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD CLIENT');
            console.log(error);
            throw error;
        });
}

export function getIndustrialsByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALSBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getIndustrialById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getIndustrialAddressById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALGETADDRESS;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getAddressInfoById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALGETADDRESSBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
export function getAllIndustrialProduct() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ALLINDUSTRIALGETPRODUCT;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getIndustrialProductByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALPRODUCTBYDATES;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getIndustrialProductByDatesAndIndus(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALPRODUCTBYDATESANDENTITY;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getIndustrialProductByName(name) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALPRODUCTSBYNAME;
    var url_args = '?name=' + name;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getIndustrialProductByIndusId(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALGETPRODUCT_BYENTITYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateIndustrial(id, updatedData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_INDUSTRIAL;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updatedData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE CLIENT');
            console.log(error);
            throw error;
        });
}

export function addIndustrialAddress(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_INDUSTRIALADDRESS;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD INDUSTRIAL ADDRESS');
            console.log(error);
            throw error;
        });
}

export function updateIndustrialAddress(id, formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_INDUSTRIALADDRESS;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE INDUSTRIAL ADDRESS');
            console.log(error);
            throw error;
        });
}

export function getIndustrialAdressByZipcodeAndEntityId(zipcode) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALGETBYZIPCODEANDENTITYID;
    var url_args = '?zipcode=' + zipcode;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getProductInfoById(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_INDUSTRIALGETPRODUCTBYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function addIndustrialProduct(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_ADD_INDUSTRIALPRODUCT;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR ADD INDUSTRIAL PRODUCT');
            console.log(error);
            throw error;
        });
}

export function updateIndustrialProduct(id, formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_INDUSTRIALPRODUCT;
    const url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE INDUSTRIAL ADDRESS');
            console.log(error);
            throw error;
        });
}
