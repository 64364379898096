// ORDERS API
import { isDataValid } from '../../Utils';
export const getOrderDetailsByEntityIdCheck = (data) => {
    const name = 'getOrderDetailsByEntityId : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].order.id) {
            return name + 'No order id found';
        } else if (!data[i].container.id) {
            return name + 'No container id found';
        } else {
            return true;
        }
    }
};

export const getOrdersDetailsNotCompletedCheck = (data) => {
    const name = 'getOrdersDetailsNotCompleted : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].order.id) {
            return name + 'No order id found';
        } else if (!data[i].order.entity.id) {
            return name + 'No entity id found';
        } else if (!data[i].container.id) {
            return name + 'No container id found';
        } else {
            return true;
        }
    }
};

export const getOrderDetailsInTransitCheck = (data) => {
    const name = 'getOrderDetailsInTransit : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].order.id) {
            return name + 'No order id found';
        } else if (!data[i].washerDeclarationDetails) {
            return name + 'No washer Declaration Details found';
        } else if (data[i].washerDeclarationDetails.length) {
            for (let j = 0; j < data[i].washerDeclarationDetails.length; j++) {
                if (!data[i].washerDeclarationDetails[j].id) {
                    return name + 'No washer declaration id found';
                } else if (!data[i].washerDeclarationDetails[j].suivi.id) {
                    return name + 'No suivi id found';
                } else if (!data[i].washerDeclarationDetails[j].suivi.washerEvents[0].status) {
                    return name + 'No status found';
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }
};

export const getAllOrdersDetailsStandardByDatesCheck = (data) => {
    const name = 'getAllOrdersDetailsByDates : ';
    for (let i = 0; i < data.length; i++) {
        if (!isDataValid(data[i].dated)) {
            return name + 'No delivery date found';
        } else if (!isDataValid(data[i].id)) {
            return name + 'No id found';
        } else if (!isDataValid(data[i].qty)) {
            return name + 'No quantity found';
        } else if (!isDataValid(data[i].sku)) {
            return name + 'No sku found';
        } else if (!isDataValid(data[i].container_palet_nbcontainer)) {
            return name + 'No palet nb container found';
        } else {
            return true;
        }
    }
};
