import { useState, useEffect } from 'react';
import { getCollectorsByStoreOwnerId, getCollectorsLastCollecte, getCollectorsNextCollecte } from '../../../API/CollectorApi';
import { _defineCollecteurStatus, _defineCollectorType } from '../../../Utils';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Jwt } from '../../../Jwt';
import * as Constant from '../../../Constants';

export default function Collector() {
    const navigate = useNavigate();
    const userContext = new Jwt();
    const [AllCollectorList, setAllCollectorList] = useState([]);

    useEffect(() => {
        const fetchAllCollectors = async () => {
            try {
                const result = await getCollectorsByStoreOwnerId(userContext.entityId);
                for (let i = 0; i < result.length; i++) {
                    const lastCollecte = await getCollectorsLastCollecte(result[i].store.id);
                    if (lastCollecte[0].store.storeEvents.length) {
                        result[i].lastCollecte = lastCollecte[0].store.storeEvents[0];
                    } else {
                        result[i].lastCollecte = null;
                    }
                    const nextCollecte = await getCollectorsNextCollecte(result[i].store.id);
                    if (nextCollecte[0].store.storeEvents.length && lastCollecte[0].store.storeEvents.length) {
                        if (nextCollecte[0].store.storeEvents[0].suiviId !== lastCollecte[0].store.storeEvents[0].suiviId) {
                            result[i].nextCollecte = nextCollecte[0].store.storeEvents[0];
                        } else {
                            result[i].nextCollecte = null;
                        }
                    } else if (nextCollecte[0].store.storeEvents.length && !lastCollecte[0].store.storeEvents.length) {
                        if (nextCollecte[0].store.storeEvents[0].status === Constant.EVENT_RECEIVED) {
                            result[i].nextCollecte = null;
                        } else {
                            result[i].nextCollecte = nextCollecte[0].store.storeEvents[0];
                        }
                    }
                }

                const sortedData = result.sort((a, b) => a.id - b.id);
                setAllCollectorList(sortedData);
            } catch (error) {
                console.error('Erreur lors de la récupération des collectors', error);
            }
        };
        fetchAllCollectors();
    }, [userContext.entityId]);

    const handleCollectorClick = (item) => {
        navigate('/storeowner/operation/collector/sheet', { state: { id: item.id } });
    };

    return (
        <main>
            <section className="big-section">
                <h2>Les collecteurs </h2>

                <div className="tab-item">
                    <div
                        className="pre-filled-fields-1-several-item"
                        style={{ fontWeight: '600', width: '100%' }}>
                        <p style={{ flex: 0.5 }}>ID</p>
                        <p style={{ flex: 0.5 }}>Type</p>
                        <p>Fabricant</p>
                        <p>Modèle</p>
                        <p>Identifiant</p>
                        <p>Capacité</p>
                        <p>Statut</p>
                        <p>Tx remplissage</p>
                        <p>Dernière collecte</p>
                        <p>Date demande collecte</p>
                    </div>
                    {AllCollectorList.length
                        ? AllCollectorList.map((item, index) => {
                              return (
                                  <div
                                      style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}
                                      key={index}>
                                      <div
                                          style={{ width: '100%', cursor: 'pointer' }}
                                          onClick={() => {
                                              handleCollectorClick(item);
                                          }}
                                          className="pre-filled-fields-2-several-item">
                                          <p style={{ flex: 0.5 }}> {item.id}</p>
                                          <p style={{ flex: 0.5 }}>{_defineCollectorType(item.type)}</p>
                                          <p>{item.supplier_name}</p>
                                          <p>{item.supplier_model}</p>
                                          <p>{item.device_id}</p>
                                          <p>{item.capacity}</p>
                                          <p
                                              style={{
                                                  color: Constant.$blanc,
                                                  backgroundColor: _defineCollecteurStatus(item.status).color,
                                              }}>
                                              {_defineCollecteurStatus(item.status).name}
                                          </p>
                                          <p>{item.filling_rate}</p>
                                          <p>{item.lastCollecte ? format(item.lastCollecte.time, 'yyyy-MM-dd') : ''}</p>
                                          <p>{item.nextCollecte ? format(item.nextCollecte.time, 'yyyy-MM-dd') : ''}</p>
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </section>
        </main>
    );
}
