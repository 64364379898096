import { useEffect, useState } from 'react';
import { getCollectorsByDates } from '../../../../API/CollectorApi';
import { getAllStandardSalesDetailsByDatesCheck } from '../../../../API/CheckApi/CheckSalesApi';
import { getReturnByDatesCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import { getCollectorsByDatesCheck } from '../../../../API/CheckApi/CheckCollectorApi';
import PieChart from '../../../../Components/PieChart';
import { _defineCollectorType } from '../../../../Utils';
import * as Constant from '../../../../Constants';
import { endOfDay } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { getAllStandardSalesDetailsByDates } from '../../../../API/SalesApi';
import { getContainersByDates } from '../../../../API/ContainerApi';
import { getSortersByDates } from '../../../../API/SorterApi';
import { getSortersByDatesCheck } from '../../../../API/CheckApi/CheckSorterApi';
import { getReturnByDates } from '../../../../API/CollectorReturnApi.js';
import { countOccurenceByKey } from '../../../../UtilsData.js';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
export default function IndusDataReuseDashboard() {
    const navigate = useNavigate();
    const [collectorByType, setCollectorByType] = useState([]);
    const [totalContainerInCirculation, setTotalContainerInCirculation] = useState(0);
    const [standardContainers, setStandardContainers] = useState([]);
    const [sorterActive, setsorterActive] = useState(0);
    const [washerActive, setwasherActive] = useState(0);
    const [allCollectorReturn, setAllCollectorReturn] = useState([]);
    const [tauxStandardReturned, setTauxStandardReturned] = useState(0);

    // // FILTRES

    useEffect(() => {
        const fetchData = async () => {
            const now = new Date();
            const startDate = new Date(now.getFullYear(), 0, 1);
            const endDate = now;
            const { gearApi } = await import('../../../../gearApi.js');

            setCollectorByType([]);
            setTotalContainerInCirculation(0);
            setStandardContainers([]);
            setsorterActive(0);
            setwasherActive(0);
            setAllCollectorReturn([]);
            setTauxStandardReturned(0);

            try {
                // COLLECTEURS
                const collectorsRaw = await getCollectorsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const collectorsRawCheck = getCollectorsByDatesCheck(collectorsRaw);
                if (collectorsRaw.length && collectorsRawCheck !== true) {
                    console.error(collectorsRawCheck);
                    Sentry.captureException(collectorsRawCheck);
                    navigate('/error');
                    return;
                }
                const countedType = countOccurenceByKey(collectorsRaw, 'collector_type');

                const collectorByTypeRaw = Object.keys(countedType).map((type) => ({
                    type: _defineCollectorType(type),
                    qty: ((countedType[type] / collectorsRaw.length) * 100).toFixed(1),
                }));
                setCollectorByType(collectorByTypeRaw);

                // STANDARD EXISTANTS
                const containerRaw = await getContainersByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const standardContainers = containerRaw.filter((container) => container.owner === Constant.CONTAINER_OWNER_STATUS_STANDARD);
                setStandardContainers(standardContainers);

                // CONTENANTS EN CIRCULATION
                const standardCirculationContainer = await getAllStandardSalesDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));

                const allSalesDetailsCheck = getAllStandardSalesDetailsByDatesCheck(standardCirculationContainer);
                if (standardCirculationContainer.length && allSalesDetailsCheck !== true) {
                    console.error(allSalesDetailsCheck);
                    Sentry.captureException(allSalesDetailsCheck);
                    navigate('/error');
                    return;
                }

                const totalQtyStandard = standardCirculationContainer.reduce((acc, item) => acc + item.qty, 0);
                setTotalContainerInCirculation(totalQtyStandard);

                // CENTRE DE TRI
                const sorterRaw = await getSortersByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const sorterRawCheck = getSortersByDatesCheck(sorterRaw);
                if (sorterRaw.length && sorterRawCheck !== true) {
                    console.error(sorterRawCheck);
                    Sentry.captureException(sorterRawCheck);
                    navigate('/error');
                    return;
                }

                const sorterActiveRaw = sorterRaw.filter((sorter) => sorter.status === 0);
                setsorterActive(sorterActiveRaw.length);

                // CENTRE DE LAVAGE
                const washerRaw = await gearApi.washer.getWashers();
                const washerActiveRaw = washerRaw.filter((washer) => washer.status === 0);
                setwasherActive(washerActiveRaw.length);

                // COLLECTORS RETURNS
                const allCollectorReturnRaw = await getReturnByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allCollectorReturnCheck = getReturnByDatesCheck(allCollectorReturnRaw);
                if (allCollectorReturnRaw.length && allCollectorReturnCheck !== true) {
                    console.error(allCollectorReturnCheck);
                    Sentry.captureException(allCollectorReturnCheck);
                    navigate('/error');
                    return;
                }
                const standardReturnedContainer = allCollectorReturnRaw.filter((item) => item.container_owner === Constant.CONTAINER_OWNER_STATUS_STANDARD);
                const tauxStandardReturned = ((standardReturnedContainer.length / totalQtyStandard) * 100).toFixed(1);
                setTauxStandardReturned(tauxStandardReturned);
                setAllCollectorReturn(allCollectorReturnRaw);
            } catch (error) {
                console.debug(error);
            }
        };
        fetchData();
    }, [navigate]);

    const collectorByTypeQuantities = collectorByType.map((item) => item.qty);
    const collectorByTypeLabels = collectorByType.map((item) => item.type);

    return (
        <main>
            <div className="data-container">
                <div className="header-container">
                    <div className="header-title">
                        <h1>DONNÉES RÉEMPLOI</h1>
                    </div>
                </div>

                <div style={{ height: '22rem', marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%' }}>
                    <div style={{ flex: 2, height: '22rem' }}>
                        <section
                            className="section-graph"
                            style={{ height: '22rem' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Répartiton des collecteurs par type (%) :
                            </div>
                            <div style={{ height: '90%' }}>
                                {collectorByType.length > 0 ? (
                                    <PieChart
                                        title={collectorByTypeLabels}
                                        quantities={collectorByTypeQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 3, display: 'flex', flexDirection: 'column', gap: '1%' }}>
                        <section
                            className="section-graph"
                            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <div
                                className="title"
                                style={{ fontWeight: 'bold', height: '1rem' }}>
                                Contenants
                            </div>
                            <div
                                style={{ height: '1rem' }}
                                className="title">
                                Nombre de contenants standards existants (SKU) :
                            </div>
                            <div
                                style={{ height: '1rem', fontSize: 25 }}
                                className="title">
                                {standardContainers.length}
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ flex: 1, display: 'flex', flexDirection: 'column', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ fontWeight: 'bold', height: '1rem' }}>
                                Produits :
                            </div>
                            <div
                                style={{ height: '1rem' }}
                                className="title">
                                Nombre total de contenants standards mis sur le marché :
                            </div>
                            <div
                                style={{ height: '1rem', fontSize: 25 }}
                                className="title">
                                {totalContainerInCirculation}
                            </div>
                        </section>
                    </div>
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%' }}>
                    <div style={{ flex: 2 }}>
                        <section
                            className="section-graph"
                            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <div
                                className="title"
                                style={{ fontWeight: 'bold', height: '1rem' }}>
                                Centres de tri
                            </div>
                            <div
                                style={{ height: '1rem' }}
                                className="title">
                                Nombre de centres de tri actifs: <span style={{ fontSize: 25 }}>{sorterActive}</span>
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <div
                                className="title"
                                style={{ fontWeight: 'bold', height: '1rem' }}>
                                Centres de lavage
                            </div>
                            <div
                                style={{ height: '1rem' }}
                                className="title">
                                Nombre de centres de lavage actifs: <span style={{ fontSize: 25 }}>{washerActive}</span>
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 3, display: 'flex', gap: '1%' }}>
                        <section
                            className="section-graph"
                            style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <div
                                    className="title"
                                    style={{ fontWeight: 'bold' }}>
                                    Retours :
                                </div>
                                <div
                                    style={{ height: '1rem' }}
                                    className="title">
                                    Nombre total de contenants collectés :
                                </div>
                                <div
                                    style={{ fontSize: 25 }}
                                    className="title">
                                    {allCollectorReturn.length}
                                </div>
                                <div className="title">
                                    <p>Taux de retour moyen des contenants standards : </p>
                                </div>
                                <div
                                    style={{ fontSize: 25, marginBottom: 0 }}
                                    className="title">
                                    {tauxStandardReturned ? tauxStandardReturned : 0}%
                                    <span style={{ fontSize: 12, paddingLeft: '0.5rem' }}>
                                        (nombre de contenants standards en collecteur / nombre total de contenants standards mises sur le marché)
                                    </span>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}
