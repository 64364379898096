import React, { useEffect, useState } from 'react';
import { endOfDay } from 'date-fns';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { getCollectorsByDates } from '../../../../API/CollectorApi';
import { getCollectorsByDatesCheck } from '../../../../API/CheckApi/CheckCollectorApi';
import { getAllStandardSalesDetailsByDates } from '../../../../API/SalesApi';
import { getAllStandardSalesDetailsByDatesCheck } from '../../../../API/CheckApi/CheckSalesApi';
import { getReturnByDates } from '../../../../API/CollectorReturnApi';
import { getReturnByDatesCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import { getContainersByDates } from '../../../../API/ContainerApi';
import { countOccurenceByKey } from '../../../../UtilsData';
import CircleChart from '../../../../Components/CircleChart';
import PieChart from '../../../../Components/PieChart';
import { _defineCollectorType, _defineStoreType } from '../../../../Utils';
import * as Constants from '../../../../Constants';

export default function DistribDataReuseDashboard() {
    const navigate = useNavigate();
    const [collectorActive, setCollectorActive] = useState(0);
    const [collectorByType, setCollectorByType] = useState([]);
    const [collectorByStoreType, setCollectorByStoreType] = useState([]);
    const [standardContainers, setStandardContainers] = useState([]);
    const [tauxStandardReturned, setTauxStandardReturned] = useState(0);
    const [allCollectorReturn, setAllCollectorReturn] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const now = new Date();
            const startDate = new Date(now.getFullYear(), 0, 1);
            const endDate = now;
            setCollectorActive(0);
            setCollectorByType([]);
            setCollectorByStoreType([]);
            setStandardContainers([]);
            setTauxStandardReturned(0);
            setAllCollectorReturn([]);

            try {
                const collectorsRaw = await getCollectorsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const collectorRawCheck = getCollectorsByDatesCheck(collectorsRaw);
                if (collectorsRaw.length && collectorRawCheck !== true) {
                    console.error(collectorRawCheck);
                    Sentry.captureException(collectorRawCheck);
                    navigate('/error');
                    return;
                }
                const collectorActiveRaw = collectorsRaw.filter((collector) => collector.collector_status === 0);
                setCollectorActive(collectorActiveRaw.length);

                const countedType = countOccurenceByKey(collectorActiveRaw, 'collector_type');
                const collectorByTypeRaw = Object.keys(countedType).map((type) => ({
                    type: _defineCollectorType(type),
                    qty: ((countedType[type] / collectorActiveRaw.length) * 100).toFixed(1),
                }));
                setCollectorByType(collectorByTypeRaw);

                const collectorByStoreTypeRaw = countOccurenceByKey(collectorActiveRaw, 'store_type');
                const collectorByStoreCounted = Object.keys(collectorByStoreTypeRaw).map((store_type) => ({
                    store_type: _defineStoreType(store_type),
                    qty: ((collectorByStoreTypeRaw[store_type] / collectorActiveRaw.length) * 100).toFixed(1),
                }));
                setCollectorByStoreType(collectorByStoreCounted);

                // STANDARD EXISTANTS
                const containerRaw = await getContainersByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const standardContainers = containerRaw.filter((container) => container.owner === Constants.CONTAINER_OWNER_STATUS_STANDARD);
                setStandardContainers(standardContainers);

                // CONTENANTS EN CIRCULATION
                const standardCirculationContainer = await getAllStandardSalesDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allSalesDetailsCheck = getAllStandardSalesDetailsByDatesCheck(standardCirculationContainer);
                if (standardCirculationContainer.length && allSalesDetailsCheck !== true) {
                    console.error(allSalesDetailsCheck);
                    Sentry.captureException(allSalesDetailsCheck);
                    navigate('/error');
                    return;
                }

                const totalQtyStandard = standardCirculationContainer.reduce((acc, item) => acc + item.qty, 0);
                // COLLECTORS RETURNS
                const allCollectorReturnRaw = await getReturnByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allCollectorReturnCheck = getReturnByDatesCheck(allCollectorReturnRaw);
                if (allCollectorReturnRaw.length && allCollectorReturnCheck !== true) {
                    console.error(allCollectorReturnCheck);
                    Sentry.captureException(allCollectorReturnCheck);
                    navigate('/error');
                    return;
                }
                const standardReturnedContainer = allCollectorReturnRaw.filter((item) => item.container_owner === Constants.CONTAINER_OWNER_STATUS_STANDARD);
                const tauxStandardReturned = ((standardReturnedContainer.length / totalQtyStandard) * 100).toFixed(1);

                setTauxStandardReturned(tauxStandardReturned);
                setAllCollectorReturn(allCollectorReturnRaw);
            } catch (error) {
                console.debug(error);
            }
        };
        fetchData();
    }, [navigate]);

    const collectorByTypeQuantities = collectorByType.map((item) => item.qty);
    const collectorByTypeLabels = collectorByType.map((item) => item.type);

    const collectorByStoreTypeQuantities = collectorByStoreType.map((item) => item.qty);
    const collectorByStoreTypeLabels = collectorByStoreType.map((item) => item.store_type);
    return (
        <main>
            <main>
                <div className="data-container">
                    <div className="header-container">
                        <div className="header-title">
                            <h1>DONNÉES RÉEMPLOI</h1>
                        </div>
                    </div>

                    <div style={{ display: 'flex', gap: '1%' }}>
                        <section
                            className="section-number"
                            style={{ width: '33%' }}>
                            <div className="title">Nombre de collecteurs actifs</div>
                            <div className="content">
                                <div className="number">{collectorActive}</div>

                                <div className="text"></div>
                            </div>
                        </section>
                        <section style={{ width: '50%' }}></section>
                    </div>
                    <div style={{ display: 'flex', gap: '1%' }}>
                        <section
                            className="section-graph"
                            style={{ width: '50%' }}>
                            <div className="title">Répartition des collecteurs par type (%) :</div>
                            <div style={{ height: '13rem' }}>
                                {collectorByType.length > 0 ? (
                                    <CircleChart
                                        title={collectorByTypeLabels}
                                        quantities={collectorByTypeQuantities}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ width: '50%' }}>
                            <div className="title">Répartition des collecteurs par type de magasin (%) :</div>
                            <div style={{ height: '13rem' }}>
                                {collectorByStoreType.length > 0 ? (
                                    <PieChart
                                        title={collectorByStoreTypeLabels}
                                        quantities={collectorByStoreTypeQuantities}
                                        position={'right'}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                    <div style={{ display: 'flex', gap: '1%' }}>
                        <section
                            className="section-number"
                            style={{ width: '33%' }}>
                            <div className="title">Nombre de contenants standards existants</div>
                            <div className="content">
                                <div className="number">{standardContainers.length}</div>
                            </div>
                        </section>

                        <section
                            className="section-number"
                            style={{ width: '33%' }}>
                            <div className="title">Nombre d’unités globales collectées</div>
                            <div className="content">
                                <div className="number">{allCollectorReturn.length}</div>
                                <div className="text"></div>
                            </div>
                        </section>
                        <section
                            className="section-number"
                            style={{ width: '33%' }}>
                            <div className="title">Taux de retour des contenants standards</div>
                            <div className="content">
                                <div className="number">{isNaN(tauxStandardReturned) ? 0 : tauxStandardReturned}%</div>
                                <div className="text"></div>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        </main>
    );
}
