import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Jwt } from '../../../../Jwt';
import PieChart from '../../../../Components/PieChart';
import BarChart from '../../../../Components/BarChart';
import BarChartMulti from '../../../../Components/BarChartMulti';
import CircleChart from '../../../../Components/CircleChart';
import DateFilter from '../../../../Components/DateFilter';
import MultiLineChart from '../../../../Components/MultiLineChart';
import { getAllStandardSalesDetailsByDates, getAllSalesDetailsByDatesAndIndus } from '../../../../API/SalesApi';
import { getAllStandardSalesDetailsByDatesCheck, getAllSalesDetailsByDatesAndIndusCheck } from '../../../../API/CheckApi/CheckSalesApi';
import { getReturnByDatesCheck, getReturnByDatesAndIndusCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import { endOfDay, format } from 'date-fns';
import { getReturnByDates, getReturnByDatesAndIndus } from '../../../../API/CollectorReturnApi';
import * as Constant from '../../../../Constants';
import { _defineCollectorType, _defineContainerType, _defineProductCat } from '../../../../Utils';
import { reduceDataByKeyAndQty, reduceByMonthAndKey, addMonthToTab, transformDataEmpliee } from '../../../../UtilsData';
import { CSVLink } from 'react-csv';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
export default function IndusDataReuseReturns() {
    const navigate = useNavigate();
    const now = new Date();
    const userContext = new Jwt();
    const [originalAllCollectorReturns, setOriginalAllCollectorReturns] = useState([]);
    const [originalMyProductCollectorReturn, setOriginalMyProductCollectorReturn] = useState([]);
    const [allCollectorReturn, setAllCollectorReturn] = useState([]);
    const [evolutionByMonthAndSku, setEvolutionByMonthAndSku] = useState([]);
    const [productReturnedBySku, setProductReturnedBySku] = useState([]);
    const [productReturnedByCollectorType, setProductReturnedByCollectorType] = useState([]);
    const [productReturnedBySkuType, setProductReturnedBySkuType] = useState([]);
    const [tauxStandardReturned, setTauxStandardReturned] = useState(0);
    const [tauxStandardReturnedBySku, setTauxStandardReturnedBySku] = useState([]);
    const [myProductCollectorReturn, setMyProductCollectorReturn] = useState([]);
    const [myProductCollectorReturnByEan, setMyProductCollectorReturnByEan] = useState([]);
    const [myProductCollectorReturnByProductCat, setMyProductCollectorReturnByProductCat] = useState([]);
    const [myProductCollectorReturnByCollectorType, setMyProductCollectorReturnByCollectorType] = useState([]);
    const [myProductEvolutionByMonthAndEan, setMyProductEvolutionByMonthAndEan] = useState([]);
    const [myProductCumulateVolume, setMyProductCumulateVolume] = useState([]);
    const [myProductReturnedTaux, setMyProductReturnedTaux] = useState(0);
    const [myProductReturnedTauxByEan, setMyProductReturnedTauxByEan] = useState([]);
    const [myProductReturnedTauxByCollectorType, setMyProductReturnedTauxByCollectorType] = useState([]);
    const [myProductCumulateTaux, setMyProductCumulateTaux] = useState([]);
    const [formattedTabGlobalData, setFormattedTabGlobalData] = useState([]);
    const [formattedTabMyRefsData, setFormattedTabMyRefsData] = useState([]);

    // FILTRE
    const [startDate, setStartDate] = useState(new Date(now.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);
    const [skuSelected, setSkuSelected] = useState('');
    const [eanSelected, setEanSelected] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setAllCollectorReturn([]);
            setEvolutionByMonthAndSku([]);
            setProductReturnedBySku([]);
            setProductReturnedByCollectorType([]);
            setProductReturnedBySkuType([]);
            setTauxStandardReturned(0);
            setTauxStandardReturnedBySku([]);
            setMyProductCollectorReturn([]);
            setMyProductCollectorReturnByCollectorType([]);
            setMyProductCollectorReturnByEan([]);
            setMyProductCollectorReturnByProductCat([]);
            setMyProductEvolutionByMonthAndEan([]);
            setMyProductCumulateVolume([]);
            setMyProductReturnedTaux(0);
            setMyProductReturnedTauxByCollectorType([]);
            setMyProductReturnedTauxByEan([]);
            setMyProductCumulateTaux([]);
            setFormattedTabGlobalData([]);
            setFormattedTabMyRefsData([]);
            setOriginalAllCollectorReturns([]);
            setOriginalMyProductCollectorReturn([]);

            try {
                // NB UNITES EN COLLECTEURS
                const allCollectorReturnRaw = await getReturnByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));

                const allCollectorReturnRawCheck = getReturnByDatesCheck(allCollectorReturnRaw);
                if (allCollectorReturnRaw.length && allCollectorReturnRawCheck !== true) {
                    console.error(allCollectorReturnRawCheck);
                    Sentry.captureMessage(allCollectorReturnRawCheck);
                    navigate('/error');
                    return;
                }

                setOriginalAllCollectorReturns(allCollectorReturnRaw);

                let allCollectorReturnFiltered = [];
                if (skuSelected) {
                    allCollectorReturnFiltered = allCollectorReturnRaw.filter((container) => container.container_sku === skuSelected);
                } else {
                    allCollectorReturnFiltered = allCollectorReturnRaw;
                }

                setAllCollectorReturn(allCollectorReturnFiltered);

                // EVOLUTION DU NOMBRE DE RETOURS PAR MOIS ET PAR SKU

                const formattedData = reduceByMonthAndKey(allCollectorReturnFiltered, 'returnedAt', 'container_sku', 'qty', 'sku');

                const formattedChart = addMonthToTab(formattedData, startDate.toISOString(), endOfDay(endDate.toISOString()), 'sku', 'month', 'quantity');
                setEvolutionByMonthAndSku(formattedChart);

                // NB PRODUITS RETOURNES PAR SKU
                const countedProductReturnedBySkuRaw = reduceDataByKeyAndQty(allCollectorReturnFiltered, 'container_sku');

                const productReturnedBySkuRaw = Object.keys(countedProductReturnedBySkuRaw).map((sku) => ({
                    sku: sku,
                    qty: ((countedProductReturnedBySkuRaw[sku] / allCollectorReturnRaw.length) * 100).toFixed(1),
                }));
                setProductReturnedBySku(productReturnedBySkuRaw);

                // NB PRODUITS RETOURNES PAR TYPE DE SKU
                const countedProductReturnedBySkuTypeRaw = reduceDataByKeyAndQty(allCollectorReturnFiltered, 'container_type');
                const productReturnedBySkuTypeRaw = Object.keys(countedProductReturnedBySkuTypeRaw).map((type) => ({
                    type: _defineContainerType(type),
                    qty: ((countedProductReturnedBySkuTypeRaw[type] / allCollectorReturnRaw.length) * 100).toFixed(1),
                }));
                setProductReturnedBySkuType(productReturnedBySkuTypeRaw);

                // COMPARAISON VOLUME TCE VS RVM
                const countedProductReturnedByCollectorType = reduceDataByKeyAndQty(allCollectorReturnFiltered, 'collector_type');
                const productReturnedByCollectorTypeRaw = Object.keys(countedProductReturnedByCollectorType).map((type) => ({
                    type: _defineCollectorType(type),
                    qty: ((countedProductReturnedByCollectorType[type] / allCollectorReturnRaw.length) * 100).toFixed(1),
                }));
                setProductReturnedByCollectorType(productReturnedByCollectorTypeRaw);

                // TX RETOUR DES CONTENANTS STANDARD
                const standardContainersProducted = await getAllStandardSalesDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const allSalesDetailsRawCheck = getAllStandardSalesDetailsByDatesCheck(standardContainersProducted);
                if (standardContainersProducted.length && allSalesDetailsRawCheck !== true) {
                    console.error(allSalesDetailsRawCheck);
                    Sentry.captureMessage(allSalesDetailsRawCheck);
                    navigate('/error');
                    return;
                }

                let allSalesDetailsFiltered = [];
                if (skuSelected) {
                    allSalesDetailsFiltered = standardContainersProducted.filter((container) => container.container_sku === skuSelected);
                } else {
                    allSalesDetailsFiltered = standardContainersProducted;
                }

                const totalQtyProducted = allSalesDetailsFiltered.reduce((accumulator, item) => accumulator + item.qty, 0);

                // STANDARDS RETOURNES

                const standardContainersReturned = allCollectorReturnFiltered.filter(
                    (container) => container.container_owner === Constant.CONTAINER_OWNER_STATUS_STANDARD,
                );
                const totalQtyReturned = standardContainersReturned.reduce((accumulator, item) => accumulator + item.qty, 0);

                const tauxStandardReturnedRaw = (totalQtyReturned / totalQtyProducted) * 100;

                setTauxStandardReturned(tauxStandardReturnedRaw);

                // TAUX RETOUR STANDARD PAR SKU
                const countedStandardContainersProductedBySku = reduceDataByKeyAndQty(standardContainersProducted, 'container_sku');
                const countedStandardContainersReturnedBySku = reduceDataByKeyAndQty(standardContainersReturned, 'container_sku');
                const tauxStandardReturnedBySku = Object.keys(countedStandardContainersProductedBySku).map((sku) => {
                    const qtyProducted = countedStandardContainersProductedBySku[sku];
                    const qtyReturned = countedStandardContainersReturnedBySku[sku] ? countedStandardContainersReturnedBySku[sku] : 0;
                    return {
                        sku: sku,
                        taux: ((qtyReturned / qtyProducted) * 100).toFixed(1),
                    };
                });
                setTauxStandardReturnedBySku(tauxStandardReturnedBySku);

                // NB UNITES EN COLLECTEURS DE MES REFERENCES
                const myProductCollectorReturnRaw = await getReturnByDatesAndIndus(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const myReturnsCheck = getReturnByDatesAndIndusCheck(myProductCollectorReturnRaw);
                if (myProductCollectorReturnRaw.length && myReturnsCheck !== true) {
                    console.error(myReturnsCheck);
                    Sentry.captureException(myReturnsCheck);
                    navigate('/error');
                    return;
                }

                setOriginalMyProductCollectorReturn(myProductCollectorReturnRaw);

                let myProductCollectorReturnFiltered = [];
                if (eanSelected) {
                    myProductCollectorReturnFiltered = myProductCollectorReturnRaw.filter((container) => container.product_ean === eanSelected);
                } else {
                    myProductCollectorReturnFiltered = myProductCollectorReturnRaw;
                }
                setMyProductCollectorReturn(myProductCollectorReturnFiltered);

                // NB PRODUITS RETOURNES PAR EAN
                const countedMyProductReturnedByEan = reduceDataByKeyAndQty(myProductCollectorReturnFiltered, 'product_name');

                const myProductReturnedByEanRaw = Object.keys(countedMyProductReturnedByEan).map((name) => ({
                    name: name,
                    qty: ((countedMyProductReturnedByEan[name] / myProductCollectorReturnRaw.length) * 100).toFixed(1),
                }));
                setMyProductCollectorReturnByEan(myProductReturnedByEanRaw);

                // NB PRODUITS RETOURNES PAR TYPE DE PRODUIT
                const countedMyProductReturnedByProductCat = reduceDataByKeyAndQty(myProductCollectorReturnFiltered, 'product_cat');
                const myProductReturnedByProductCatRaw = Object.keys(countedMyProductReturnedByProductCat).map((cat) => ({
                    cat: _defineProductCat(cat),
                    qty: ((countedMyProductReturnedByProductCat[cat] / myProductCollectorReturnRaw.length) * 100).toFixed(1),
                }));
                setMyProductCollectorReturnByProductCat(myProductReturnedByProductCatRaw);

                // COMPARAISON VOLUME TCE VS RVM DE MES REFERENCES
                const countedMyProductReturnedByCollectorType = reduceDataByKeyAndQty(myProductCollectorReturnFiltered, 'collector_type');
                const myProductReturnedByCollectorTypeRaw = Object.keys(countedMyProductReturnedByCollectorType).map((type) => ({
                    type: _defineCollectorType(type),
                    qty: ((countedMyProductReturnedByCollectorType[type] / myProductCollectorReturnRaw.length) * 100).toFixed(1),
                }));
                setMyProductCollectorReturnByCollectorType(myProductReturnedByCollectorTypeRaw);

                // EVOLUTION DU NOMBRE DE RETOURS PAR MOIS ET PAR SKU
                const myProductReturnedformattedData = reduceByMonthAndKey(myProductCollectorReturnFiltered, 'returnedAt', 'product_name', 'qty', 'name');
                const myProductFormattedChart = addMonthToTab(
                    myProductReturnedformattedData,
                    startDate.toISOString(),
                    endOfDay(endDate.toISOString()),
                    'name',
                    'month',
                    'quantity',
                );
                setMyProductEvolutionByMonthAndEan(myProductFormattedChart);

                // VOLUME CUMULES EN EAN ET RVM PAR EAN
                const myProductReturnedCumulateVolumeRaw = transformDataEmpliee(
                    myProductCollectorReturnFiltered,
                    'product_ean',
                    'collector_type',
                    'qty',
                    _defineCollectorType,
                    false,
                    null,
                );
                setMyProductCumulateVolume(myProductReturnedCumulateVolumeRaw);

                // TAUX DE RETOUR GLOBAL DE MES REFERENCES
                const myProductProducted = await getAllSalesDetailsByDatesAndIndus(startDate.toISOString(), endOfDay(endDate.toISOString()));
                const myProductProductedCheck = getAllSalesDetailsByDatesAndIndusCheck(myProductProducted);
                if (myProductProducted.length && myProductProductedCheck !== true) {
                    console.error(myProductProductedCheck);
                    Sentry.captureMessage(myProductProductedCheck);
                    navigate('/error');
                    return;
                }
                let myProductProductedFiltered = [];
                if (eanSelected) {
                    myProductProductedFiltered = myProductProducted.filter((container) => container.product_ean === eanSelected);
                } else {
                    myProductProductedFiltered = myProductProducted;
                }

                const totalMyProductProducted = myProductProductedFiltered.reduce((accumulator, item) => accumulator + item.qty, 0);
                const tauxGlobalMyProductReturnedRaw = (myProductCollectorReturnFiltered.length / totalMyProductProducted) * 100;
                setMyProductReturnedTaux(tauxGlobalMyProductReturnedRaw);
                // TAUX DE RETOUR DE MES REFERENCES ENTRE TCE ET RVM
                const myProductReturnedTauxByCollectorTypeRaw = Object.keys(countedMyProductReturnedByCollectorType).map((type) => ({
                    type: _defineCollectorType(type),
                    taux: (countedMyProductReturnedByCollectorType[type] / totalMyProductProducted) * 100,
                }));
                const sommeTaux = myProductReturnedTauxByCollectorTypeRaw.reduce((acc, item) => acc + item.taux, 0);
                const myProductReturnedTauxByCollectorTypeFinal = myProductReturnedTauxByCollectorTypeRaw.map((item) => {
                    return {
                        type: item.type,
                        qty: ((item.taux / sommeTaux) * 100).toFixed(1),
                    };
                });

                setMyProductReturnedTauxByCollectorType(myProductReturnedTauxByCollectorTypeFinal);

                // CUMULE TAUX DE RETOUR DE MES REFERENCES ENTRE TCE ET RVM
                const myProductReturnedCumulateTauxRaw = transformDataEmpliee(
                    myProductCollectorReturnFiltered,
                    'product_ean',
                    'collector_type',
                    'qty',
                    _defineCollectorType,
                    true,
                    null,
                );

                setMyProductCumulateTaux(myProductReturnedCumulateTauxRaw);

                // EXTRACT GLOBAL MARCHE
                const formattedTabGlobalDataRaw = allCollectorReturnFiltered.map((item) => {
                    return {
                        date: format(item.returnedAt, 'dd/MM/yyyy HH:mm:ss'),
                        year: new Date(item.returnedAt).getFullYear(),
                        month: new Date(item.returnedAt).toLocaleString('default', { month: 'long' }),
                        sku: item.container_sku !== 'Autres' ? item.container_sku : 'N/D',
                        skuType: item.container_type ? _defineContainerType(item.container_type) : 'N/D',
                        collectorType: _defineCollectorType(item.collector_type),
                    };
                });
                setFormattedTabGlobalData(formattedTabGlobalDataRaw);

                // EXTRACT MES REFERENCES
                const formattedDataRaw = myProductCollectorReturnFiltered.map((item) => {
                    return {
                        date: format(item.returnedAt, 'dd/MM/yyyy HH:mm:ss'),
                        year: new Date(item.returnedAt).getFullYear(),
                        month: new Date(item.returnedAt).toLocaleString('default', { month: 'long' }),
                        ean: item.product_ean,
                        name: item.product_name,
                        cat: _defineProductCat(item.product_cat),
                        sku: item.container_sku,
                        collectorType: _defineCollectorType(item.collector_type),
                    };
                });
                setFormattedTabMyRefsData(formattedDataRaw);

                // EVOLUTION DU TAUX DE RETOUR PAR EAN AVEC 1 COURBE PAR EAN SUR LES MOIS DE L'ANNEE EN COURS
                const countedMyProductProductedByEan = reduceDataByKeyAndQty(myProductProductedFiltered, 'ean');
                const myProductProductedByEanRaw = Object.keys(countedMyProductProductedByEan).map((ean) => ({
                    ean: ean,
                    qty: countedMyProductProductedByEan[ean],
                }));
                const myReturnTauxChart = reduceByMonthAndKey(myProductCollectorReturnFiltered, 'returnedAt', 'product_ean', 'qty', 'ean');
                for (let i = 0; i < myReturnTauxChart.length; i++) {
                    for (let j = 0; j < myProductProductedByEanRaw.length; j++) {
                        if (myReturnTauxChart[i].ean === myProductProductedByEanRaw[j].ean) {
                            myReturnTauxChart[i].producted = myProductProductedByEanRaw[j].qty;

                            myReturnTauxChart[i].quantity = ((myReturnTauxChart[i].quantity / myProductProductedByEanRaw[j].qty) * 100).toFixed(1);
                        }
                    }
                }

                const myReturnTauxFormattedChartRaw = addMonthToTab(
                    myReturnTauxChart,
                    startDate.toISOString(),
                    endOfDay(endDate.toISOString()),
                    'ean',
                    'month',
                    'quantity',
                );
                setMyProductReturnedTauxByEan(myReturnTauxFormattedChartRaw);
            } catch (error) {
                console.debug(error);
            }
        };
        if (startDate && endDate) fetchData();
    }, [startDate, endDate, userContext.entityId, skuSelected, eanSelected, navigate]);

    const productReturnedBySkuLabels = productReturnedBySku.map((item) => item.sku);
    const productReturnedBySkuQuantities = productReturnedBySku.map((item) => item.qty);

    const productReturnedByCollectorTypeLabels = productReturnedByCollectorType.map((item) => item.type);
    const productReturnedByCollectorTypeQuantities = productReturnedByCollectorType.map((item) => item.qty);

    const productReturnedBySkuTypeLabels = productReturnedBySkuType.map((item) => item.type);
    const productReturnedBySkuTypeQuantities = productReturnedBySkuType.map((item) => item.qty);

    const tauxStandardReturnedBySkuLabels = tauxStandardReturnedBySku.map((item) => item.sku);
    const tauxStandardReturnedBySkuQuantities = tauxStandardReturnedBySku.map((item) => item.taux);

    const myProductCollectorReturnByEanLabels = myProductCollectorReturnByEan.map((item) => item.name);
    const myProductCollectorReturnByEanQuantities = myProductCollectorReturnByEan.map((item) => item.qty);

    const myProductCollectorReturnByProductCatLabels = myProductCollectorReturnByProductCat.map((item) => item.cat);
    const myProductCollectorReturnByProductCatQuantities = myProductCollectorReturnByProductCat.map((item) => item.qty);

    const myProductCollectorReturnByCollectorTypeLabels = myProductCollectorReturnByCollectorType.map((item) => item.type);
    const myProductCollectorReturnByCollectorTypeQuantities = myProductCollectorReturnByCollectorType.map((item) => item.qty);

    const evolutionBySkus = [...new Set(evolutionByMonthAndSku.map((fd) => fd.sku))];
    const EvolutionBySkusQuantites = evolutionBySkus.map((sku) => {
        const data = evolutionByMonthAndSku.filter((fd) => fd.sku === sku).map((fd) => fd.quantity);
        return {
            label: sku,
            data,
        };
    });
    const myProductEvolutionByEan = [...new Set(myProductEvolutionByMonthAndEan.map((fd) => fd.name))];

    const myProductEvolutionByEanQuantites = myProductEvolutionByEan.map((name) => {
        const data = myProductEvolutionByMonthAndEan.filter((fd) => fd.name === name).map((fd) => fd.quantity);
        return {
            label: name,
            data,
        };
    });

    const myProductReturnedEvolutionByEan = [...new Set(myProductReturnedTauxByEan.map((fd) => fd.ean))];

    const myProductReturnedEvolutionByEanQuantities = myProductReturnedEvolutionByEan.map((ean) => {
        const data = myProductReturnedTauxByEan.filter((fd) => fd.ean === ean).map((fd) => fd.quantity);
        return {
            label: ean,
            data,
        };
    });

    const tauxMyProductReturnedByCollectorTypeLabels = myProductReturnedTauxByCollectorType.map((item) => item.type);
    const tauxMyProductReturnedByCollectorTypeQuantities = myProductReturnedTauxByCollectorType.map((item) => item.qty);

    const customHeaderGlobal = [
        {
            label: 'Date',
            key: 'date',
        },
        {
            label: 'Année',
            key: 'year',
        },
        {
            label: 'Mois',
            key: 'month',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Type de SKU',
            key: 'skuType',
        },
        {
            label: 'Type de collecteur',
            key: 'collectorType',
        },
    ];

    const customHeaderMyRefs = [
        {
            label: 'Date',
            key: 'date',
        },
        {
            label: 'Année',
            key: 'year',
        },
        {
            label: 'Mois',
            key: 'month',
        },
        {
            label: 'EAN',
            key: 'ean',
        },
        {
            label: 'Nom',
            key: 'name',
        },
        {
            label: 'Catégorie',
            key: 'cat',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Type de collecteur',
            key: 'collectorType',
        },
    ];
    const mySkus = [...new Set(originalAllCollectorReturns.map((fd) => fd.container_sku))];
    const myFilteredSkus = mySkus.filter((sku) => sku !== 'Autres' && sku !== 'N/D');

    const myEans = [...new Set(originalMyProductCollectorReturn.map((fd) => fd.product_ean))];

    const resetGlobalFilter = () => {
        setSkuSelected('');
    };

    const resetMyRefsFilter = () => {
        setEanSelected('');
    };

    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>RETOURS</h1>
                    <div className="date-container">
                        <DateFilter
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                </div>
                <div className="header-container">
                    <div className="header-title">
                        <h1>Global marché</h1>
                    </div>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setSkuSelected(e.target.value)}
                                value={skuSelected}>
                                <option value="">SKU</option>

                                {myFilteredSkus.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetGlobalFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>
                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderGlobal}
                                    data={formattedTabGlobalData ? formattedTabGlobalData : []}
                                    filename={`Les_retours.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire la donnée globale
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%', height: '25rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <section className="section-graph">
                            <div className="title">
                                Nombre total de contenants retournés :
                                <span style={{ fontSize: 25 }}>{allCollectorReturn.length ? allCollectorReturn.length : 0}</span>{' '}
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Evolution du nombre de contenants retournés par SKU :
                            </div>
                            <div style={{ height: '90%' }}>
                                {evolutionByMonthAndSku ? (
                                    <MultiLineChart
                                        initialData={evolutionByMonthAndSku}
                                        datasets={EvolutionBySkusQuantites}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <section className="section-graph">
                            <div className="title">
                                Taux de retour moyen des contenants standards :{' '}
                                <span style={{ fontSize: 25 }}>{Number.isFinite(tauxStandardReturned) ? tauxStandardReturned.toFixed(1) : 0}%</span>{' '}
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Taux de retour des contenants standards par SKU (%) :
                            </div>
                            <div style={{ height: '90%' }}>
                                {tauxStandardReturnedBySku.length > 0 ? (
                                    <BarChart
                                        title={tauxStandardReturnedBySkuLabels}
                                        quantities={tauxStandardReturnedBySkuQuantities}
                                        label={'Taux de retour'}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>{' '}
                    </div>
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%', height: '25rem' }}>
                    <section
                        className="section-graph"
                        style={{ flex: 1, marginBottom: 0 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Répartition des contenants retournés par SKU (%) :{' '}
                        </div>
                        <div style={{ height: '90%' }}>
                            {productReturnedBySku.length > 0 ? (
                                <PieChart
                                    title={productReturnedBySkuLabels}
                                    quantities={productReturnedBySkuQuantities}
                                    position={'top'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ flex: 1, marginBottom: 0 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Répartition des contenants retournés <br />
                            par typologie (%) :{' '}
                        </div>
                        <div style={{ height: '90%' }}>
                            {productReturnedBySkuType.length > 0 ? (
                                <PieChart
                                    title={productReturnedBySkuTypeLabels}
                                    quantities={productReturnedBySkuTypeQuantities}
                                    position={'top'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ flex: 1, marginBottom: 0 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Répartition des contenants retournés par type de collecteur (%) :{' '}
                        </div>
                        <div style={{ height: '90%' }}>
                            {productReturnedByCollectorType.length > 0 ? (
                                <PieChart
                                    title={productReturnedByCollectorTypeLabels}
                                    quantities={productReturnedByCollectorTypeQuantities}
                                    position={'top'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div className="header-container">
                    <h1>Mes références</h1>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setEanSelected(e.target.value)}
                                value={eanSelected}>
                                <option value="">EAN</option>

                                {myEans.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetMyRefsFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>

                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderMyRefs}
                                    data={formattedTabMyRefsData ? formattedTabMyRefsData : []}
                                    filename={`Mes_retours.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire ma donnée
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%', height: '30rem' }}>
                    <div style={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
                        <section className="section-graph">
                            <div
                                className="title"
                                style={{ display: 'flex', alignItems: 'center' }}>
                                Nombre total de mes contenants retournés :
                                <span style={{ fontSize: 25 }}>{myProductCollectorReturn ? myProductCollectorReturn.length : 0}</span>
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Evolution du nombre de mes contenants retournés par produit :{' '}
                            </div>
                            <div style={{ height: '90%' }}>
                                {myProductEvolutionByMonthAndEan ? (
                                    <MultiLineChart
                                        initialData={myProductEvolutionByMonthAndEan}
                                        datasets={myProductEvolutionByEanQuantites}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <section
                            className="section-graph"
                            style={{ flex: 1 }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Répartition de mes contenants retournés par produit (%) :{' '}
                            </div>
                            <div style={{ height: '80%' }}>
                                {myProductCollectorReturnByEan.length > 0 ? (
                                    <PieChart
                                        title={myProductCollectorReturnByEanLabels}
                                        quantities={myProductCollectorReturnByEanQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ flex: 1, marginBottom: 0 }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Répartition de mes contenants retournés par catégorie de produit (%) :
                            </div>
                            <div style={{ height: '80%' }}>
                                {myProductCollectorReturnByProductCat.length > 0 ? (
                                    <PieChart
                                        title={myProductCollectorReturnByProductCatLabels}
                                        quantities={myProductCollectorReturnByProductCatQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', gap: '1%', height: '25rem' }}>
                    <section
                        className="section-graph"
                        style={{ flex: 2, marginBottom: 0 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Répartition de mes contenants retournés par type de collecteur (%) :
                        </div>
                        <div style={{ height: '90%' }}>
                            {productReturnedByCollectorType.length > 0 ? (
                                <PieChart
                                    title={myProductCollectorReturnByCollectorTypeLabels}
                                    quantities={myProductCollectorReturnByCollectorTypeQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ flex: 3, marginBottom: 0 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Nombre de mes contenants retournés par produit et par type de collecteur :{' '}
                        </div>
                        <div style={{ height: '90%' }}>
                            {myProductCumulateVolume ? (
                                <BarChartMulti formattedData={myProductCumulateVolume} />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ width: '100%', marginBottom: '2rem' }}>
                    <section className="section-graph">
                        <div className="title">Mon taux de retour global : {myProductReturnedTaux ? myProductReturnedTaux.toFixed(1) : 0}% </div>
                    </section>
                </div>
                <div style={{ height: '25rem', marginBottom: '2rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', marginBottom: 0 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Evolution du taux de retour de mes produits (%) :{' '}
                        </div>
                        <div style={{ height: '100%' }}>
                            {myProductReturnedTauxByEan ? (
                                <MultiLineChart
                                    initialData={myProductReturnedTauxByEan}
                                    datasets={myProductReturnedEvolutionByEanQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div style={{ height: '25rem', display: 'flex', gap: '1%', marginBottom: '2rem' }}>
                    <section
                        className="section-graph"
                        style={{ flex: 2 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Répartition de mon taux de retour moyen par type <br /> de collecteur (%) :
                        </div>
                        <div style={{ height: '80%' }}>
                            {myProductReturnedTauxByCollectorType ? (
                                <CircleChart
                                    title={tauxMyProductReturnedByCollectorTypeLabels}
                                    quantities={tauxMyProductReturnedByCollectorTypeQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ flex: 3 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Taux de retour de mes produits par type de collecteur (%) :
                        </div>
                        <div style={{ height: '90%' }}>
                            {myProductCumulateTaux ? (
                                <BarChartMulti formattedData={myProductCumulateTaux} />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
