// WASHER DECLARATION API
import { isDataValid } from '../../Utils';
export const getWasherDeclarationDetailsLastStatusByEntityIdCheck = (data) => {
    const name = 'getWasherDeclarationDetailsLastStatusByEntityId : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].washerDeclaration.id) {
            return name + 'No washer Declaration id found';
        } else if (!data[i].washerDeclaration.entity.id) {
            return name + 'No entity id found';
        } else if (data[i].orderDetailId) {
            if (!data[i].orderDetail.order.id) {
                return name + 'No order id found';
            } else if (!data[i].orderDetail.order.entity.id) {
                return name + 'No entity id found';
            } else if (!data[i].suivi.id) {
                return name + 'No suivi id found';
            } else if (!data[i].suivi.washerEvents[0].status) {
                return name + 'No status found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getWasherDeclarationDetailsAttributedByEntityIdCheck = (data) => {
    const name = 'getWasherDeclarationDetailsAttributedByEntityId : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].orderDetail.id) {
            return name + 'No order details id found';
        } else if (!data[i].orderDetail.entityAddress.id) {
            return name + 'No entity address id found';
        } else if (!data[i].orderDetail.order.id) {
            return name + 'No order id found';
        } else if (!data[i].suivi.id) {
            return name + 'No suivi id found';
        } else if (!data[i].suivi.washerEvents[0].status) {
            return name + 'No status found';
        } else if (!data[i].washerDeclaration.id) {
            return name + 'No washer declaration id found';
        } else if (!data[i].washerDeclaration.entity.id) {
            return name + 'No entity id found';
        } else {
            return true;
        }
    }
};

export const getWasherDeclarationDetailsByOrderDetailIdCheck = (data) => {
    const name = 'getWasherDeclarationDetailsByOrderDetailId : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].washerDeclaration.id) {
            return name + 'No washer declaration id found';
        } else if (!data[i].washerDeclaration.entity.id) {
            return name + 'No entity id found';
        } else {
            return true;
        }
    }
};

export const getWasherDeclarationDetailsByOrderDetailIdDataCheck = (data) => {
    const name = 'getWasherDeclarationDetailsByOrderDetailIdData : ';
    for (let i = 0; i < data.length; i++) {
        if (!isDataValid(data[i].sku)) {
            return name + 'No sku found';
        } else if (!isDataValid(data[i].qty)) {
            return name + 'No quantity found';
        } else {
            return true;
        }
    }
};

export const getDetailsLastWasherEventStatusCheck = (data) => {
    const name = 'getDetailsLastWasherEventStatus : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].container.id) {
            return name + 'No container id found';
        } else if (data[i].orderDetailId) {
            if (!data[i].suivi.id) {
                return name + 'No suivi id found';
            } else if (!data[i].suivi.washerEvents[0].status) {
                return name + 'No status found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getWasherDeclarationNoAssignedBySkuCheck = (data) => {
    const name = 'getWasherDeclarationNoAssignedBySku : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (!data[i].washerDeclaration.id) {
            return name + 'No washer declaration id found';
        } else if (!data[i].washerDeclaration.entity.id) {
            return name + 'No entity id found';
        } else if (!data[i].container.id) {
            return name + 'No container id found';
        } else {
            return true;
        }
    }
};

export const getStandardWasherDeclarationDetailsByDatesCheck = (data) => {
    const name = 'getStandardWasherDeclarationDetailsByDates : ';
    for (let i = 0; i < data.length; i++) {
        if (!isDataValid(data[i].createdAt)) {
            return name + 'No date found';
        } else if (!isDataValid(data[i].qty)) {
            return name + 'No quantity found';
        } else if (!isDataValid(data[i].sku)) {
            return name + 'No sku found';
        } else if (!isDataValid(data[i].container_palet_nbcontainer)) {
            return name + 'No palet nb container found';
        } else if (!isDataValid(data[i].container_owner)) {
            return name + 'No container owner found';
        } else {
            return true;
        }
    }
};
