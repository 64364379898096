import { useEffect, useState } from 'react';
import { getReturnByDates, getReturnByDatesAndIndus } from '../../../../API/CollectorReturnApi';
import { getReturnByDatesCheck, getReturnByDatesAndIndusCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import CircleChart from '../../../../Components/CircleChart';
import PieChart from '../../../../Components/PieChart';
import DateFilter from '../../../../Components/DateFilter';
import { CSVLink } from 'react-csv';
import { Jwt } from '../../../../Jwt';
import { endOfDay } from 'date-fns';
import { _defineCollectorType, _defineProductCat, _defineStoreType, _defineStoreOwner, getRegionsName } from '../../../../Utils.js';
import * as Constant from '../../../../Constants';
import { reduceDataByKeyAndQty } from '../../../../UtilsData.js';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
export default function IndusDataConsumerCollecteurs() {
    const navigate = useNavigate();
    const now = new Date();
    const userContext = new Jwt();
    const [originalAllReturns, setOriginalAllReturns] = useState([]);
    const [originalMyReturns, setOriginalMyReturns] = useState([]);
    const [returnInTce, setReturnInTce] = useState([]);
    const [returnInRvm, setReturnInRvm] = useState([]);
    const [returnsByStoreOwner, setReturnsByStoreOwner] = useState([]);
    const [returnsByStoreType, setReturnsByStoreType] = useState([]);
    const [totalInTce, setTotalInTce] = useState([]);
    const [totalInRvm, setTotalInRvm] = useState([]);
    const [myReturnInTce, setMyReturnInTce] = useState([]);
    const [myReturnInRvm, setMyReturnInRvm] = useState([]);
    const [myReturnsByStoreOwner, setMyReturnsByStoreOwner] = useState([]);
    const [myReturnsByStoreType, setMyReturnsByStoreType] = useState([]);
    const [formattedTabGlobalData, setFormattedTabGlobalData] = useState([]);
    const [formattedTabMyRefsData, setFormattedTabMyRefsData] = useState([]);

    // FILTRES
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);
    const [skuSelected, setSkuSelected] = useState('');
    const [eanSelected, setEanSelected] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setReturnInTce([]);
            setReturnInRvm([]);
            setReturnsByStoreOwner([]);
            setReturnsByStoreType([]);
            setMyReturnInTce([]);
            setMyReturnInRvm([]);
            setMyReturnsByStoreOwner([]);
            setMyReturnsByStoreType([]);
            setFormattedTabGlobalData([]);
            setFormattedTabMyRefsData([]);
            setOriginalAllReturns([]);
            setOriginalMyReturns([]);

            // ALL RETURNS
            const allReturns = await getReturnByDates(startDate, endOfDay(endDate));
            const allReturnsCheck = getReturnByDatesCheck(allReturns);
            if (allReturns.length && allReturnsCheck !== true) {
                console.error(allReturnsCheck);
                Sentry.captureException(allReturnsCheck);
                navigate('/error');
                return;
            }

            setOriginalAllReturns(allReturns);

            let allReturnsFiltered = [];
            if (skuSelected) {
                allReturnsFiltered = allReturns.filter((container) => container.container_sku === skuSelected);
            } else {
                allReturnsFiltered = allReturns;
            }

            // RETURN IN TCE
            const returnTceRaw = allReturnsFiltered.filter((container) => container.collector_type === parseInt(Constant.COLLECTOR_TYPE_TCE));
            setReturnInTce(returnTceRaw);
            // RETURN IN RVM
            const returnRvmRaw = allReturnsFiltered.filter((container) => container.collector_type === parseInt(Constant.COLLECTOR_TYPE_RVM));
            setReturnInRvm(returnRvmRaw);

            // RETURN BY STORE OWNER
            const countedReturnsByStoreOwner = reduceDataByKeyAndQty(allReturnsFiltered, 'store_owner');
            const returnsByStoreOwnerRaw = Object.keys(countedReturnsByStoreOwner).map((owner) => ({
                owner: _defineStoreOwner(owner),
                qty: ((countedReturnsByStoreOwner[owner] / allReturnsFiltered.length) * 100).toFixed(1),
            }));
            setReturnsByStoreOwner(returnsByStoreOwnerRaw);
            // RETURN BY TYPE ENSEIGNE
            const countedReturnsByStoreType = reduceDataByKeyAndQty(allReturnsFiltered, 'store_type');
            const returnsByStoreTypeRaw = Object.keys(countedReturnsByStoreType).map((type) => ({
                type: _defineStoreType(type),
                qty: ((countedReturnsByStoreType[type] / allReturnsFiltered.length) * 100).toFixed(1),
            }));
            setReturnsByStoreType(returnsByStoreTypeRaw);

            // MY RETURNS
            const myReturns = await getReturnByDatesAndIndus(startDate, endOfDay(endDate));

            const myReturnsCheck = getReturnByDatesAndIndusCheck(myReturns);
            if (myReturnsCheck.length && myReturnsCheck !== true) {
                console.error(myReturnsCheck);
                Sentry.captureException(myReturnsCheck);
                navigate('/error');
                return;
            }
            setOriginalMyReturns(myReturns);

            let myReturnsFiltered = [];
            if (eanSelected) {
                myReturnsFiltered = myReturns.filter((container) => container.product_ean === eanSelected);
            } else {
                myReturnsFiltered = myReturns;
            }
            // MY RETURN IN TCE
            const totalInTce = allReturns.filter((container) => container.collector_type === parseInt(Constant.COLLECTOR_TYPE_TCE));
            setTotalInTce(totalInTce);
            const myReturnTceRaw = myReturnsFiltered.filter((container) => container.collector_type === parseInt(Constant.COLLECTOR_TYPE_TCE));
            setMyReturnInTce(myReturnTceRaw);

            // MY RETURN IN RVM
            const totalInRvm = allReturns.filter((container) => container.collector_type === parseInt(Constant.COLLECTOR_TYPE_RVM));
            setTotalInRvm(totalInRvm);
            const myReturnRvmRaw = myReturnsFiltered.filter((container) => container.collector_type === parseInt(Constant.COLLECTOR_TYPE_RVM));
            setMyReturnInRvm(myReturnRvmRaw);

            // MY RETURN BY STORE OWNER
            const countedMyReturnsByStoreOwner = reduceDataByKeyAndQty(myReturnsFiltered, 'store_owner');
            const myReturnsByStoreOwnerRaw = Object.keys(countedMyReturnsByStoreOwner).map((owner) => ({
                owner: _defineStoreOwner(owner),
                qty: ((countedMyReturnsByStoreOwner[owner] / myReturnsFiltered.length) * 100).toFixed(1),
            }));

            setMyReturnsByStoreOwner(myReturnsByStoreOwnerRaw);

            // MY RETURN BY TYPE ENSEIGNE
            const countedmMyReturnsByStoreType = reduceDataByKeyAndQty(myReturnsFiltered, 'store_type');
            const myReturnsByStoreTypeRaw = Object.keys(countedmMyReturnsByStoreType).map((type) => ({
                type: _defineStoreType(type),
                qty: ((countedmMyReturnsByStoreType[type] / myReturnsFiltered.length) * 100).toFixed(1),
            }));
            setMyReturnsByStoreType(myReturnsByStoreTypeRaw);

            // EXTRACT GLOBAL MARCHE
            const formattedTabGlobalDataRaw = allReturnsFiltered.map((item) => {
                return {
                    date: new Date(item.returnedAt).toLocaleDateString(),
                    year: new Date(item.returnedAt).getFullYear(),
                    month: new Date(item.returnedAt).toLocaleString('default', { month: 'long' }),
                    sku: item.container_sku,
                    region: getRegionsName(item.store_region),
                    type: _defineCollectorType(item.collector_type),
                    owner: _defineStoreOwner(item.store_owner),
                    storeType: _defineStoreType(item.store_type),
                };
            });
            setFormattedTabGlobalData(formattedTabGlobalDataRaw);

            // EXTRACT MES REFERENCES
            const formattedDataRaw = myReturnsFiltered.map((item) => {
                return {
                    date: new Date(item.returnedAt).toLocaleDateString(),
                    year: new Date(item.returnedAt).getFullYear(),
                    month: new Date(item.returnedAt).toLocaleString('default', { month: 'long' }),
                    ean: item.product_ean,
                    name: item.product_name,
                    cat: _defineProductCat(item.product_cat),
                    sku: item.container_sku,
                    region: getRegionsName(item.store_region),
                    type: _defineCollectorType(item.collector_type),
                    owner: _defineStoreOwner(item.store_owner),
                    storeType: _defineStoreType(item.store_type),
                };
            });
            setFormattedTabMyRefsData(formattedDataRaw);
        };
        if (startDate && endDate) fetchData();
    }, [startDate, endDate, userContext.entityId, skuSelected, eanSelected, navigate]);

    const returnByStoreTypeLabels = returnsByStoreType.map((item) => item.type);
    const returnByStoreTypeQuantities = returnsByStoreType.map((item) => item.qty);

    const returnByStoreOwnerLabels = returnsByStoreOwner.map((item) => item.owner);
    const returnByStoreOwnerQuantities = returnsByStoreOwner.map((item) => item.qty);

    const myReturnByStoreOwnerLabels = myReturnsByStoreOwner.map((item) => item.owner);
    const myReturnByStoreOwnerQuantities = myReturnsByStoreOwner.map((item) => item.qty);

    const myReturnByStoreTypeLabels = myReturnsByStoreType.map((item) => item.type);
    const myReturnByStoreTypeQuantities = myReturnsByStoreType.map((item) => item.qty);

    const resetGlobalFilter = () => {
        setSkuSelected('');
    };

    const resetMyRefsFilter = () => {
        setEanSelected('');
    };

    const customHeaderGlobal = [
        {
            label: 'Date',
            key: 'year',
        },
        {
            label: 'Année',
            key: 'year',
        },
        {
            label: 'Mois',
            key: 'month',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Région',
            key: 'region',
        },
        {
            label: 'Type de collecteur',
            key: 'type',
        },
        {
            label: 'Propriétaire',
            key: 'owner',
        },
        {
            label: 'Type de magasin',
            key: 'storeType',
        },
    ];

    const customHeaderMyRefs = [
        {
            label: 'Date',
            key: 'year',
        },
        {
            label: 'Année',
            key: 'year',
        },
        {
            label: 'Mois',
            key: 'month',
        },
        {
            label: 'EAN',
            key: 'ean',
        },
        {
            label: 'Nom',
            key: 'name',
        },
        {
            label: 'Catégorie',
            key: 'cat',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Région',
            key: 'region',
        },
        {
            label: 'Type de collecteur',
            key: 'type',
        },
        {
            label: 'Propriétaire',
            key: 'owner',
        },
        {
            label: 'Type de magasin',
            key: 'storeType',
        },
    ];

    const filteredSku = originalAllReturns.filter((item) => item.container_sku !== 'N/D' && item.container_owner === Constant.CONTAINER_OWNER_STATUS_STANDARD);
    const mySkus = [...new Set(filteredSku.map((fd) => fd.container_sku))];

    const myEans = [...new Set(originalMyReturns.map((fd) => fd.product_ean))];

    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>COLLECTEURS</h1>
                    <div className="date-container">
                        <DateFilter
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                </div>
                <div className="header-container">
                    <div className="header-title">
                        <h1>Global marché</h1>
                    </div>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setSkuSelected(e.target.value)}
                                value={skuSelected}>
                                <option value="">SKU</option>

                                {mySkus.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetGlobalFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>

                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderGlobal}
                                    data={formattedTabGlobalData ? formattedTabGlobalData : []}
                                    filename={`Les_retours_par_collecteur.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire la donnée globale
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: '22rem', marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1%' }}>
                        <section
                            className="section-graph"
                            style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Nombre de contenants retournés en TCE :{' '}
                            </div>
                            <div
                                style={{ fontSize: 25 }}
                                className="title">
                                <br />
                                {returnInTce.length ? returnInTce.length : 0}{' '}
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ flex: 1, marginBottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Nombre de contenants retournés en RVM :{' '}
                            </div>
                            <div
                                style={{ fontSize: 25 }}
                                className="title">
                                <br />
                                {returnInRvm.length ? returnInRvm.length : 0}{' '}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 2, height: '22rem' }}>
                        <section
                            className="section-graph"
                            style={{ height: '22rem' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Répartition des contenants retournés par propriétaire de collecteur (%) :{' '}
                            </div>
                            <div style={{ height: '90%' }}>
                                {returnsByStoreOwner.length > 0 ? (
                                    <CircleChart
                                        title={returnByStoreOwnerLabels}
                                        quantities={returnByStoreOwnerQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 2, height: '22rem' }}>
                        <section
                            className="section-graph"
                            style={{ height: '22rem' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Répartition des contenants retournés par enseigne (%) :{' '}
                            </div>
                            <div style={{ height: '90%' }}>
                                {returnsByStoreType.length > 0 ? (
                                    <PieChart
                                        title={returnByStoreTypeLabels}
                                        quantities={returnByStoreTypeQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph ">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>

                <div className="header-container">
                    <h1>Mes références</h1>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setEanSelected(e.target.value)}
                                value={eanSelected}>
                                <option value="">EAN</option>

                                {myEans.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetMyRefsFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>

                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderMyRefs}
                                    data={formattedTabMyRefsData ? formattedTabMyRefsData : []}
                                    filename={`Mes_retours_par_collecteur.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire ma donnée
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ height: '22rem', marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%' }}>
                    <section
                        className="section-graph"
                        style={{ height: '100%', marginBottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Nombre de mes contenants retournés en TCE :
                        </div>
                        <div
                            style={{ fontSize: 25 }}
                            className="title">
                            {myReturnInTce.length ? myReturnInTce.length : 0}
                        </div>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Contribution au marché du réemploi :
                        </div>
                        <div
                            style={{ fontSize: 25 }}
                            className="title">
                            {myReturnInTce.length && totalInTce.length ? `${((myReturnInTce.length / totalInTce.length) * 100).toFixed(1)}%` : 0}%
                        </div>
                    </section>

                    <section
                        className="section-graph"
                        style={{ height: '100%', marginBottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Nombre de mes contenants retournés en RVM :
                        </div>
                        <div
                            style={{ fontSize: 25 }}
                            className="title">
                            {myReturnInRvm.length ? myReturnInRvm.length : 0}
                        </div>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Contribution au marché du réemploi :
                        </div>
                        <div
                            style={{ fontSize: 25 }}
                            className="title">
                            {myReturnInRvm.length && totalInRvm.length ? `${((myReturnInRvm.length / totalInRvm.length) * 100).toFixed(1)}%` : 0}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '22rem', flex: 1.5 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Répartition de mes contenants retournés par propriétaire de collecteur (%) :{' '}
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsByStoreOwner.length > 0 ? (
                                <CircleChart
                                    title={myReturnByStoreOwnerLabels}
                                    quantities={myReturnByStoreOwnerQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '22rem', flex: 1.5 }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Répartition de mes contenants retournés par enseigne (%) :{' '}
                        </div>
                        <div style={{ height: '90%' }}>
                            {myReturnsByStoreType.length > 0 ? (
                                <PieChart
                                    title={myReturnByStoreTypeLabels}
                                    quantities={myReturnByStoreTypeQuantities}
                                    position={'left'}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
