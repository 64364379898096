export const $vertBocoloco = '#38b4a0';
export const $noir = '#000000';
export const $blanc = '#ffffff';
export const $blancGris = '#f8f8f8';
export const $gris = '#f5f5f5';
export const $vertpale = '#afded5';
export const $vertTresClair = '#e1f3ef';
export const $rouge = '#ed6a5e';
export const $rougePale = ' #f5b3ad';
export const $grisfonce = '#636363';
export const $grisclair = '#cac9cb';

// ENTITY
export const ENTITY_TYPE_FS = 0;
export const ENTITY_TYPE_INDUSTRIAL = 1;
export const ENTITY_TYPE_SORTER = 2;
export const ENTITY_TYPE_WASHER = 3;
export const ENTITY_TYPE_OPERATOR = 4;
export const ENTITY_TYPE_RVMOWNER = 5;
export const ENTITY_TYPE_STOREOWNER = 6;
export const ENTITY_TYPE_CDC = 7;
export const ENTITY_TYPE_DISTRIBUTOR = 8;

// ENTITY ROLE
export const ENTITY_ROLE_ADMIN = 'admin';
export const ENTITY_ROLE_CONTRIBUTOR = 'contributor';
export const ENTITY_ROLE_VIEWER = 'viewer';

export const USER_STATUS_ACTIF = 0;
export const USER_STATUS_INACTIF = 100;

// MODULES

export const MODULE_OPERATION = 'operation';
export const MODULE_DATA = 'data';
export const MODULE_FACTURATION = 'facturation';

// CONTAINER
export const CONTAINER_CAT_FRESHPRODUCT = '1';
export const CONTAINER_CAT_BEVERAGE = '2';
export const CONTAINER_CAT_CATERING = '3';

export const CONTAINER_OWNER_STATUS_STANDARD = 0;
export const CONTAINER_OWNER_STATUS_BRAND = 1;

export const CONTAINER_OWNER_CITEO = 0;
export const CONTAINER_OWNER_COCACOLA = 1;

export const CONTAINER_TYPE_GLASSJAR = 0;
export const CONTAINER_TYPE_BOTTLE = 1;
export const CONTAINER_TYPE_TRAY = 2;
export const CONTAINER_TYPE_BENTO = 3;
export const CONTAINER_TYPE_ROUNDBOX = 4;
export const CONTAINER_TYPE_BOWL = 5;
export const CONTAINER_TYPE_CONTAINER = 6;
export const CONTAINER_TYPE_CUP = 7;
export const CONTAINER_TYPE_PASTABOX = 8;
export const CONTAINER_TYPE_POT = 9;
export const CONTAINER_TYPE_OTHER = 10;

export const CONTAINER_SUBTYPE_CARROND = 0;
export const CONTAINER_SUBTYPE_ROUND = 1;
export const CONTAINER_SUBTYPE_EMPTY = 2;
export const CONTAINER_SUBTYPE_ALIENOR = 3;
export const CONTAINER_SUBTYPE_BORDEAUX = 4;
export const CONTAINER_SUBTYPE_BOURGOGNE = 5;
export const CONTAINER_SUBTYPE_CHAMPENOISE = 6;
export const CONTAINER_SUBTYPE_SPARKLING = 7;
export const CONTAINER_SUBTYPE_VICHY = 8;
export const CONTAINER_SUBTYPE_STEINIE = 10;
export const CONTAINER_SUBTYPE_LITREDEFRANCE = 11;
export const CONTAINER_SUBTYPE_LONGNECK = 12;
export const CONTAINER_SUBTYPE_FRESHLITER = 13;
export const CONTAINER_SUBTYPE_SQUARE = 14;
export const CONTAINER_SUBTYPE_ANGLE = 15;

export const CONTAINER_MATERIAL_COPOLYESTER = 0;
export const CONTAINER_MATERIAL_CPET = 1;
export const CONTAINER_MATERIAL_INOX = 2;
export const CONTAINER_MATERIAL_PBT = 3;
export const CONTAINER_MATERIAL_PP = 4;
export const CONTAINER_MATERIAL_PET = 5;
export const CONTAINER_MATERIAL_TRITAN = 6;
export const CONTAINER_MATERIAL_BOROSILICATE = 7;
export const CONTAINER_MATERIAL_SODOCALCIQUE = 8;
export const CONTAINER_MATERIAL_OTHER = 9;

export const CONTAINER_UNIT_ML = 0;
export const CONTAINER_UNIT_GR = 1;
export const CONTAINER_UNIT_CM3 = 2;

export const CONTAINER_PALETTYPE_40X60 = '1';
export const CONTAINER_PALETTYPE_40X80 = '2';
export const CONTAINER_PALETTYPE_80X100 = '3';
export const CONTAINER_PALETTYPE_80X120 = '4';
export const CONTAINER_PALETTYPE_100X120 = '5';
export const CONTAINER_PALETTYPE_120X120 = '6';

export const CONTAINER_USAGE_PRIMARY = 0;
export const CONTAINER_USAGE_SECONDARY = 1;

// COLLECTOR
export const COLLECTOR_TYPE_RVM = 1;
export const COLLECTOR_TYPE_AUTO = 2;
export const COLLECTOR_TYPE_TCE = 3;
export const COLLECTOR_TYPE_CASHIER = 4;

// STORE
export const STORE_TYPE_HYPER = 1;
export const STORE_TYPE_SUPER = 2;
export const STORE_TYPE_PROXI = 3;

// STORE OWNER
export const STORE_OWNER_MAGASIN = 1;
export const STORE_OWNER_COLLECTIVITE = 2;

// COLLECTOR/SORTER/WASHER EVENTS
export const EVENT_PENDING = 0;
export const EVENT_REQUESTED = 1;
export const EVENT_ACCEPTED = 2;
export const EVENT_DONE = 3;
export const EVENT_RECEIVED = 4;

// ORDER DETAILS STATUS
export const ORDERDETAILS_PENDING = 0;
export const ORDERDETAILS_SUPPLIER_ATTRIBUTED = 1;
export const ORDERDETAILS_WASHER_ATTRIBUTED = 2;
export const ORDERDETAILS_RECEIVED = 3;

// WASHER/SORTER DECLARATIONS DETAILS
export const DECLARATION_DETAILS_AVAILABLE = 0;
export const DECLARATION_DETAILS_ATTRIBUTED = 1;
export const DECLARATION_DETAILS_VALIDATED = 2;

// WASHER/SORTER DECLARATIONS DETAILS TYPE
export const DECLARATION_DETAILS_TYPE_OK = 0;
export const DECLARATION_DETAILS_TYPE_REBUS = 1;

// SUPPLIER ORDER DETAILS STATUS
export const SUPPLIER_ORDERDETAILS_ATTRIBUTED = 0;
export const SUPPLIER_ORDERDETAILS_REPLACED = 1;

// COLLECTOR RETURN STATUS
export const COLLECTORRETURN_INCOLLECTOR = 0;
export const COLLECTORRETURN_RETURNED = 1;

// PRODUCT CAT
export const INDUSPRODUCT_CAT_EPICERIESALEE = 1;
export const INDUSPRODUCT_CAT_EPICERIESUCREE = 2;
export const INDUSPRODUCT_CAT_PRODUITSFRAIS = 2;
export const INDUSPRODUCT_CAT_BOISSON = 3;

// COLLECTOR RETURN TYPE
export const COLLECTORRETURN_TYPE_BRI = 0;
export const COLLECTORRETURN_TYPE_CB = 1;

// STORESBOXES STATUS
export const STORESBOXES_STATUS_INSTORE = 1;
export const STORESBOXES_STATUS_RETURNED = 2;

export const STORESBOXES_STATE_FILL = 0;
export const STORESBOXES_STATE_EMPTY = 1;

export const STOREEVENT_TYPE_COLLECTE = 1;
export const STOREEVENT_TYPE_DELIVERY = 2;

export const STOREEVENTDETAILS_FROM_PERIODIQUE = 1;
export const STOREEVENTDETAILS_FROM_FULL = 2;
export const STOREEVENTDETAILS_FROM_MANUAL_FS = 3;
export const STOREEVENTDETAILS_FROM_MANUAL_STORE = 4;
export const STOREEVENTDETAILS_FROM_STORE_MODIFIED = 5;
export const STOREEVENTDETAILS_FROM_FS_MODIFIED = 6;

export const COLLECTETYPE_STORE = 0;
export const COLLECTETYPE_SORTER = 1;
export const COLLECTETYPE_WASHER = 2;
