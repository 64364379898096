import { useEffect, useState } from 'react';
import { getReturnByDates, getReturnByDatesAndIndus } from '../../../../API/CollectorReturnApi';
import { getReturnByDatesCheck, getReturnByDatesAndIndusCheck } from '../../../../API/CheckApi/CheckCollectorReturnApi';
import BarChart from '../../../../Components/BarChart.jsx';
import LineChart from '../../../../Components/LineChart';
import { Jwt } from '../../../../Jwt';
import { endOfDay } from 'date-fns';
import DateFilter from '../../../../Components/DateFilter';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { getQtyByDayOfWeek, getQtyByHourOfDay } from '../../../../UtilsData';
export default function IndusDataConsumerFrequence() {
    const navigate = useNavigate();
    const now = new Date();
    const userContext = new Jwt();
    const [returnsByWeekDay, setReturnsByWeekDay] = useState([]);
    const [returnsByHour, setReturnsByHour] = useState([]);
    const [MyReturnsByWeekDay, setMyReturnsByWeekDay] = useState([]);
    const [MyReturnsByHour, setMyReturnsByHour] = useState([]);

    // FILTRES
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);

    useEffect(() => {
        const fetchData = async () => {
            setReturnsByWeekDay([]);
            setReturnsByHour([]);
            setMyReturnsByWeekDay([]);
            setMyReturnsByHour([]);

            const allReturnsRaw = await getReturnByDates(startDate, endOfDay(endDate));
            const allReturnsCheck = getReturnByDatesCheck(allReturnsRaw);
            if (allReturnsRaw.length && allReturnsCheck !== true) {
                console.error(allReturnsCheck);
                Sentry.captureException(allReturnsCheck);
                navigate('/error');
                return;
            }

            const countedByWeekDays = getQtyByDayOfWeek(allReturnsRaw);
            // NB RETOUR BY DAY OF WEEK
            const returnsByWeekDayRaw = Object.keys(countedByWeekDays).map((item) => ({
                day: item,
                qty: countedByWeekDays[item],
            }));
            setReturnsByWeekDay(returnsByWeekDayRaw);

            // NB RETOUR BY HOUR OF DAY
            const countedByHourOfDay = getQtyByHourOfDay(allReturnsRaw);
            const returnsByHoursRaw = Object.keys(countedByHourOfDay).map((item) => ({
                hour: item + 'h',
                qty: countedByHourOfDay[item],
            }));
            setReturnsByHour(returnsByHoursRaw);

            // MY REFERENCES
            const myReturnsRaw = await getReturnByDatesAndIndus(startDate, endOfDay(endDate));
            const myReturnsCheck = getReturnByDatesAndIndusCheck(myReturnsRaw);
            if (myReturnsRaw.length && myReturnsCheck !== true) {
                console.error(myReturnsCheck);
                Sentry.captureException(myReturnsCheck);
                navigate('/error');
                return;
            }

            // NB RETOUR OF MY REFERENCES BY DAY OF WEEK
            const myCountedByWeekDays = getQtyByDayOfWeek(myReturnsRaw);
            const myReturnsByWeekDayRaw = Object.keys(myCountedByWeekDays).map((item) => ({
                day: item,
                qty: myCountedByWeekDays[item],
            }));
            setMyReturnsByWeekDay(myReturnsByWeekDayRaw);

            // NB RETOUR OF MY REFERENCES BY HOUR OF DAY
            const myCountedByHourOfDay = getQtyByHourOfDay(myReturnsRaw);
            const myReturnsByHoursRaw = Object.keys(myCountedByHourOfDay).map((item) => ({
                hour: item + 'h',
                qty: myCountedByHourOfDay[item],
            }));
            setMyReturnsByHour(myReturnsByHoursRaw);
        };
        if (startDate && endDate) fetchData();
    }, [startDate, endDate, userContext.entityId, navigate]);

    const returnsByWeekDayQuantities = returnsByWeekDay.map((item) => item.qty);
    const returnsByWeekDayLabels = returnsByWeekDay.map((item) => item.day);

    const returnsByHourQuantities = returnsByHour.map((item) => item.qty);
    const returnsByHourLabels = returnsByHour.map((item) => item.hour);

    const myReturnsByWeekDayQuantities = MyReturnsByWeekDay.map((item) => item.qty);
    const myReturnsByWeekDayLabels = MyReturnsByWeekDay.map((item) => item.day);

    const myReturnsByHourQuantities = MyReturnsByHour.map((item) => item.qty);
    const myReturnsByHourLabels = MyReturnsByHour.map((item) => item.hour);

    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>FREQUENCE</h1>
                    <div className="date-container">
                        <DateFilter
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                </div>
                <div className="header-container">
                    <div className="header-title">
                        <h1>Global marché</h1>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '1%', height: '25rem', marginBottom: '2rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '25rem', width: '50%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Nombre de retours par jour de la semaine
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsByWeekDay.length > 0 ? (
                                <BarChart
                                    title={returnsByWeekDayLabels}
                                    quantities={returnsByWeekDayQuantities}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '25rem', width: '50%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Nombre de retours par heure de la journee
                        </div>
                        <div style={{ height: '90%' }}>
                            {returnsByHour.length > 0 ? (
                                <LineChart
                                    labels={returnsByHourLabels}
                                    quantities={returnsByHourQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
                <div className="header-container">
                    <h1>Mes références</h1>
                </div>
                <div style={{ display: 'flex', gap: '1%', height: '25rem', marginBottom: '2rem' }}>
                    <section
                        className="section-graph"
                        style={{ height: '25rem', width: '50%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Mes quantités mises sur le marché :
                        </div>
                        <div style={{ height: '90%' }}>
                            {MyReturnsByWeekDay.length > 0 ? (
                                <BarChart
                                    title={myReturnsByWeekDayLabels}
                                    quantities={myReturnsByWeekDayQuantities}
                                />
                            ) : (
                                <div className="no-data-graph">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                    <section
                        className="section-graph"
                        style={{ height: '25rem', width: '50%' }}>
                        <div
                            className="title"
                            style={{ height: '1rem' }}>
                            Nombre de retours par heure de la journee
                        </div>
                        <div style={{ height: '90%' }}>
                            {MyReturnsByHour.length > 0 ? (
                                <LineChart
                                    labels={myReturnsByHourLabels}
                                    quantities={myReturnsByHourQuantities}
                                />
                            ) : (
                                <div className="no-data-graph ">Aucune donnée.</div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
