import { useState, useEffect } from 'react';
import { getAllFinancialCashInByDatesCheck } from '../../../API/CheckApi/CheckFinancialCashInReporting';
import { downloadReportingCashInCsv, downloadReportingCashInPdf, getAllFinancialCashInByDates } from '../../../API/FinancialCashInReportingApi';
import { getIndustrials } from '../../../API/IndustrialApi';
import { endOfDay } from 'date-fns';
import { useNavigate, Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Eye, DotsThree, FileCsv, FilePdf } from '@phosphor-icons/react';

export default function CdcIndustrial() {
    const navigate = useNavigate();
    const now = new Date();
    const [startDate, setStartDate] = useState(startOfMonth(new Date()));
    const [endDate, setEndDate] = useState(endOfMonth(new Date()));
    const currentMonth = format(new Date(), 'MM');
    const [monthSelected, setMonthSelected] = useState(currentMonth);
    const [yearSelected, setYearSelected] = useState(now.getFullYear());
    const [reportingTab, setReportingTab] = useState([]);
    const [industrialList, setIndustrialList] = useState([]);
    const [industrialSelected, setIndustrialSelected] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // TOUS LES FINANCIAL CASHIN
                const allFinancialCashInRaw = await getAllFinancialCashInByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));

                const allFinancialCashInCheck = getAllFinancialCashInByDatesCheck(allFinancialCashInRaw);
                if (allFinancialCashInRaw.length && allFinancialCashInCheck !== true) {
                    console.error(allFinancialCashInCheck);
                    Sentry.captureException(allFinancialCashInCheck);
                    navigate('/error');
                    return;
                }

                let allFinancialCashInFiltered = [];
                if (industrialSelected) {
                    allFinancialCashInFiltered = allFinancialCashInRaw.filter((reporting) => reporting.entityId === parseInt(industrialSelected));
                } else {
                    allFinancialCashInFiltered = allFinancialCashInRaw;
                }
                const groupedData = groupByMonthAndEntityId(allFinancialCashInFiltered);
                const sortedDataDesc = groupedData.sort((a, b) => {
                    if (a.year === b.year) {
                        return b.month - a.month;
                    }
                    return b.year - a.year;
                });

                setReportingTab(sortedDataDesc);

                // TOUS LES INDUSTRIELS
                const allIndustrials = await getIndustrials();
                setIndustrialList(allIndustrials);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (startDate & endDate) fetchData();
    }, [startDate, endDate, navigate, industrialSelected]);

    const groupByMonthAndEntityId = (data) => {
        const groupedData = {};

        data.forEach((item) => {
            const month = format(item.endDate, 'MM');
            const year = format(item.endDate, 'yyyy');
            const entityId = item.entityId;
            const key = `${year}-${month}-${entityId}`;

            if (!groupedData[key]) {
                groupedData[key] = {
                    month,
                    year,
                    entity: item.entity,
                    items: [],
                };
            }
            groupedData[key].items.push(item);
        });

        return Object.values(groupedData);
    };
    const resetGlobalFilter = () => {
        setMonthSelected(currentMonth);
        setYearSelected(now.getFullYear());
        setStartDate(startOfMonth(new Date()));
        setEndDate(endOfMonth(new Date()));
        setIndustrialSelected('');
    };

    const getAllMonthsInYear = () => {
        const months = [{ month: "Depuis le début de l'année", value: 'all' }];
        const monthNames = [
            { month: 'janvier', value: '01' },
            { month: 'février', value: '02' },
            { month: 'mars', value: '03' },
            { month: 'avril', value: '04' },
            { month: 'mai', value: '05' },
            { month: 'juin', value: '06' },
            { month: 'juillet', value: '07' },
            { month: 'août', value: '08' },
            { month: 'septembre', value: '09' },
            { month: 'octobre', value: '10' },
            { month: 'novembre', value: '11' },
            { month: 'décembre', value: '12' },
        ];

        months.push(...monthNames);

        return months;
    };
    const months = getAllMonthsInYear();

    const getYears = () => {
        const years = [];
        for (let year = 2020; year <= now.getFullYear(); year++) {
            years.push(year);
        }
        return years;
    };
    const years = getYears();

    const handleMonthSelected = (month) => {
        if (month === 'all') {
            setStartDate(new Date(new Date().getFullYear(), 0, 1));
            setEndDate(now);
        } else {
            setStartDate(startOfMonth(new Date(now.getFullYear(), month - 1, 1)));
            setEndDate(endOfMonth(new Date(now.getFullYear(), month - 1, 1)));
        }
        setMonthSelected(month);
    };
    const handleYearSelected = (year) => {
        setYearSelected(year);
        setStartDate(startOfMonth(new Date(year, monthSelected - 1, 1)));
        setEndDate(endOfMonth(new Date(year, monthSelected - 1, 1)));
    };

    const getCsv = async (fileName) => {
        try {
            const response = await downloadReportingCashInCsv(fileName);
            if (response) {
                console.log('response', response);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const getPdf = async (fileName) => {
        try {
            const response = await downloadReportingCashInPdf(fileName);
            if (response) {
                console.log('response', response);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>REPORTING MENSUELS MARQUES</h1>
                </div>

                <div className="header-container">
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => handleMonthSelected(e.target.value)}
                                value={monthSelected}>
                                {months.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.value}>
                                        {option.month}
                                    </option>
                                ))}
                            </select>
                            <select
                                style={{ width: '5rem' }}
                                onChange={(e) => handleYearSelected(e.target.value)}
                                value={yearSelected}>
                                {years.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <select
                                style={{ width: '15rem' }}
                                onChange={(e) => setIndustrialSelected(e.target.value)}
                                value={industrialSelected}>
                                <option value={''}>Toutes les entités juridiques</option>
                                {industrialList.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetGlobalFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-title">
                    <h1>
                        {monthSelected === 'all' ? "Depuis le début de l'année" : format(monthSelected, 'MMMM', { locale: fr })} {yearSelected}
                    </h1>
                </div>
                <div style={{ marginBottom: '2rem', display: 'flex', width: '100%', gap: '3%', flexDirection: 'column' }}>
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600', width: '100%', fontSize: 15 }}>
                            <p style={{ flex: 1 }}>Société juridique</p>
                            <p style={{ flex: 1 }}>Siret</p>
                            <p style={{ flex: 1 }}>Marque</p>
                            <p style={{ flex: 1 }}>Période</p>
                            <p style={{ flex: 1 }}>Date reporting</p>
                            <p style={{ flex: 1, borderRight: 0 }}>Montant consigne (€)</p>
                            <p style={{ flex: 0.5 }}></p>
                        </div>
                    </div>

                    {reportingTab.length ? (
                        reportingTab.map((elem, index) => (
                            <section
                                key={index}
                                className="section-graph"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    height: '4rem',
                                    alignItems: 'center',
                                    padding: 0,
                                    position: 'relative',
                                }}>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{elem.entity.company_name}</p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{elem.entity.siret}</p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{elem.entity.name}</p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>
                                    {format(elem.month, 'MMMM', { locale: fr })} {elem.year}
                                </p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{format(elem.items[0].createdAt, 'dd/MM/yyyy')}</p>
                                <p style={{ flex: 1, paddingLeft: '0.93rem' }}>{elem.items.reduce((acc, item) => acc + item.amount / 100, 0)} €</p>
                                <p
                                    style={{ flex: 0.5, paddingLeft: '0.93rem', cursor: 'pointer', textAlign: 'center' }}
                                    onClick={() => {
                                        setSelectedIndex(index);
                                        setShowOptions(index === selectedIndex ? !showOptions : false);
                                    }}>
                                    <DotsThree size={25} />
                                </p>
                                {showOptions && index === selectedIndex && (
                                    <div className="option-container tooltip ">
                                        <p style={{ cursor: 'pointer' }}>
                                            <Link
                                                to={'/cdc/financial/industrial/details'}
                                                state={{ startDate: startDate, endDate: endDate, date: elem.items[0].createdAt, entityId: elem.entity.id }}>
                                                <Eye size={25} />
                                            </Link>
                                        </p>
                                        <p
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                getCsv(elem.items[0].csvFile);
                                            }}>
                                            <FileCsv size={25} />
                                        </p>
                                        <p
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                getPdf(elem.items[0].pdfFile);
                                            }}>
                                            <FilePdf size={25} />
                                        </p>
                                    </div>
                                )}
                            </section>
                        ))
                    ) : (
                        <div className="no-data-graph ">Aucune donnée.</div>
                    )}
                </div>
            </div>
        </main>
    );
}
