import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { endOfDay } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { getReturnByDatesAndStoreOwner } from '../../../API/CollectorReturnApi';
import { getReturnByDatesAndStoreOwnerCheck } from '../../../API/CheckApi/CheckCollectorReturnApi';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { _defineCollectorType, _defineRemboursementType } from '../../../Utils';
import { CSVLink } from 'react-csv';
import * as Constants from '../../../Constants';

export default function StoreOwnerReportingDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const { startDate, endDate, date, entity } = location.state || {};
    const [allReturns, setAllReturns] = useState({});
    const [formattedTabData, setFormattedTabData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const formattedStartDate = format(startDate, 'yyyy-MM-dd');
            const formattedEndDate = format(endDate, 'yyyy-MM-dd');

            try {
                // TOUTES LES RETOURS BRI DU MAGASIN
                const allReturnsRaw = await getReturnByDatesAndStoreOwner(formattedStartDate, endOfDay(formattedEndDate), entity);

                const allReturnsRawCheck = getReturnByDatesAndStoreOwnerCheck(allReturnsRaw);
                if (allReturnsRaw.length && allReturnsRawCheck !== true) {
                    console.error(allReturnsRawCheck);
                    Sentry.captureException(allReturnsRawCheck);
                    navigate('/error');
                    return;
                }

                setAllReturns(allReturnsRaw);

                const formattedcsvData = allReturnsRaw.map((item) => {
                    return {
                        company_name: item.company_name,
                        store_name: item.store_name,
                        date: format(item.returnedAt, 'yyyy-MM-dd'),
                        collectorId: item.collector_id,
                        collectorType: _defineCollectorType(item.collector_type),
                        remboursement: _defineRemboursementType(item.return_type),
                        amount: (item.container_price / 100).toFixed(2),
                    };
                });
                setFormattedTabData(formattedcsvData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [
        startDate,
        endDate,
        navigate,
        entity,
    ]);

    const customHeaderMyRefs = [
        {
            label: 'Société juridique',
            key: 'company_name',
        },
        {
            label: 'Magasin',
            key: 'store_name',
        },
        {
            label: 'Date retour',
            key: 'date',
        },
        {
            label: 'Collecteur ID',
            key: 'collectorId',
        },
        {
            label: 'Type de collecteur',
            key: 'collectorType',
        },
        {
            label: 'Mode remboursement',
            key: 'remboursement',
        },
        {
            label: 'Montant',
            key: 'amount',
        },
    ];
    return (
        <main>
            {allReturns.length ? (
                <section
                    className="big-section"
                    style={{ width: '95%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                        <h2>
                            Reporting {entity ? allReturns[0].store_name : 'MAGASINS'} : {format(date, 'dd-MM-yyyy')}{' '}
                        </h2>
                        <CSVLink
                            style={{
                                textDecoration: 'none',
                                padding: '0.5rem',
                                borderRadius: '0.40rem',
                                backgroundColor: Constants.$vertBocoloco,
                                color: Constants.$blanc,
                            }}
                            className="extract-button"
                            headers={customHeaderMyRefs}
                            data={formattedTabData ? formattedTabData : []}
                            filename={`stores_${format(date, 'dd-MM-yyyy')}.csv`}
                            target="_blank"
                            separator={';'}>
                            Télécharger
                        </CSVLink>
                    </div>

                    <h2>Total : {allReturns.reduce((acc, item) => acc + item.container_price / 100, 0).toFixed(2)}€</h2>
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600' }}>
                            {!entity ? <p>Société juridique</p> : null}
                            {!entity ? <p>Magasin</p> : null}
                            <p>Date retour</p>
                            <p>Collecteur ID</p>
                            <p>Type de collecteur</p>
                            <p>Remboursement</p>
                            <p>Montant consigne (€)</p>
                        </div>

                        {allReturns.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="pre-filled-fields-2-several-item">
                                    {!entity ? <p>{item.company_name}</p> : null}
                                    {!entity ? <p>{item.store_name}</p> : null}

                                    <p>{format(item.returnedAt, 'yyyy-MM-dd')}</p>
                                    <p>{item.collector_id}</p>
                                    <p>{_defineCollectorType(item.collector_type)}</p>
                                    <p>{_defineRemboursementType(item.return_type)}</p>
                                    <p>{(item.container_price / 100).toFixed(2)}</p>
                                </div>
                            );
                        })}
                    </div>
                </section>
            ) : (
                <div className="no-data-graph ">Aucune donnée.</div>
            )}
        </main>
    );
}
