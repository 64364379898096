import { isDataValid } from '../../Utils';
// COLLECTOR RETURN API
export const getReturnByCollectorIdCheck = (data) => {
    const name = 'getReturnByCollectorId : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].collector.id) {
            return name + 'No collector id found';
        } else if (!data[i].collector.store.id) {
            return name + 'No store id found';
        } else if (data[i].entityIndustrialProduct) {
            if (!data[i].entityIndustrialProduct.id) {
                return name + 'No entity industrial product found';
            } else if (!data[i].entityIndustrialProduct.container.id) {
                return name + 'No container id found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getReturnByDatesCheck = (data) => {
    const name = 'getReturnByDates : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].returnedAt) {
            return name + 'No date found';
        } else if (!isDataValid(data[i].return_type)) {
            return name + 'No return type found';
        } else if (!isDataValid(data[i].qty)) {
            return name + 'No quantity found';
        } else if (!isDataValid(data[i].collector_type)) {
            return name + 'No collector type found';
        } else if (!isDataValid(data[i].store_region)) {
            return name + 'No store region found';
        } else if (!isDataValid(data[i].store_type)) {
            return name + 'No store type found';
        } else if (!isDataValid(data[i].store_owner)) {
            return name + 'No store owner found';
        } else if (!isDataValid(data[i].container_sku)) {
            return name + 'No container sku found';
        } else if (!isDataValid(data[i].container_owner)) {
            return name + 'No container owner found';
        } else if (!isDataValid(data[i].container_type)) {
            return name + 'No container type found';
        } else if (!isDataValid(data[i].container_price)) {
            return name + 'No container price found';
        } else {
            return true;
        }
    }
};

export const getAllReturnInCollectorCheck = (data) => {
    const name = 'getAllReturnInCollectorCheck : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].id) {
            return name + 'No id found';
        } else if (data[i].entityIndustrialProduct) {
            if (!data[i].entityIndustrialProduct.id) {
                return name + 'No entity industrial product found';
            } else if (!data[i].entityIndustrialProduct.container.id) {
                return name + 'No container id found';
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
};

export const getReturnByDatesAndStoreOwnerCheck = (data) => {
    const name = 'getReturnByDatesAndStoreOwner : ';
    for (let i = 0; i < data.length; i++) {
        if (!isDataValid(data[i].company_name)) {
            return name + 'No company name found';
        } else if (!isDataValid(data[i].store_name)) {
            return name + 'No store name found';
        } else if (!isDataValid(data[i].returnedAt)) {
            return name + 'No date found';
        } else if (!isDataValid(data[i].collector_id)) {
            return name + 'No collector Id found';
        } else if (!isDataValid(data[i].collector_type)) {
            return name + 'No collector type found';
        } else if (!isDataValid(data[i].return_type)) {
            return name + 'No return type found';
        } else if (!isDataValid(data[i].container_price)) {
            return name + 'No container price found';
        } else {
            return true;
        }
    }
};

export const getReturnStoreByDatesCheck = (data) => {
    const name = 'getReturnByDatesAndStoreOwner : ';
    for (let i = 0; i < data.length; i++) {
        if (!isDataValid(data[i].company_name)) {
            return name + 'No company name found';
        } else if (!isDataValid(data[i].store_name)) {
            return name + 'No store name found';
        } else if (!isDataValid(data[i].returnedAt)) {
            return name + 'No date found';
        } else if (!isDataValid(data[i].collector_id)) {
            return name + 'No collector Id found';
        } else if (!isDataValid(data[i].collector_type)) {
            return name + 'No collector type found';
        } else if (!isDataValid(data[i].return_type)) {
            return name + 'No return type found';
        } else if (!isDataValid(data[i].container_price)) {
            return name + 'No container price found';
        } else {
            return true;
        }
    }
};

export const getReturnByDatesAndCollectorSupplierCheck = (data) => {
    const name = 'getReturnByDatesAndCollectorSupplier : ';
    for (let i = 0; i < data.length; i++) {
        if (!isDataValid(data[i].returnedAt)) {
            return name + 'No date found';
        } else if (!isDataValid(data[i].collector_id)) {
            return name + 'No collector Id found';
        } else if (!isDataValid(data[i].collector_type)) {
            return name + 'No collector type found';
        } else if (!isDataValid(data[i].return_type)) {
            return name + 'No return type found';
        } else if (!isDataValid(data[i].container_price)) {
            return name + 'No container price found';
        } else {
            return true;
        }
    }
};

export const getReturnCollectorByDatesCheck = (data) => {
    const name = 'getReturnCollectorByDates : ';
    for (let i = 0; i < data.length; i++) {
        if (!isDataValid(data[i].returnedAt)) {
            return name + 'No date found';
        } else if (!isDataValid(data[i].collector_id)) {
            return name + 'No collector Id found';
        } else if (!isDataValid(data[i].collector_type)) {
            return name + 'No collector type found';
        } else if (!isDataValid(data[i].return_type)) {
            return name + 'No return type found';
        } else if (!isDataValid(data[i].container_price)) {
            return name + 'No container price found';
        } else {
            return true;
        }
    }
};

export const getReturnByDatesAndDistributorCheck = (data) => {
    const name = 'getReturnByDatesAndDistributor : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].returnedAt) {
            return name + 'No date found';
        } else if (!isDataValid(data[i].return_type)) {
            return name + 'No return type found';
        } else if (!isDataValid(data[i].qty)) {
            return name + 'No quantity found';
        } else if (!isDataValid(data[i].collector_type)) {
            return name + 'No collector type found';
        } else if (!isDataValid(data[i].store_region)) {
            return name + 'No store region found';
        } else if (!isDataValid(data[i].store_type)) {
            return name + 'No store type found';
        } else if (!isDataValid(data[i].store_owner)) {
            return name + 'No store owner found';
        } else if (!isDataValid(data[i].store_name)) {
            return name + 'No store name found';
        } else if (!isDataValid(data[i].container_sku)) {
            return name + 'No container sku found';
        } else if (!isDataValid(data[i].container_owner)) {
            return name + 'No container owner found';
        } else {
            return true;
        }
    }
};

export const getReturnByDatesAndIndusCheck = (data) => {
    const name = 'getReturnByDatesAndIndus : ';
    for (let i = 0; i < data.length; i++) {
        if (!data[i].returnedAt) {
            return name + 'No date found';
        } else if (!isDataValid(data[i].return_type)) {
            return name + 'No return type found';
        } else if (!isDataValid(data[i].qty)) {
            return name + 'No quantity found';
        } else if (!isDataValid(data[i].collector_type)) {
            return name + 'No collector type found';
        } else if (!isDataValid(data[i].store_region)) {
            return name + 'No store region found';
        } else if (!isDataValid(data[i].store_type)) {
            return name + 'No store type found';
        } else if (!isDataValid(data[i].store_owner)) {
            return name + 'No store owner found';
        } else if (!isDataValid(data[i].product_ean)) {
            return name + 'No product ean found';
        } else if (!isDataValid(data[i].product_cat)) {
            return name + 'No product category found';
        } else if (!isDataValid(data[i].product_name)) {
            return name + 'No product name found';
        } else if (!isDataValid(data[i].container_sku)) {
            return name + 'No container sku found';
        } else if (!isDataValid(data[i].container_owner)) {
            return name + 'No container owner found';
        } else if (!isDataValid(data[i].container_type)) {
            return name + 'No container type found';
        } else if (!isDataValid(data[i].container_price)) {
            return name + 'No container price found';
        } else {
            return true;
        }
    }
};
