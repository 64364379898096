import { getGearAPIApiEndpoint } from './endpoints';
import * as Sentry from '@sentry/react';
import { defaultHeaders } from './headers';

const GEAR_API_BASE_URL = getGearAPIApiEndpoint();

const BOCOLOCO_CREATE_WASHERDECLARATION = '/washerdeclaration/new';
const BOCOLOCO_GET_DECLARATIONS_BYENTITYID = '/washerdeclaration/getbyentityid';
const BOCOLOCO_GET_DECLARATIONS_DETAILS = '/washerdeclaration/getdetails';
const BOCOLOCO_UPDATE_WASHER_DECLARATIONS_DETAILS = '/washerdeclaration/update';
const BOCOLOCO_GET_DECLARATIONS_DETAILS_BY_ENTITYID_LAST_STATUS = '/washerdeclaration/gedetailsdyentityid/laststatus';
const BOCOLOCO_GET_DECLARATIONS_DETAILS_ATTRIBUTED_BY_ENTITYID = '/washerdeclaration/gedetailsdyentityid/attributed';
const BOCOLOCO_UPDATE_WASHER_DECLARATIONS_DETAILS_VALIDATION = '/washerdeclaration/update/validation';
const BOCOLOCO_GET_DECLARATIONS_DETAILS_ATTRIBUTED_BY_ORDERDETAILID = '/washerdeclaration/getdetailsbyorderdetailid';
const BOCOLOCO_GET_DETAILS_LAST_SORTEREVENT_STATUS = '/washerdeclaration/details/laststatus';
const BOCOLOCO_GET_DECLARATIONS_DETAILS_NOTASSIGNED_BYSKU = '/washerdeclaration/getdetails/notassign';
const BOCOLOCO_GET_ALL_WASHER_DECLARATIONS_DETAILS_BYDATES_STANDARD = '/washerdeclaration/standard/getalldetails/bydates';
const BOCOLOCO_GET_DECLARATIONS_DETAILS_ATTRIBUTED_BY_ORDERDETAILID_DATA = '/washerdeclaration/getdetailsbyorderdetailid/data';

export function washerDeclarationCreate(declarationDetailsData, entity_id, user_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_CREATE_WASHERDECLARATION;
    var url_args = '?entity_id=' + entity_id + '&user_id=' + user_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(declarationDetailsData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR WASHER DECLARATION CREATE');
            console.log(error);
            throw error;
        });
}

export function getStandardWasherDeclarationDetailsByDates(startDate, endDate) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_ALL_WASHER_DECLARATIONS_DETAILS_BYDATES_STANDARD;
    var url_args = '?startDate=' + startDate + '&endDate=' + endDate;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getDeclarationsByWasherId(entity_id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_BYENTITYID;
    var url_args = '?entity_id=' + entity_id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getWasherDeclarationDetails(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_DETAILS;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateWasherDeclarationDetails(formData, suiviId) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_WASHER_DECLARATIONS_DETAILS;
    var url_args = '?suiviId=' + suiviId;
    const url = encodeURI(url_base + url_args);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE WASHER DECLARATION DETAILS');
            console.log(error);
            throw error;
        });
}

export function getWasherDeclarationDetailsLastStatusByEntityId(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_DETAILS_BY_ENTITYID_LAST_STATUS;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getWasherDeclarationDetailsAttributedByEntityId(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_DETAILS_ATTRIBUTED_BY_ENTITYID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function updateWasherDeclarationDetailsValidation(formData) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_UPDATE_WASHER_DECLARATIONS_DETAILS_VALIDATION;
    const url = encodeURI(url_base);
    return fetch(url, {
        method: 'PUT',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...defaultHeaders,
        },
    })
        .then((response) => response.json())
        .catch((error) => {
            console.log('ERROR UPDATE WASHER DECLARATION DETAILS');
            console.log(error);
            throw error;
        });
}

export function getWasherDeclarationDetailsByOrderDetailId(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_DETAILS_ATTRIBUTED_BY_ORDERDETAILID;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getWasherDeclarationDetailsByOrderDetailIdData(id) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_DETAILS_ATTRIBUTED_BY_ORDERDETAILID_DATA;
    var url_args = '?id=' + id;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getDetailsLastWasherEventStatus() {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DETAILS_LAST_SORTEREVENT_STATUS;
    const url = encodeURI(url_base);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function getWasherDeclarationNoAssignedBySku(sku) {
    const url_base = GEAR_API_BASE_URL + BOCOLOCO_GET_DECLARATIONS_DETAILS_NOTASSIGNED_BYSKU;
    var url_args = '?sku=' + sku;
    const url = encodeURI(url_base + url_args);
    return fetch(url, { headers: defaultHeaders })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Sentry.captureException({ url, response });
                return null;
            }
        })
        .catch((error) => {
            throw error;
        });
}
