import Cookies from 'js-cookie';

// autorisation avec token
const token = Cookies.get('userToken');
export const defaultHeaders = {
    Authorization: `Bearer ${token}`,
};

export function getDefaultHeaders() {
    const token = Cookies.get('userToken');
    return {
        Authorization: `Bearer ${token}`,
    };
}
