import { useEffect, useState } from 'react';
import { getIndustrialProductByDates, getIndustrialProductByDatesAndIndus } from '../../../../API/IndustrialApi';
import { getAllStandardSalesDetailsByDatesCheck, getAllSalesDetailsByDatesAndIndusCheck } from '../../../../API/CheckApi/CheckSalesApi';
import { getIndustrialProductByDatesCheck } from '../../../../API/CheckApi/CheckIndustrialApi';
import PieChart from '../../../../Components/PieChart';
import 'react-datepicker/dist/react-datepicker.css';
import { Jwt } from '../../../../Jwt';
import BarChart from '../../../../Components/BarChart';
import { getAllStandardSalesDetailsByDates, getAllSalesDetailsByDatesAndIndus } from '../../../../API/SalesApi';
import { _defineStoreType, _defineProductCat } from '../../../../Utils';
import BarChartMulti from '../../../../Components/BarChartMulti';
import { endOfDay } from 'date-fns';
import { CSVLink } from 'react-csv';
import DateFilter from '../../../../Components/DateFilter';
import * as Constant from '../../../../Constants';
import { reduceDataByKeyAndQty, transformDataEmpliee } from '../../../../UtilsData';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

export default function IndusDataReuseProducts() {
    const navigate = useNavigate();
    const now = new Date();
    const userContext = new Jwt();
    const [originalReferencesReuse, setOriginalReferencesReuse] = useState([]);
    const [originalreferencesReauseByIndus, setOriginalreferencesReauseByIndus] = useState([]);
    const [allReferences, setAllReferences] = useState([]);
    const [referencesByIndus, setReferencesByIndus] = useState([]);
    const [contributionReuseMarket, setContributionReuseMarket] = useState(0);
    const [salesBySku, setSalesBySku] = useState([]);
    const [salesByStoreType, setSalesByStoreType] = useState([]);
    const [totalContainerInCirculation, setTotalContainerInCirculation] = useState([]);
    const [salesStandardByEntityId, setSalesStandardByEntityId] = useState([]);
    const [salesIconiqueByEntityId, setSalesIconiqueByEntityId] = useState([]);
    const [salesByIndusByStoreType, setSalesByIndusByStoreType] = useState([]);
    const [contributionSalesMarket, setContributionSalesMarket] = useState(0);
    const [salesByIndusByProduct, setSalesByIndusByProduct] = useState([]);
    const [salesByStoreAndEan, setSalesByStoreAndEan] = useState([]);
    const [formattedTabMyRefsData, setFormattedTabMyRefsData] = useState([]);

    // FILTRES
    const [startDate, setStartDate] = useState(new Date(now.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(now);
    const [skuSelected, setSkuSelected] = useState('');
    const [eanSelected, setEanSelected] = useState('');

    useEffect(() => {
        setAllReferences([]);
        setReferencesByIndus([]);
        setContributionReuseMarket(0);
        setSalesBySku([]);
        setSalesByStoreType([]);
        setTotalContainerInCirculation([]);
        setSalesStandardByEntityId([]);
        setSalesByIndusByStoreType([]);
        setContributionSalesMarket(0);
        setSalesByIndusByProduct([]);
        setSalesByStoreAndEan([]);
        setFormattedTabMyRefsData([]);
        setOriginalReferencesReuse([]);
        setOriginalreferencesReauseByIndus([]);

        const fetchData = async () => {
            // NB REFERENCES REEMPLOYABLES
            const referencesReause = await getIndustrialProductByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));

            const referencesReauseCheck = getIndustrialProductByDatesCheck(referencesReause);
            if (referencesReause.length && referencesReauseCheck !== true) {
                console.error(referencesReauseCheck);
                Sentry.captureException(referencesReauseCheck);
                navigate('/error');
                return;
            }
            setOriginalReferencesReuse(referencesReause);

            let referencesReauseFiltered = [];
            if (skuSelected) {
                referencesReauseFiltered = referencesReause.filter((container) => container.sku === skuSelected);
            } else {
                referencesReauseFiltered = referencesReause;
            }

            setAllReferences(referencesReauseFiltered);

            // NB PRODUITS REEMPLOYES (CONTENANTS MIS SUR LE MARCHE)
            const standardCirculationContainer = await getAllStandardSalesDetailsByDates(startDate.toISOString(), endOfDay(endDate.toISOString()));

            const allSalesDetailsCheck = getAllStandardSalesDetailsByDatesCheck(standardCirculationContainer);
            if (standardCirculationContainer.length && allSalesDetailsCheck !== true) {
                console.error(allSalesDetailsCheck);
                Sentry.captureException(allSalesDetailsCheck);
                navigate('/error');
                return;
            }

            let allSalesDetailsFiltered = [];
            if (skuSelected) {
                allSalesDetailsFiltered = standardCirculationContainer.filter((container) => container.container_sku === skuSelected);
            } else {
                allSalesDetailsFiltered = standardCirculationContainer;
            }

            const totalQtyStandard = allSalesDetailsFiltered.reduce((acc, item) => acc + item.qty, 0);
            setTotalContainerInCirculation(totalQtyStandard);

            // NB PRODUITS REEMPLOYES (CONTENANTS MIS SUR LE MARCHE) PAR SKU
            const countedBySku = reduceDataByKeyAndQty(standardCirculationContainer, 'container_sku');
            const salesBySkuRaw = Object.keys(countedBySku).map((sku) => ({
                sku: sku,
                qty: ((countedBySku[sku] / totalQtyStandard) * 100).toFixed(1),
            }));

            setSalesBySku(salesBySkuRaw);

            // NB PRODUITS REEMPLOYES (CONTENANATS MIS SUR LE MARCHE) PAR ENSEIGNE
            const countedByStoreType = reduceDataByKeyAndQty(standardCirculationContainer, 'sale_type');
            const salesByTypeRaw = Object.keys(countedByStoreType).map((type) => ({
                type: _defineStoreType(type),
                qty: ((countedByStoreType[type] / totalQtyStandard) * 100).toFixed(1),
            }));
            setSalesByStoreType(salesByTypeRaw);

            // NB REFERENCES REEMPLOYABLES PAR MARQUE
            const referencesReauseByIndus = await getIndustrialProductByDatesAndIndus(startDate.toISOString(), endOfDay(endDate.toISOString()));

            setOriginalreferencesReauseByIndus(referencesReauseByIndus);
            let referencesReauseByIndusFiltered = [];
            if (eanSelected) {
                referencesReauseByIndusFiltered = referencesReauseByIndus.filter((container) => container.ean === eanSelected);
            } else {
                referencesReauseByIndusFiltered = referencesReauseByIndus;
            }
            setReferencesByIndus(referencesReauseByIndusFiltered);

            // CONTRIBUTION MARKET
            const contributionReuseMarketRaw = ((referencesReauseByIndusFiltered.length / referencesReause.length) * 100).toFixed(1);
            setContributionReuseMarket(contributionReuseMarketRaw);

            // NB PRODUITS REEMPLOYES (CONTENANTS MIS SUR LE MARCHE) PAR MARQUE
            const salesByEntityIdRaw = await getAllSalesDetailsByDatesAndIndus(startDate.toISOString(), endOfDay(endDate.toISOString()));
            const salesByEntityIdCheck = getAllSalesDetailsByDatesAndIndusCheck(salesByEntityIdRaw);
            if (salesByEntityIdRaw.length && salesByEntityIdCheck !== true) {
                console.error(salesByEntityIdCheck);
                Sentry.captureException(salesByEntityIdCheck);
                navigate('/error');
                return;
            }

            const salesByEntityIdRawTotal = salesByEntityIdRaw.reduce((acc, item) => acc + item.qty, 0);

            let salesByEntityIdFiltered = [];
            if (eanSelected) {
                salesByEntityIdFiltered = salesByEntityIdRaw.filter((container) => container.product_ean === eanSelected);
            } else {
                salesByEntityIdFiltered = salesByEntityIdRaw;
            }

            const standardCirculationContainerIndus = salesByEntityIdFiltered.filter(
                (item) => item.container_owner === Constant.CONTAINER_OWNER_STATUS_STANDARD,
            );

            const ownerCircularContainerIndus = salesByEntityIdFiltered.filter((item) => item.container_owner === Constant.CONTAINER_OWNER_STATUS_BRAND);

            const salesIndusTotalQtyOwner = ownerCircularContainerIndus.reduce((acc, item) => acc + item.qty, 0);
            setSalesIconiqueByEntityId(salesIndusTotalQtyOwner);

            const salesIndusTotalQtyStandard = standardCirculationContainerIndus.reduce((acc, item) => acc + item.qty, 0);
            setSalesStandardByEntityId(salesIndusTotalQtyStandard);

            // CONTRIBUTION MARKET
            const totalQStandardCirculation = standardCirculationContainer.reduce((acc, item) => acc + item.qty, 0);
            const contributionReuseMarketSalesRaw = ((salesIndusTotalQtyStandard / totalQStandardCirculation) * 100).toFixed(1);
            setContributionSalesMarket(contributionReuseMarketSalesRaw);

            // NB PRODUITS REEMPLOYES (CONTENANATS MIS SUR LE MARCHE) PAR MARQUE ET PAR ENSEIGNE
            const countedByStoreTypeIndus = reduceDataByKeyAndQty(salesByEntityIdFiltered, 'sale_type');
            const salesByIndusByTypeRaw = Object.keys(countedByStoreTypeIndus).map((type) => ({
                type: _defineStoreType(type),
                qty: ((countedByStoreTypeIndus[type] / salesByEntityIdRawTotal) * 100).toFixed(1),
            }));
            setSalesByIndusByStoreType(salesByIndusByTypeRaw);

            // QUANTITE MISE SUR LE MARCHE BY REFERENCE
            const countedSalesByIndusAndProduct = reduceDataByKeyAndQty(salesByEntityIdFiltered, 'product_name');
            const salesByIndusByProducteRaw = Object.keys(countedSalesByIndusAndProduct).map((name) => ({
                name: name,
                qty: countedSalesByIndusAndProduct[name],
            }));
            setSalesByIndusByProduct(salesByIndusByProducteRaw);

            // NB PRODUIT REEMPLOYES BY EAN AND ENSEIGNE
            const salesByStoreAndEanRaw = transformDataEmpliee(salesByEntityIdFiltered, 'sale_type', 'product_name', 'qty', null, false, _defineStoreType);
            setSalesByStoreAndEan(salesByStoreAndEanRaw);

            // EXTRACT MES REFERENCES
            let result = [];

            salesByEntityIdFiltered.forEach((item) => {
                let existingItem = result.find((resultItem) => resultItem.product_ean === item.product_ean);

                if (existingItem) {
                    existingItem.qty += item.qty;
                    if (!existingItem.type) existingItem.type = {};
                    existingItem.type['type' + item.sale_type] = (existingItem.type['type' + item.sale_type] || 0) + item.qty;
                } else {
                    result.push({
                        ean: item.product_ean,
                        name: item.product_name,
                        cat: _defineProductCat(item.product_cat),
                        sku: item.container_sku,
                        qty: item.qty,
                        type: { ['type' + item.sale_type]: item.qty },
                    });
                }
            });

            setFormattedTabMyRefsData(result);
        };
        if (startDate & endDate) fetchData();
    }, [userContext.entityId, startDate, endDate, skuSelected, eanSelected, navigate]);

    const customHeaderMyRefs = [
        {
            label: 'EAN',
            key: 'ean',
        },
        {
            label: 'Nom',
            key: 'name',
        },
        {
            label: 'Catégorie',
            key: 'cat',
        },
        {
            label: 'SKU',
            key: 'sku',
        },
        {
            label: 'Quantité totale déclarée',
            key: 'qty' || 0,
        },
        {
            label: 'Hyper',
            key: 'type.type1' || 0,
        },
        {
            label: 'Super',
            key: 'type.type2' || 0,
        },
        {
            label: 'Proxi',
            key: 'type.type3' || 0,
        },
    ];

    const resetGlobalFilter = () => {
        setSkuSelected('');
    };

    const resetMyRefsFilter = () => {
        setEanSelected('');
    };

    const salesBySkuLabels = salesBySku.map((item) => item.sku);
    const salesBySkuQuantities = salesBySku.map((item) => item.qty);

    const salesByStoreTypeLabels = salesByStoreType.map((item) => item.type);
    const salesByStoreTypeQuantities = salesByStoreType.map((item) => item.qty);

    const salesByIndusByStoreTypeLabels = salesByIndusByStoreType.map((item) => item.type);
    const salesByIndusByStoreTypeQuantities = salesByIndusByStoreType.map((item) => item.qty);

    const salesByIndusByProductLabels = salesByIndusByProduct.map((item) => item.name);
    const salesByIndusByProductQuantities = salesByIndusByProduct.map((item) => item.qty);

    const filteredsku = originalReferencesReuse.filter((item) => item.container_owner === Constant.CONTAINER_OWNER_STATUS_STANDARD);

    const mySkus = [...new Set(filteredsku.map((fd) => fd.container_sku))];

    const myEans = [...new Set(originalreferencesReauseByIndus.map((fd) => fd.ean))];
    return (
        <main>
            <div className="data-container">
                <div className="header-title">
                    <h1>PRODUITS</h1>
                    <div className="date-container">
                        <DateFilter
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                </div>

                <div className="header-container">
                    <div className="header-title">
                        <h1>Global marché</h1>
                    </div>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setSkuSelected(e.target.value)}
                                value={skuSelected}>
                                <option value="">SKU</option>

                                {mySkus.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetGlobalFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ height: '22rem', marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1%' }}>
                        <section
                            className="section-graph"
                            style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Nombre de références réemployables :
                            </div>
                            <div
                                style={{ fontSize: 25 }}
                                className="title">
                                <br />
                                {allReferences.length ? allReferences.length : 0}
                            </div>
                        </section>
                        <section
                            className="section-graph"
                            style={{ flex: 1, marginBottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Nombre total de contenants standards en circulation :
                            </div>
                            <div
                                style={{ fontSize: 25 }}
                                className="title">
                                <br />
                                {totalContainerInCirculation}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 2, height: '22rem' }}>
                        <section
                            className="section-graph"
                            style={{ height: '22rem' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Répartition des contenants standards réemployés <br /> par SKU (%) :
                            </div>
                            <div style={{ height: '90%' }}>
                                {salesBySku.length > 0 ? (
                                    <PieChart
                                        title={salesBySkuLabels}
                                        quantities={salesBySkuQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 2, height: '22rem' }}>
                        <section
                            className="section-graph"
                            style={{ height: '22rem' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Répartition des contenants standards réemployés <br /> par enseigne (%):
                            </div>
                            <div style={{ height: '90%' }}>
                                {salesByStoreType.length > 0 ? (
                                    <PieChart
                                        title={salesByStoreTypeLabels}
                                        quantities={salesByStoreTypeQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>

                <div className="header-container">
                    <h1>Mes références</h1>
                    <div className="content-header">
                        <div className="header-container-content">
                            <select
                                onChange={(e) => setEanSelected(e.target.value)}
                                value={eanSelected}>
                                <option value="">EAN</option>

                                {myEans.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <div
                                onClick={resetMyRefsFilter}
                                className="reset">
                                <p style={{ fontWeight: 'bold' }}>X</p>
                            </div>
                        </div>

                        <div className="extract-content">
                            <div className="extract">
                                <CSVLink
                                    style={{ textDecoration: 'none', color: Constant.$blanc }}
                                    className="extract-button"
                                    headers={customHeaderMyRefs}
                                    data={formattedTabMyRefsData ? formattedTabMyRefsData : []}
                                    filename={`myproduct_data.csv`}
                                    target="_blank"
                                    separator={';'}>
                                    Extraire ma donnée
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ height: '22rem', marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%' }}>
                    <div style={{ flex: 1 }}>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Nombre de mes références en réemploi :
                            </div>
                            <div
                                style={{ fontSize: 25 }}
                                className="title">
                                <br />
                                {referencesByIndus.length}
                            </div>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Contribution au marché du réemploi :
                            </div>
                            <div
                                style={{ fontSize: 25 }}
                                className="title">
                                <br /> {contributionReuseMarket}%
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 1 }}>
                        <section
                            className="section-graph"
                            style={{ height: '100%', marginBottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Nombre total de mes contenants standard en réemploi :
                            </div>
                            <div
                                style={{ fontSize: 25 }}
                                className="title">
                                <br /> {salesStandardByEntityId}
                            </div>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Contribution au marché du réemploi des standards :
                            </div>
                            <div
                                style={{ fontSize: 25 }}
                                className="title">
                                <br /> {contributionSalesMarket}%
                            </div>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Nombre total de mes contenants iconiques en réemploi :
                            </div>
                            <div
                                style={{ fontSize: 25 }}
                                className="title">
                                <br /> {salesIconiqueByEntityId}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 3, height: '22rem' }}>
                        <section
                            className="section-graph"
                            style={{ height: '22rem' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Mes quantités mises sur le marché :
                            </div>
                            <div style={{ height: '90%' }}>
                                {salesByIndusByProduct.length > 0 ? (
                                    <BarChart
                                        title={salesByIndusByProductLabels}
                                        quantities={salesByIndusByProductQuantities}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>

                <div style={{ height: '22rem', marginBottom: '2rem', display: 'flex', width: '100%', gap: '1%' }}>
                    <div style={{ flex: 3, height: '22rem' }}>
                        <section
                            className="section-graph"
                            style={{ height: '22rem' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Mes contenants en réemploi par enseigne et par produit :
                            </div>
                            <div style={{ height: '90%' }}>
                                {salesByStoreAndEan ? (
                                    <BarChartMulti formattedData={salesByStoreAndEan} />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                    <div style={{ flex: 2, height: '22rem' }}>
                        <section
                            className="section-graph"
                            style={{ height: '22rem' }}>
                            <div
                                className="title"
                                style={{ height: '1rem' }}>
                                Mes contenants en réemploi par enseigne (%) :
                            </div>
                            <div style={{ height: '90%' }}>
                                {salesByIndusByStoreType.length > 0 ? (
                                    <PieChart
                                        title={salesByIndusByStoreTypeLabels}
                                        quantities={salesByIndusByStoreTypeQuantities}
                                        position={'left'}
                                    />
                                ) : (
                                    <div className="no-data-graph">Aucune donnée.</div>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}
