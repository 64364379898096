import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { createAnnualForecast } from '../../../../API/AnnualForecastApi';
import { getIndustrialProductByIndusId } from '../../../../API/IndustrialApi';
import { getContainerBySku } from '../../../../API/ContainerApi';
import parser from 'papaparse';
import { UploadSimple } from '@phosphor-icons/react';
import CSVDownloader from '../../../../Components/CSVDownloader';
import { Jwt } from '../../../../Jwt';
import { format } from 'date-fns';
import * as Constant from '../../../../Constants';

export default function AnnualForecast() {
    const userContext = new Jwt();
    const today = new Date();
    const todayFormatted = format(today, 'yyyy-MM-dd');
    const currentMonth = format(today, 'yyyy-MM');
    const [startDate, setStartDate] = useState(currentMonth);
    const [importMsg, setImportMsg] = useState('');
    const [formOk, setFormOk] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [containerList, setContainerList] = useState([]);
    const [fileName, setFileName] = useState(null);
    const csvName = `forecast_${todayFormatted}`;
    const headers = [
        'sku',
        'month_1',
        'month_2',
        'month_3',
        'month_4',
        'month_5',
        'month_6',
        'month_7',
        'month_8',
        'month_9',
        'month_10',
        'month_11',
        'month_12',
    ];

    const exemples = [
        'XX-XXXX-XX',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
    ];
    const [declarationDetailsData, setDeclarationDetailsData] = useState([
        {
            sku: '',
            containerId: null,
            month_1: 0,
            month_2: 0,
            month_3: 0,
            month_4: 0,
            month_5: 0,
            month_6: 0,
            month_7: 0,
            month_8: 0,
            month_9: 0,
            month_10: 0,
            month_11: 0,
            month_12: 0,
        },
    ]);

    const dialogRef = useRef(null);

    useEffect(() => {
        setImportMsg('');

        const fetchContainer = async () => {
            const data = await getIndustrialProductByIndusId(userContext.entityId);
            const uniqueSkus = [...new Set(data.map((item) => item.sku))];
            setContainerList(uniqueSkus);
        };
        fetchContainer();
    }, [userContext.entityId]);

    const ajouterLigne = () => {
        setDeclarationDetailsData([
            ...declarationDetailsData,
            {
                sku: '',
                containerId: null,
                month_1: 0,
                month_2: 0,
                month_3: 0,
                month_4: 0,
                month_5: 0,
                month_6: 0,
                month_7: 0,
                month_8: 0,
                month_9: 0,
                month_10: 0,
                month_11: 0,
                month_12: 0,
            },
        ]);
    };

    const mettreAJourCommande = (index, field, value) => {
        const newData = [...declarationDetailsData];
        newData[index][field] = value;
        setDeclarationDetailsData(newData);
    };

    const mettreAJourCommande2 = async (index, field1, value) => {
        const getSkuInfos = await getContainerBySku(value);
        const newData = [...declarationDetailsData];
        newData[index][field1] = value;
        newData[index]['containerId'] = getSkuInfos[0].id;

        setDeclarationDetailsData(newData);
    };

    const envoyerCommandeAuServeur = async () => {
        for (const item of declarationDetailsData) {
            if (item.sku === '') {
                alert('Veuillez renseigner tous les champs.');
                return;
            } else if (Object.values(item).some((val) => val < 0)) {
                alert('Les quantités ne peuvent pas être inférieures à 0.');
                return;
            } else {
                const response = await createAnnualForecast(declarationDetailsData, userContext.entityId, userContext.userId, startDate);
                if (response) {
                    alert('Prévisionnel annuel enregistré.');
                    window.location.reload();
                } else {
                    alert('Une erreur est survenue.');
                }
            }
        }
    };

    const supprimerLigne = (index) => {
        const newData = [...declarationDetailsData];
        newData.splice(index, 1);
        setDeclarationDetailsData(newData);
    };

    const handleModalOpen = () => {
        const startDateImport = prompt('Quel est le mois de début du prévisonnel ? (format YYYY-MM) : ');

        const selectedDate = new Date(startDateImport);
        if (selectedDate <= new Date(currentMonth)) {
            alert('La date de début ne peut pas être antérieure au mois en cours !');
            return;
        }
        setStartDate(startDateImport);

        if (dialogRef.current) {
            setModalOpen(true);

            dialogRef.current.showModal();
        }
    };

    const handleModalClose = () => {
        setImportMsg('');

        setFileName(null);
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const importHandler = (e) => {
        setImportMsg('Import in progress');
        e.preventDefault();
        var file = e.target.files[0];
        setFileName(file.name);

        parser.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: callBackHandleImportParse,
        });
    };

    const callBackHandleImportParse = (results) => {
        if (checkDataValidity(results)) {
            ConvertCsvToForm(results);
        }
    };

    const checkDataValidity = (results) => {
        var i = 0;
        if (checkHeaderValidity(results.meta.fields)) {
            for (i = 0; i < results.data.length; i++) {
                checkLineValidity(i, results.data[i]);
            }
        } else {
            console.log('HEADER - Unvalid');
        }

        setImportMsg('DATA Validity check finished !');
        return 1;
    };

    const checkLineValidity = (i, resultLine) => {
        var ret = 1;
        if (resultLine.sku.length > 32) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_1 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_2 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_3 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_4 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_5 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_6 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_7 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_8 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_9 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_10 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_11 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        if (resultLine.month_12 < 0) {
            console.log('LINE' + i + ' - column SKU - unvalid');
            ret = 0;
        }
        return ret;
    };

    const checkHeaderValidity = (fields) => {
        var ret = 1;
        if (fields[0] !== 'sku') {
            console.log('HEADER - column 1 - unvalid');
            ret = 0;
        }

        if (fields[1] !== 'month_1') {
            console.log('HEADER - column 2 - unvalid');
            ret = 0;
        }

        if (fields[2] !== 'month_2') {
            console.log('HEADER - column 3 - unvalid');
            ret = 0;
        }

        if (fields[3] !== 'month_3') {
            console.log('HEADER - column 4 - unvalid');
            ret = 0;
        }

        if (fields[3] !== 'month_4') {
            console.log('HEADER - column 5 - unvalid');
            ret = 0;
        }

        if (fields[3] !== 'month_5') {
            console.log('HEADER - column 6 - unvalid');
            ret = 0;
        }

        if (fields[3] !== 'month_6') {
            console.log('HEADER - column 7 - unvalid');
            ret = 0;
        }

        if (fields[3] !== 'month_7') {
            console.log('HEADER - column 8 - unvalid');
            ret = 0;
        }

        if (fields[3] !== 'month_8') {
            console.log('HEADER - column 9 - unvalid');
            ret = 0;
        }

        if (fields[3] !== 'month_9') {
            console.log('HEADER - column 10 - unvalid');
            ret = 0;
        }

        if (fields[3] !== 'month_10') {
            console.log('HEADER - column 11 - unvalid');
            ret = 0;
        }

        if (fields[3] !== 'month_11') {
            console.log('HEADER - column 12 - unvalid');
            ret = 0;
        }

        if (fields[3] !== 'month_12') {
            console.log('HEADER - column 13 - unvalid');
            ret = 0;
        }

        return ret;
    };

    const ConvertCsvToForm = async (results) => {
        let newData = [];
        let skuSet = new Set();
        for (let i = 0; i < results.data.length; i++) {
            const skuInfo = await getContainerBySku(results.data[i].sku);

            if (!containerList.some((option) => option === results.data[i].sku)) {
                setImportMsg('Erreur: Le SKU ' + results.data[i].sku + " n'existe pas.");
                setFormOk(false);
                return;
            }

            if (
                Object.values(results.data[i])
                    .slice(1)
                    .some((val) => val < 0)
            ) {
                setImportMsg('Erreur: Une quantité ne peut pas être inférieure à 0.');
                setFormOk(false);
                return;
            }

            skuSet.add(results.data[i].sku);
            newData.push({
                sku: results.data[i].sku,
                containerId: skuInfo[0].id,
                month_1: results.data[i].month_1,
                month_2: results.data[i].month_2,
                month_3: results.data[i].month_3,
                month_4: results.data[i].month_4,
                month_5: results.data[i].month_5,
                month_6: results.data[i].month_6,
                month_7: results.data[i].month_7,
                month_8: results.data[i].month_8,
                month_9: results.data[i].month_9,
                month_10: results.data[i].month_10,
                month_11: results.data[i].month_11,
                month_12: results.data[i].month_12,
            });
        }

        setDeclarationDetailsData(newData);
        setImportMsg('CSV converti avec succès !');
        setFormOk(true);
    };

    const getNext12Months = (startDate) => {
        const months = [];
        let currentDate = new Date(startDate);
        for (let i = 0; i < 12; i++) {
            const month = currentDate.toLocaleString('default', { month: 'short' });
            const year = currentDate.getFullYear();
            months.push({ month, year });
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
        return months;
    };

    const next12Months = getNext12Months(startDate);

    // Générer une liste d'années (par exemple, de 2020 à 2030)
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year <= currentYear + 10; year++) {
        years.push(year);
    }

    return (
        <main>
            <section className="big-section">
                <div className="top">
                    <h2>Prévisions annuelles</h2>
                    <button
                        onClick={handleModalOpen}
                        className="button-actif">
                        IMPORTER UN FICHIER
                    </button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1.25rem' }}>
                    <p style={{ fontWeight: 'bolder' }}>Début du prévisionnel :</p>
                    Janvier
                    <select
                        id="yearSelect"
                        value={startDate.split('-')[0]}
                        onChange={(e) => setStartDate(`${e.target.value}-01`)}>
                        {years.map((year) => (
                            <option
                                key={year}
                                value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                    <p style={{ fontWeight: 'bolder' }}>Unité :</p>
                    <p>Palette</p>
                </div>
                <div className="annual-forecast-container">
                    <div className="tab-item">
                        <div
                            className="pre-filled-fields-1-several-item"
                            style={{ fontWeight: '600' }}>
                            <div style={{ width: '20%', borderRight: '1px solid #37ac95' }}>
                                <p>SKU</p>
                            </div>
                            <div className="month-container">
                                {next12Months.map((item, index) => (
                                    <p key={index}>
                                        {item.month} <br /> {item.year}
                                    </p>
                                ))}
                            </div>
                        </div>
                        {declarationDetailsData.map((ligne, index) => (
                            <div
                                key={index}
                                className="line-container">
                                <div className="select-container">
                                    {containerList.length ? (
                                        <select onChange={(e) => mettreAJourCommande2(index, 'sku', e.target.value)}>
                                            <option>Choisissez une option</option>
                                            {containerList.map((option, index) => (
                                                <option
                                                    key={index}
                                                    value={option}
                                                    selected={ligne.sku === option ? 'selected' : ''}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            type="text"
                                            disabled
                                            value={'Aucun produit enregistré.'}
                                        />
                                    )}
                                </div>
                                <div className="input-container">
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_1}
                                        onChange={(e) => mettreAJourCommande(index, 'month_1', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_2}
                                        onChange={(e) => mettreAJourCommande(index, 'month_2', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_3}
                                        onChange={(e) => mettreAJourCommande(index, 'month_3', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_4}
                                        onChange={(e) => mettreAJourCommande(index, 'month_4', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_5}
                                        onChange={(e) => mettreAJourCommande(index, 'month_5', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_6}
                                        onChange={(e) => mettreAJourCommande(index, 'month_6', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        value={ligne.month_7}
                                        onChange={(e) => mettreAJourCommande(index, 'month_7', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_8}
                                        onChange={(e) => mettreAJourCommande(index, 'month_8', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_9}
                                        onChange={(e) => mettreAJourCommande(index, 'month_9', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_10}
                                        onChange={(e) => mettreAJourCommande(index, 'month_10', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_11}
                                        onChange={(e) => mettreAJourCommande(index, 'month_11', e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        min={0}
                                        value={ligne.month_12}
                                        onChange={(e) => mettreAJourCommande(index, 'month_12', e.target.value)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="add-ligne">
                        {declarationDetailsData.length > 1 && <button onClick={() => supprimerLigne(declarationDetailsData.length - 1)}>-</button>}
                        <button onClick={ajouterLigne}>+</button>
                    </div>
                    <div className="save">
                        {!modalOpen && importMsg && <p style={{ color: Constant.$rouge, fontWeight: 'bolder' }}>{importMsg}</p>}
                        <button
                            className="button-actif"
                            onClick={envoyerCommandeAuServeur}>
                            DÉCLARER
                        </button>
                    </div>
                </div>
            </section>
            <dialog
                ref={dialogRef}
                className="dialog-collector">
                <div className="title">
                    <p>Importer un fichier</p>
                    <button
                        className="button-negatif"
                        onClick={handleModalClose}>
                        X
                    </button>
                </div>
                <div className="modal-declaration">
                    {!fileName ? (
                        <>
                            <CSVDownloader
                                fileName={csvName}
                                headers={headers}
                                data={exemples}
                            />
                            <div className="custom-file-upload">
                                <input
                                    id="fileInput"
                                    type={'file'}
                                    accept={'.csv'}
                                    onChange={importHandler}
                                />
                                <label htmlFor="fileInput">
                                    <span>
                                        <UploadSimple size={32} />
                                    </span>
                                    Importer un fichier CSV
                                </label>
                            </div>
                        </>
                    ) : (
                        <div style={{ width: '100%' }}>
                            {importMsg && <p style={{ paddingBottom: '1rem' }}>{importMsg}</p>}
                            <p>Fichier : {fileName}</p>
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '2rem', width: '100%' }}>
                                <button
                                    className="button-negatif"
                                    onClick={() => {
                                        setFileName(null);
                                    }}>
                                    RECOMMENCER
                                </button>
                                {formOk && (
                                    <button
                                        onClick={handleModalClose}
                                        className="button-actif">
                                        VALIDER
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </dialog>
        </main>
    );
}
